/******************************************************************************\
* File: Modal.jsx
*
* Author: Gigster
*
* Description: General-purpose modal component
*
* Example Usage:

    <Modal isOpen={this.state.isOpen} onRequestClose={this.close}>
        <Title>Do you want to save your ride?</Title>
        <Button negative text="Nope" onClick={this.close} />
        <Button positive text="Save Ride" onClick={this.save} />
    </Modal>

*
* Notes: Also accepts all react-modal props
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import ReactModal from 'react-modal';
if (process.env.NODE_ENV !== 'test') ReactModal.setAppElement('#app');
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------
import { escapable } from '@/helpers/hoc';
import Link from '@/components/common/header/Link';
import Close from '@/components/common/icons/Close';
import ArrowRight from '@/components/common/icons/ArrowRight';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/common/Modal.scss';
//------------------------------------------------------------------------------
// Helper Classes --------------------------------------------------------------
export const Title = ({ title, children, className }) => (
    <h2 className={classNames(className, style.title)}>{title || children}</h2>
);

export const Subtitle = ({ title, children, className, ...rest }) => (
    <h3 className={className || style.subtitle} {...rest}>
        {title || children}
    </h3>
);

export const Header = ({ children }) => (
    <header className={style.header}>{children}</header>
);

export const Button = ({
    children,
    disabled,
    text,
    positive,
    negative,
    bottom,
    right,
    onClick,
    ...rest
}) => (
    <button
        className={classNames(style.button, {
            [style['positive']]: positive,
            [style['negative']]: negative,
            [style.bottom]: bottom,
            [style['right']]: !!right
        })}
        onClick={onClick}
        type={negative ? 'button' : 'submit'}
        disabled={disabled}
        tabIndex="0"
        {...rest}>
        {text || children} <ArrowRight className={style.arrow} />
    </button>
);
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
const Modal = ({
    className,
    withBackground,
    large,
    onRequestClose,
    closeFully = true,
    children,
    ...rest
}) => {
    const cnOverlay = classNames(style.overlay, {
        [style['large']]: !!large,
        [style['background']]: !!withBackground
    });

    const cn = classNames(style.content, {
        [className]: !!className
    });

    return (
        <ReactModal
            overlayClassName={cnOverlay}
            className={cn}
            shouldCloseOnOverlayClick={true}
            closeTimeoutMS={0}
            {...rest}>
            {onRequestClose && (
                <Link className={style.close} onClick={onRequestClose}>
                    <Close />
                </Link>
            )}
            <div className={style.inner}>{children}</div>
        </ReactModal>
    );
};
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default escapable(Modal);
