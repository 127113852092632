import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import withRouter from '@/helpers/hooks';
import * as style from '@/style/mapListView/MapListView.scss';
import Map from '@/components/mapListView/Map';
import MapCommon from '@/components/mapListView/views/MapCommon';
import MapDefault from '@/components/mapListView/views/MapDefault';
import PrintView from '@/components/mapListView/directions/PrintView';
import ArrowLink from '@/components/common/ArrowLink';

import UiControls from '@/components/common/map/here/UiControls';
import ErrorBoundary from '@/components/common/ErrorBoundary';
import * as ridesActions from '@/store/rides';
import * as mapActions from '@/store/map';
import { transformRide } from '@/store/poi/helpers';
import { ensureGigyaWebSDK } from '@/helpers/gigya';
import {
    removeOsanoWidgetIcon,
    undoOsanoWidgetRemoval
} from '@/helpers/removeOsanoWidgetIcon';

import {
    isChildOf,
    setupGeolocation,
    cleanupGeolocation
} from '@/helpers/functions';
import { Routes, isType } from '@/helpers/routes';
import { collections } from '@/helpers/collections';
import { createLogger } from '@/helpers/debug';
//App context
import AppContext from '@/contexts/AppContext';
const log = createLogger('Map Collection View', true);

class MapCollectionView extends React.Component {
    constructor(props) {
        super(props);
        this.intervalo = null;
    }
    state = {
        contextMenu: false,
        isInitialLoad: true,
        print: false,
        collection: null,
        collectionType: null,
        collectionTitle: null,
        shouldFetchCollection: true
    };

    isType = (types) => isType(this.props.location.pathname, types);

    componentDidMount = () => {
        this.intervalo = removeOsanoWidgetIcon(this);
        const { location, setShowSearchThisArea, update } = this.props;
        document.addEventListener('keydown', this.overridePrint, false);
        // Clear previous rides to prevent rendering (race condition)
        this.props.unloadCollectionRides();

        let shouldUpdatePos = false;
        const search = new URLSearchParams(location.search);
        if (!search.get('pos')) {
            shouldUpdatePos = true;
        } else {
            const [lat, lng, zoom] = search
                .get('pos')
                .replace('z', '')
                .split(',');
            update('center')({ lat: parseFloat(lat), lng: parseFloat(lng) });
            update('zoom')(parseFloat(zoom));
        }

        setShowSearchThisArea(false);
        const { centerZoom } = this.props;

        log('awaiting location and map...');

        const collection = search.get('collection');
        const collectionType = search.get('type');
        if (!!collectionType && !!collection) {
            this.setState({
                collection,
                collectionType,
                shouldFetchCollection: !!collection
            });

            const collectionObject = collections.find(
                (collectionObj) => collectionObj.tag === collection
            );
            if (!!collectionObject) {
                const { title, lat, lng, zoom } = collectionObject;
                this.setState({ collectionTitle: title, collectionObject });
                if (shouldUpdatePos) {
                    const center = { lat, lng };
                    centerZoom(center, zoom);
                }
            }
        }
        this.setState({ isInitialLoad: false });
        // If browser has geolocation, setup callbacks
        ensureGigyaWebSDK(() => {
            this.watchGeoId = setupGeolocation(
                this.didReceiveLocation,
                window.hd.gigya
            );
        });
        this.props.onMapHasLoaded();
    };

    didReceiveLocation = (locationInfo) => {
        const { onReceiveLocation } = this.props;
        onReceiveLocation(locationInfo);
    };

    componentDidUpdate = (prevProps, prevState) => {
        const { getFetchItemsByCollection } = prevProps;
        const {
            collection,
            collectionType,
            shouldFetchCollection,
            collectionObject
        } = prevState;
        if (
            collection !== this.props.collection &&
            !!collectionType &&
            !!collection &&
            shouldFetchCollection &&
            !!collectionObject
        ) {
            log(shouldFetchCollection);
            const tags =
                collectionObject.key === 'tag'
                    ? collectionObject.tags
                    : collectionObject.tag;
            getFetchItemsByCollection(
                collectionType,
                tags,
                collection,
                collectionObject
            );
            return this.setState({ shouldFetchCollection: false });
        }
    };

    componentWillUnmount = () => {
        undoOsanoWidgetRemoval();
        this.props.unloadCollectionRides();
        // Clear the geolocation watch callback if it was created
        cleanupGeolocation(this.watchGeoId);
        document.removeEventListener('keydown', this.overridePrint, false);
    };

    overridePrint = (e) => {
        const {
            setPrint,
            map: { print }
        } = this.props;
        // if already showing the modal, do nothing
        if (print) {
            return;
        }
        if (e.key === 'p' && (e.metaKey || e.ctrlKey)) {
            e.preventDefault();
            setPrint(true);
        }
    };

    handleWheel = (e) => {
        const { deltaY } = e;

        this.closeContextMenu();

        // don't traverse if we're scrolling in an info bubble
        if (
            isChildOf(
                e.target,
                (el) => el.classList && el.classList.contains('H_ib_body')
            )
        ) {
            return;
        }

        const { map } = this.props;
        const zoom = -Math.sign(deltaY);
        const nextZoom = map.zoom + zoom;

        // on zoom past cluster, hide it
        if (map.selectedDataArgs) {
            const { minZoom, maxZoom } = map.selectedDataArgs || {};
            if ((maxZoom && nextZoom > maxZoom) || nextZoom < minZoom) {
                this.onBubbleChange('closed');
            }
        }
    };

    openContextMenu = (contextMenuEvent, pointId, meta = {}) => {
        if (this.isType(['CREATE', 'EDIT'])) {
            const event = (contextMenuEvent || {}).originalEvent || {};
            this.setState({
                contextMenu: {
                    style: { top: event.pageY, left: event.pageX },
                    geocoord: contextMenuEvent.geocoord,
                    pointId,
                    meta
                }
            });
        }
    };

    closeContextMenu = () => this.setState({ contextMenu: false });

    onBubbleChange = (state) => {
        if (state === 'closed') {
            this.props.update('selectedData')(null);
            this.props.update('selectedDataArgs')(null);
        }
    };

    onMarkerClick = (marker) => {
        const isArray = Array.isArray(marker);
        if (marker && (isArray ? marker.length > 0 : true)) {
            log('onMarkerClick', marker);

            const { update } = this.props;

            const isRide = isArray
                ? (marker[0] || {}).waypoints
                : marker.waypoints;

            if (isArray && isRide) {
                const transformedRide = marker.map(transformRide);
                update('selectedData')(transformedRide);
            } else if (isRide) {
                const transformedRide = transformRide(marker);
                update('selectedData')(transformedRide);
            } else if (!isRide) {
                update('selectedData')(marker);
            }
        }
    };

    onCenter = () => {
        const { update } = this.props;
        update('centerMyLocation')(true);
    };

    render() {
        const {
            location,
            currentRide,
            map,
            createModalShowing,
            onSelectCollectionRideId,
            selectedCollectionRideId
        } = this.props;

        const link = (title, link, linkTitle) => (
            <span className={style.LinkStyle}>
                <span className={style.titleStyle}>{title}</span>
                <span className={style.LinkText}>
                    <ArrowLink to={link} plain text={linkTitle} />
                </span>
            </span>
        );

        const { collection, collectionTitle, collectionObject } = this.state;

        const { print } = map;

        // // totally not hacky solution
        const scaleBar = document.querySelector('.H_scalebar');
        if (scaleBar) {
            scaleBar.style.transform = 'translateY(-115px) translateX(-53px)';
        }

        const { isMobile } = this.context;
        return (
            <div className={style.MapListView}>
                {print && (
                    <PrintView
                        isOpen={print}
                        onRequestClose={() => this.props.setPrint(false)}
                        ride={currentRide}
                        printZoom={map.zoom - 1}
                        prevZoom={map.zoom}
                        onZoom={this.props.onZoom}
                        map={map}
                    />
                )}
                <ErrorBoundary>
                    {collectionTitle && (
                        <div
                            className={classNames(
                                style.LinkSection,
                                style.collections
                            )}>
                            <span className={style.LinkSectionTitle}>
                                {!!collectionObject.logo && (
                                    <img src={collectionObject.logo} />
                                )}
                                {!!collectionObject.collectionDisplay && (
                                    <span className={style.LinkSectionText}>
                                        {collectionObject.collectionDisplay}
                                    </span>
                                )}
                                {collectionObject.key === 'list' &&
                                    link(
                                        `${collectionTitle} `,
                                        `${Routes.COLLECTIONS}/${collection}`,
                                        'VIEW AS LIST'
                                    )}
                            </span>
                        </div>
                    )}
                    <Map
                        onWheel={this.handleWheel}
                        onContextMenu={this.openContextMenu}
                        isMobile={isMobile}
                        onClick={(e) => {
                            const { target } = e.originalEvent;
                            const isMap = parseInt(target.style.width) > 100;
                            if (selectedCollectionRideId && isMap)
                                onSelectCollectionRideId(null);
                            const el = document.activeElement;
                            if (el && typeof el.blur === 'function') {
                                el.blur();
                            }
                        }}
                        createModalShowing={createModalShowing}
                        {...this.props}>
                        <MapDefault
                            {...this.props}
                            onMarkerClick={this.onMarkerClick}
                        />
                        <MapCommon
                            location={location}
                            currRide={currentRide}
                            onMarkerClick={this.onMarkerClick}
                            onBubbleChange={this.onBubbleChange}
                        />
                    </Map>
                </ErrorBoundary>

                <div>
                    <UiControls
                        className={classNames(
                            style.uiControls,
                            style.collectionUi
                        )}
                        onCenter={this.onCenter}
                        onZoom={this.props.onZoom}
                        zoom={map.zoom}
                        onUpdateMap={this.props.update}
                        scheme={map.scheme}
                        schemeOpts={map.schemeOpts}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const selectedCollectionRideId = state.rides.selectedCollectionRide || {};

    return {
        currentRide: ridesActions.getCurrentRide(state),
        rides: state.rides.data,
        map: state.map,
        selectedCollectionRideId,
        events: state.events,
        dealers: state.dealers,
        createModalShowing: state.app.isCreateModalShowing
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        centerZoom: (center, zoom) =>
            dispatch(mapActions.centerZoom(center, zoom)),
        getFetchItemsByCollection: (
            collectionType,
            key,
            collection,
            collectionObject
        ) =>
            dispatch(
                mapActions.getFetchItemsByCollection(
                    collectionType,
                    key,
                    collection,
                    collectionObject
                )
            ),
        setShowSearchThisArea: (value) =>
            dispatch(mapActions.setShowSearchThisArea(value)),
        update: (field) => (value) => dispatch(mapActions.update(field, value)),
        onReceiveLocation: (position) =>
            dispatch(mapActions.didReceiveLocation(position)),
        onTraverseRoute: (deltaY) =>
            dispatch(ridesActions.traverseRoute(deltaY)),
        onSelectRide: (rideId) => dispatch(ridesActions.selectRide(rideId)),
        onSelectCollectionRideId: (rideId) =>
            dispatch(ridesActions.selectCollectionRide(rideId)),
        handleTraverseRoute: (deltaY) =>
            dispatch(ridesActions.handleTraverseRoute(deltaY)),
        onMapHasLoaded: () => dispatch(mapActions.mapHasLoaded()),
        onInitialLoad: (dynamicZoom) => () =>
            dispatch(mapActions.initialLoad(dynamicZoom)),
        setPrint: (value) => dispatch(mapActions.setPrint(value)),
        onZoom: (amount = 1) => dispatch(mapActions.zoom(amount)),
        centerMyLocation: () => dispatch(mapActions.centerMyLocation()),
        resetScrub: () => dispatch(ridesActions.resetScrub()),
        unloadCollectionRides: () => dispatch(ridesActions.update('data', []))
    };
};

MapCollectionView.contextType = AppContext;

const container = connect(
    mapStateToProps,
    mapDispatchToProps
)(MapCollectionView);

export default withRouter(container);
