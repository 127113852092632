/******************************************************************************\
 * File: RideDropdownHeader.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes: This component can either be used in uncontrolled or controleld mode.
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { confetti } from 'dom-confetti';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/mapListView/directions/RideDropdownHeader.scss';
//------------------------------------------------------------------------------
// Components ------------------------------------------------------------------
import RideHeader from '@/components/common/header/RideHeader';
import Dropdown from './Dropdown';
import Tooltip from '@/components/common/form/Tooltip';
import { analyticsWithData } from '@/helpers/analytics';
import { set as setLocalStore } from '@/helpers/persistor';
//------------------------------------------------------------------------------
// Store -----------------------------------------------------------------------
import { openDropdown } from '@/store/rides';
import { clamp } from '@/helpers/functions';
import { translate } from '@/helpers/i18n';
const t = translate('directions.RideDropdownHeader');
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class RideDropdownHeader extends React.Component {
    onChangeName = (event) => {
        const { onChangeName } = this.props;
        const name = event.target.value;
        onChangeName(name);
    };

    onFocusName = () => {
        const { name } = this.props.ride;
        this.prevRideName = name;
    };

    onBlurName = () => {
        const { name } = this.props.ride;
        // easter egg
        if (!this.isBirthday(this.prevRideName) && this.isBirthday(name)) {
            this.throwParty();
        }
    };

    isBirthday(name) {
        return (name || '').toLowerCase().indexOf('birthday') >= 0;
    }

    throwParty() {
        const { name } = this.props.ride;
        const colors = ['#fff', '#f60', '#202020', '#eee'];
        const elementCount = clamp(
            parseInt(name.match(/\d+/), 10) || 50,
            30,
            150
        );
        confetti(this.$el, { angle: -10, spread: 60, elementCount, colors });
    }

    setRef = (el) => {
        this.$el = el;
    };

    isInitialLoad = true;

    componentDidUpdate = (prevProps) => {
        const { prevIsMobile, prevRide } = prevProps;
        const {
            ride: { thisRide },
            onToggleDropdown
        } = this.props;
        if (
            !!prevRide &&
            !prevIsMobile &&
            this.isInitialLoad &&
            (thisRide || {}).id !== (prevRide || {}).id
        ) {
            onToggleDropdown(true);
            this.isInitialLoad = false;
        }
    };

    render() {
        const {
            ride,
            dropdownOpen,
            onToggleDropdown,
            onClickBack,
            onClickEdit,
            isCreate,
            updateUploadModal,
            canEdit
        } = this.props;
        const containerStyle = {
            width: dropdownOpen ? '390px' : '24px'
        };

        const containerSubStyle = { width: dropdownOpen ? '368px' : '0px' };

        const expandPanelIcon = !dropdownOpen
            ? '/img/icon-panel-expand.svg'
            : '/img/icon-panel-collapse.svg';

        return (
            <div 
            // style={containerStyle}
            >
                <div
                    className={classNames(style.sidePanelToggle, {
                        [style.expanded]: dropdownOpen
                    })}
                    onClick={() => onToggleDropdown(!dropdownOpen)}>
                    <Tooltip
                        value={
                            dropdownOpen
                                ? t('Collapse location list')
                                : t('Open location list')
                        }
                        placement="e"
                        noDelay>
                        <img src={expandPanelIcon} />
                    </Tooltip>
                </div>
                <div
                    // className={style.containerStyle}
                    style={containerSubStyle}
                    onScroll={this.onScroll}>
                    {dropdownOpen && (
                        <div
                            className={style.RideDropdownHeader}
                            ref={this.setRef}
                            style={containerSubStyle}>
                            <RideHeader
                                dropdownOpen={dropdownOpen}
                                ride={ride}
                                onClickBack={onClickBack}
                                onClickEdit={onClickEdit}
                                inputProps={
                                    onClickEdit
                                        ? {
                                              onChange: this.onChangeName,
                                              onFocus: this.onFocusName,
                                              onBlur: this.onBlurName
                                          }
                                        : {}
                                }
                                transparent={!dropdownOpen}
                            />
                            <Dropdown
                                isOpen={dropdownOpen}
                                ride={ride}
                                editable={!!onClickEdit}
                                isCreate={isCreate}
                                onClickBack={onClickBack}
                                canEdit={canEdit}
                                updateUploadModal={updateUploadModal}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
//------------------------------------------------------------------------------
// Redux State -----------------------------------------------------------------
const mapStateToProps = (state) => {
    return {
        dropdownOpen: state.rides.preview.dropdownOpen
    };
};
//------------------------------------------------------------------------------
// Redux Actions ---------------------------------------------------------------
const mapDispatchToProps = (dispatch) => {
    return {
        onToggleDropdown: (value) => {
            const locationsAnalyticsState = value ? 'open' : 'close';
            analyticsWithData('location list', {
                value: locationsAnalyticsState
            });
            setLocalStore('ride.sidebar', { value: locationsAnalyticsState });
            return dispatch(openDropdown(value));
        }
    };
};
//------------------------------------------------------------------------------
// Redux Connect ---------------------------------------------------------------
const container = connect(
    mapStateToProps,
    mapDispatchToProps
)(RideDropdownHeader);
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default container;
