/******************************************************************************\
* File: Arrow.jsx
*
* Author: Gigster
*
* Description: SVG icon of Arrow
*
* Notes: 
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as css from '@/style/common/icons/Icon.scss';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Arrow extends React.Component {
    render() {
        const { className, style, onClick } = this.props;

        const cn = classNames(css.Icon, {
            [css['clickable']]: onClick,
            [className]: className
        });

        return (
            <svg
                className={cn}
                viewBox="0 0 15 10"
                onClick={onClick}
                style={style}
            >
                <g id="Preview" strokeWidth="0" fillRule="evenodd">
                    <g
                        id="No-Location-Selected"
                        transform="translate(-1245.000000, -1003.000000)"
                    >
                        <g
                            id="First-Row"
                            transform="translate(179.000000, 605.000000)"
                        >
                            <g
                                id="See-More"
                                transform="translate(991.000000, 394.000000)"
                            >
                                <path
                                    d="M89.9901408,8.85261201 C89.8602299,8.53721705 89.6523725,8.25980944 89.3855555,8.04788003 L88.386241,7.00822628 L86.1877491,4.69899536 L85.6880919,4.19916183 C85.453253,3.9467459 85.0580241,3.93225073 84.8051976,4.16717249 C84.7947048,4.17766899 84.7837123,4.18816549 84.7737191,4.19916183 C84.5223916,4.46957177 84.5223916,4.88843227 84.7737191,5.15884221 L87.7716626,8.31779013 L75.6449813,8.31779013 C75.2857278,8.32828664 75.0004235,8.62318842 75.0004235,8.98256873 C74.9939279,9.16800697 75.062381,9.34794704 75.1902932,9.48240226 C75.3077127,9.60886015 75.4720999,9.68133601 75.6449813,9.68233568 L87.7866523,9.68233568 L84.7887089,12.8407838 C84.5373813,13.1116935 84.5373813,13.5300542 84.7887089,13.8004641 C85.0240474,14.0533799 85.4192763,14.0678751 85.6711036,13.8324535 C85.6825957,13.821957 85.6925888,13.8119603 85.7030816,13.8004641 L87.1620808,12.266475 L89.4005452,9.9122591 C89.6353841,9.66734067 89.975151,9.41242557 89.9951373,9.04254875 C90.0031319,8.9790699 90.0011332,8.9150912 89.9901408,8.85261201"
                                    id="Fill-1"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Arrow;
