/******************************************************************************\
 * File: MenuToggle.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/common/header/MenuToggle.scss';
import Hamburger from '@/components/common/icons/Hamburger';
import { showMobileMenu } from '@/store/app';
import Link from '@/components/common/header/Link';
import { translate } from '@/helpers/i18n';
const t = translate('header.MenuToggle');
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class MenuToggle extends React.Component {
    render() {
        const { className, dark, showMobileMenu, showText = true } = this.props;

        const cn = classNames(style.MenuToggle, {
            [className]: className,
            [style['dark']]: !!dark
        });

        return (
            <Link className={cn} onClick={showMobileMenu}>
                {showText && <div className={style.text}>{t('Menu')}</div>}
                <Hamburger className={style.icon} />
            </Link>
        );
    }
}
//------------------------------------------------------------------------------
// Redux State -----------------------------------------------------------------
const mapStateToProps = (state, ownProps) => {
    return {};
};
//------------------------------------------------------------------------------
// Redux Actions ---------------------------------------------------------------
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        showMobileMenu: () => dispatch(showMobileMenu(true))
    };
};
//------------------------------------------------------------------------------
// Redux Connect ---------------------------------------------------------------
const container = connect(mapStateToProps, mapDispatchToProps)(MenuToggle);
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default container;
