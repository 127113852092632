/******************************************************************************\
 * File: BaseSearchBar.jsx
 *
 * Author: Gigster
 *
 * Description: BaseSearchBar
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import Autosuggest from 'react-autosuggest';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/common/search/SearchBar.scss';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------
import Input from '@/components/common/form/Input';
import SearchIcon from '@/components/common/icons/Search';
import { capitalize } from '@/helpers/functions';
import MCP from '@/helpers/MCP';
//App context
import AppContext from '@/contexts/AppContext';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class BaseSearchBar extends React.Component {
    static defaultProps = {
        icon: SearchIcon,
        inputProps: {},
        getSuggestionValue: (item) => item.value,
        shortcutEnabled: false
    };

    state = { focused: false };

    componentDidMount() {
        if (this.props.shortcutEnabled) {
            document.addEventListener('keydown', this.globalKeyListener, false);
            MCP.on('search focus', this.focus, false);
        }
    }

    componentWillUnmount() {
        if (this.props.shortcutEnabled) {
            document.removeEventListener(
                'keydown',
                this.globalKeyListener,
                false
            );
            MCP.off('search focus', this.focus);
        }
    }

    focus = () => this.$input && this.$input.focus();

    select = () => {
        if (!this.$input) return;

        if (typeof this.$input.setSelectionRange === 'function') {
            this.$input.setSelectionRange(0, this.props.value.length);
        } else {
            this.$input.select();
        }
    };

    blur = () => this.$input && this.$input.blur();

    isFocused = () => this.$input && document.activeElement === this.$input;

    globalKeyListener = (event) => {
        switch (event.key) {
            case '/':
                if (!this.isFocused()) {
                    this.focus();
                    this.select();
                    event.preventDefault();
                }
                break;
            case 'Escape':
                this.blur();
                event.preventDefault();
                break;
        }
    };

    onClickSearch = (event) => {
        const { value, submitOnClick } = this.props;

        if (submitOnClick && value.length) {
            this.submit(event, 'click');
        } else {
            this.focus();
        }

        event.preventDefault();
        event.stopPropagation();
    };

    submit = (event, method = '') => {
        const { onSubmit, value, suggestions, getSuggestionValue } = this.props;

        if (!onSubmit) return;

        const suggestion =
            suggestions.find(
                (suggestion) => getSuggestionValue(suggestion) === value
            ) || suggestions[0];

        const suggestionValue = suggestion && getSuggestionValue(suggestion);

        onSubmit(event, { method, value, suggestion, suggestionValue });
    };

    onChange = (event, data) => {
        const { inputProps, onChange } = this.props;
        onChange && onChange(event, data);
        (inputProps || {}).onChange && inputProps.onChange(event);
    };

    onClickClose = (event) => {
        const { onChange } = this.props;
        onChange && onChange(event, { newValue: '' });
        event.preventDefault();
        event.stopPropagation();
    };

    forwardInputEvent = (fn) => (e) => {
        const listener = `on${capitalize(e.type)}`;
        fn(e);
        if (this.props.inputProps[listener]) {
            this.props.inputProps[listener](e);
        }
    };

    render() {
        const {
            className,
            expandOnFocus,
            icon: IconComponent,
            renderAfter: AfterComponent,
            inputProps,
            invertedDropdown,
            suggestions,
            large,
            placeholder,
            value,
            selectOnFocus,
            transparent,
            centerIcon,
            locale,
            hasId = false,
            ...rest
        } = this.props;

        const { focused } = this.state;

        const { isMobile } = this.context;

        const cn = classNames(style.SearchBar, {
            [className]: !!className,
            [style['large']]: large,
            [style['expand']]: expandOnFocus,
            [style['open']]: focused && AfterComponent,
            [style['inverted']]: invertedDropdown
        });

        return (
            <Autosuggest
                ref={(el) => (this.$autosuggest = el)}
                inputProps={{
                    ...inputProps,
                    onChange: this.onChange,
                    onFocus: this.forwardInputEvent((e) => {
                        this.setState({ focused: true });
                        if (selectOnFocus) {
                            this.select();
                        }
                    }),
                    onBlur: this.forwardInputEvent((e) => {
                        this.setState({ focused: false });
                    }),
                    value: value || '',
                    placeholder,
                    onKeyDown: this.forwardInputEvent((e) => {
                        if (
                            e.key === 'Enter' &&
                            !this.$autosuggest.getHighlightedSuggestion()
                        ) {
                            this.submit(e, 'enter');
                        }
                    }),
                    locale
                }}
                theme={{
                    container: cn,
                    containerOpen: style.open,
                    input: style.input,
                    inputOpen: 'react-autosuggest__input--open',
                    // inputFocused: style.focused,
                    suggestionsContainer: style.dropdown,
                    suggestionsContainerOpen:
                        'react-autosuggest__suggestions-container--open',
                    suggestionsList: 'react-autosuggest__suggestions-list',
                    suggestion: 'react-autosuggest__suggestion',
                    suggestionFirst: 'react-autosuggest__suggestion--first',
                    suggestionHighlighted:
                        'react-autosuggest__suggestion--highlighted'
                }}
                renderInputComponent={(props) => (
                    <Input
                        onSearchClick={this.onClickSearch}
                        onClearClick={this.onClickClose}
                        icon={IconComponent}
                        hideClose={isMobile}
                        transparent={transparent}
                        centerIcon={centerIcon}
                        {...props}
                        innerRef={(el) => (this.$input = el)}
                        id={`${hasId ? 'map-search' : undefined}`}
                        data-testid={`${hasId ? 'map-search' : undefined}`}
                    />
                )}
                renderSuggestionsContainer={({ containerProps, children }) => (
                    <div {...containerProps}>
                        {children}
                        {!!AfterComponent && (
                            <AfterComponent
                                suggestions={suggestions}
                                value={value}
                            />
                        )}
                    </div>
                )}
                suggestions={suggestions}
                {...rest}
            />
        );
    }
}
BaseSearchBar.contextType = AppContext;
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default BaseSearchBar;
