/******************************************************************************\
* File: ArrowAll.jsx
*
* Author: Gigster
*
* Description: SVG icon of Arrow
*
* Notes: 
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as css from '@/style/common/icons/Icon.scss';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class ArrowAll extends React.Component {
    render() {
        const { className, style, onClick } = this.props;

        const cn = classNames(css.Icon, {
            [css['clickable']]: onClick,
            [className]: className
        });

        return (
            <svg
                className={cn}
                viewBox="0 0 15 10"
                // viewBox="0 0 16 16"
                onClick={onClick}
                style={style}
                width="16px"
                height="16px">
                <g
                    id="Search-Flow"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd">
                    <g
                        id="Map-/-Sidepanel-/-View-all-rides-list"
                        transform="translate(-28.000000, -20.000000)">
                        <g
                            id="Icon/Arrow/Back"
                            transform="translate(24.000000, 16.000000)">
                            <rect
                                id="Bounds"
                                x="0"
                                y="0"
                                width="16"
                                height="24"></rect>
                            <polygon
                                id="Shape"
                                fill="#FFFFFF"
                                points="20 11 7.8 11 13.4 5.4 12 4 4 12 12 20 13.4 18.6 7.8 13 20 13"></polygon>
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default ArrowAll;
