/******************************************************************************\
 * File: UploadModal.jsx
 *
 * Author: Gigster
 *
 * Description: Upload GPX modal
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------
import Modal from '@/components/common/Modal';
import { connect } from 'react-redux';
import withRouter from '@/helpers/hooks';

import { clickAway } from '@/helpers/hoc';
import { uploadRideGPX } from '@/helpers/api';
import { getCurrentRide } from '@/store/rides';
import { createRide } from '@/store/edit_ride/ride';
import { unsafeCalculateRide } from '@/helpers/here';
import { WaypointType } from '@/helpers/constants';
import ToggleIcon from '@/components/common/icons/Toggle';
import Spinner from '@/components/common/loading/Spinner';
import RouteType from '@/components/mapListView/RouteType';
import ArrowLink from '@/components/common/ArrowLink';
import { getPolylineLength, getPointsDistances } from '@/helpers/map';
import { convertToLatLng } from '@/helpers/functions';
import {
    setHDAnalyticsUploadSuccess,
    setHDAnalyticsUploadFailed,
    analyticsImportSuccessEvent
} from '@/helpers/analytics';

//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/homeView/UploadModal.scss';

import { translate } from '@/helpers/i18n';
const t = translate('modal.uploadModal');
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class UploadModal extends React.Component {
    fileInputRef = React.createRef();
    constructor(props) {
        super(props);
    }

    state = {
        uploading: this.props.uploading,
        fileRef: this.props.fileRef,
        successfullyUploaded: this.props.successfullyUploaded,
        uploadMsg: this.props.uploadMsg
    };

    onClickAway = () => this.props.onRequestClose();

    successMessage = (res, isRecorded, isOffRoadRoute) => {
        const { updateUploadModal, fileRef, createRide } = this.props;
        const {
            data: { ride }
        } = res;

        const { waypoints } = ride;
        const uploadMsg = isRecorded
            ? 'Your GPX file has been successfully imported as a Recorded Ride. Recorded Rides can’t be edited but can be copied to a Planned Ride.'
            : isOffRoadRoute
            ? 'Your GPX file has been successfully imported as a Planned Ride. Planned Rides are rides that you can edit the locations and add waypoints.<br /><br />The route may vary from the original route.'
            : 'Your GPX file has been successfully imported as a Planned Ride. Planned Rides are rides that you can edit the locations and add waypoints.<br /><br />To support on-road routing, the route has been optimized to less than 100 waypoints. The route may vary from the original route.';
        const successfullyUploaded = true;
        const uploading = false;
        setHDAnalyticsUploadSuccess(isRecorded);
        updateUploadModal(fileRef, uploadMsg, successfullyUploaded, uploading);
        // Transform locationHistory to waypoints for recorded rides
        if (
            isRecorded &&
            !!ride.locationHistory &&
            ride.locationHistory.length > 1
        ) {
            const locationHistoryAsWaypoints = ride.locationHistory.map(
                (waypoint) => ({
                    ...waypoint,
                    type: WaypointType.WAYPOINT
                })
            );
            ride.waypoints = locationHistoryAsWaypoints;
        }
        createRide(ride);
    };

    errorMsg =
        'There was an issue with your file. Try importing the file again or select a new file.';

    failMessage = (e) => {
        const { updateUploadModal, fileRef } = this.props;
        const uploadMsg = this.errorMsg;
        setHDAnalyticsUploadFailed();
        const successfullyUploaded = false;
        const uploading = false;
        this.setState({
            uploading,
            fileRef,
            successfullyUploaded,
            uploadMsg
        });
    };

    render() {
        const {
            onRequestClose,
            isOpen,
            currentRide: { waypoints },
            currentRide: { offRoad },
            currentRide,
            fileRef,
            isRecorded,
            createRide,
            updateUploadModal,
            ...rest
        } = this.props;

        const { uploading, successfullyUploaded, uploadMsg } = this.state;
        const hasError = uploadMsg === this.errorMsg;
        const headerText = successfullyUploaded
            ? t('Ride imported')
            : t('Import GPX');
        const subheaderText =
            !successfullyUploaded && waypoints && waypoints.length > 0
                ? t('Import GPX is only available to start creating a new ride')
                : !!uploadMsg
                ? t(uploadMsg)
                : '';
        const isOffRoadRoute = offRoad;

        return (
            <Modal
                {...rest}
                isOpen={isOpen}
                onAfterOpen={this.onAfterOpen}
                onRequestClose={onRequestClose}>
                {!hasError &&
                    !successfullyUploaded &&
                    !uploading &&
                    uploadMsg && (
                        <div className={style.back} onClick={onRequestClose}>
                            <ToggleIcon className={style.handle} />
                        </div>
                    )}
                <div 
                // className={style.setttingsContainer}
                >
                    <div
                        className={style.Title}
                        style={{
                            textTransform: 'uppercase',
                            fontSize: '52px'
                        }}>
                        {headerText}
                    </div>
                    {subheaderText.length > 0 && (
                        <div
                            className={style.description}
                            dangerouslySetInnerHTML={{ __html: subheaderText }}
                        />
                    )}
                    {/* <hr /> */}
                    {!hasError && !uploading && !successfullyUploaded && (
                        <div>
                            <div
                                className={style.description}
                                style={{ paddingBottom: '16px' }}>
                                {t(
                                    'Planned Rides can be edited and support navigation. GPX Files containing multiple tracks are not currently supported.'
                                )}
                            </div>
                            <div className={style.descriptionWithIcon}>
                                <span>
                                    <img
                                        // className={style.icon}
                                        src={'/img/icon-route-type-on-road.svg'}
                                    />
                                </span>
                                <span className={style.text}>
                                    {t(
                                        'On-road routing will generate a ride that adheres to known-roads.'
                                    )}
                                </span>
                            </div>
                            <div className={style.descriptionWithIcon}>
                                <span>
                                    <img
                                        // className={style.icon}
                                        src={
                                            '/img/icon-route-type-off-road.svg'
                                        }
                                    />
                                </span>
                                <span className={style.text}>
                                    {t(
                                        'Off-road routing will generate a ride directly linking waypoints together, allowing for routing over any area of the map.'
                                    )}
                                </span>
                            </div>
                            <RouteType
                                points={waypoints}
                                isOffRoadRoute={isOffRoadRoute}
                                isHeader={false}
                                ride={currentRide}
                            />
                            <div className={style.link}>
                                <ArrowLink
                                    onClick={() =>
                                        uploadRideGPX(fileRef, {
                                            offRoad: !!isOffRoadRoute,
                                            subType: 'PLANNED'
                                        })
                                            .then((res) => {
                                                this.successMessage(
                                                    res,
                                                    false,
                                                    isOffRoadRoute
                                                );
                                                analyticsImportSuccessEvent(
                                                    'planned ride'
                                                );
                                            })
                                            .catch((e) => this.failMessage())
                                    }
                                    text={t('import as planned ride')}
                                />
                            </div>
                            {isRecorded && (
                                <div>
                                    <div className={style.alt}>
                                        <div className={style.altTextHeader}>
                                            {t('Or')}
                                        </div>
                                        <div className={style.altText}>
                                            {t(
                                                'Recorded Rides capture the date, time, and original GPS track points of the route.'
                                            )}
                                        </div>
                                    </div>
                                    <div className={style.link}>
                                        <ArrowLink
                                            onClick={() =>
                                                uploadRideGPX(fileRef, {
                                                    offRoad: !!isOffRoadRoute,
                                                    subType: 'RECORDED'
                                                })
                                                    .then((res) => {
                                                        this.successMessage(
                                                            res,
                                                            true,
                                                            isOffRoadRoute
                                                        );
                                                        analyticsImportSuccessEvent(
                                                            'recorded ride'
                                                        );
                                                    })
                                                    .catch((e) =>
                                                        this.failMessage()
                                                    )
                                            }
                                            text={t('import as recorded ride')}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                    {uploading && (
                        <div className={style.progressSpinner}>
                            <Spinner className={style.spinner} />
                            <input
                                ref={this.fileInputRef}
                                // className={style.FileInput}
                                style={{ display: 'none' }}
                                type="file"
                                onChange={this.onChangeFile}
                            />
                        </div>
                    )}
                </div>
            </Modal>
        );
    }
}

//------------------------------------------------------------------------------
// Redux State -----------------------------------------------------------------
const mapStateToProps = (state, ownProps) => {
    return { currentRide: getCurrentRide(state) };
};
//------------------------------------------------------------------------------
// Redux Actions ---------------------------------------------------------------
const mapDispatchToProps = (dispatch, ownProps) => ({
    createRide: (ride, imported = true) => {
        const pts = (ride.points || ride.waypoints || ride.locationHistory).map(
            convertToLatLng
        );
        const distances = getPointsDistances(pts);
        const length = getPolylineLength(pts);
        dispatch(
            createRide(ride.waypoints, {
                ...ride,
                distances,
                points: pts,
                imported,
                length
            })
        );
    },
    calculateRide: (ride) => {
        dispatch(unsafeCalculateRide(ride));
    }
});
//------------------------------------------------------------------------------
// Redux Connect ---------------------------------------------------------------
const container = connect(
    mapStateToProps,
    mapDispatchToProps
)(clickAway(UploadModal));
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withRouter(container);
