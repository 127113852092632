/******************************************************************************\
 * File: CreateActionBar.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/common/header/CreateActionBar.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import Actions from '@/components/common/header/Actions';
import Link from '@/components/common/header/Link';
import Undo from '@/components/common/icons/Undo';
import Redo from '@/components/common/icons/Redo';
import CopyIcon from '@/components/common/icons/Copy';
import Tooltip from '@/components/common/form/Tooltip';
import ShareDropdown from '@/components/common/header/ShareDropdown';
import DownloadGPXButton from '@/components/mapListView/DownloadGPXButton';
import PrintIcon from '@/components/common/icons/Print';
import SaveButton from '@/components/mapListView/SaveButton';
import Bookmark from '@/components/common/icons/Bookmark';
import { setBookmark, isBookmarked } from '@/store/bookmarks';
import {
    analyticsForRideWithData,
    analyticsRideEvent
} from '@/helpers/analytics';

//------------------------------------------------------------------------------
// Actions ---------------------------------------------------------------------
import { isAuthenticated, auth, invalidateAuth } from '@/store/auth';
import { undo, redo, canUndo, canRedo, forkRide } from '@/store/edit_ride';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { rideSharePath } from '@/helpers/routes';
import { translate } from '@/helpers/i18n';
const t = translate('header.CreateActionBar');
const tShareBar = translate('header.ShareBar');
const tPrint = translate('directions.PrintView');
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class CreateActionBar extends React.Component {
    render() {
        const {
            className,
            canUndo,
            canRedo,
            undo,
            redo,
            copy,
            ride,
            isBookmarked,
            openConvertRideModal,
            onClickPrint,
            profile,
            isAuthenticated,
            auth,
            invalidateAuth,
            openSettingsModal
        } = this.props;
        const cn = classNames(
            // style.CreateActionBar, 
            {
            [className]: !!className
        });
        const { isRecordedRide, waypoints, offRoad, id, shortId, dealerId } =
            ride;
        const isRidable = !!waypoints && waypoints.length > 1;
        const toggleBookmark = () => {
            const { isBookmarked, setBookmark } = this.props;
            if (!isBookmarked)
                analyticsForRideWithData('star ride', {
                    location: 'map',
                    dealerId
                });
            setBookmark(ride, !isBookmarked);
        };
        const showPrint = !offRoad && !isRecordedRide && isRidable;

        return (
            <Actions className={cn}>
                {!isRecordedRide && (
                    <Tooltip value={t('Undo')} placement="s" noDelay>
                        <Link
                            onClick={undo}
                            title={t('Undo')}
                            id="undo-button"
                            data-testid="undo-button">
                            <Undo
                                className={classNames(style.icon, {
                                    [style['disabled']]: !canUndo
                                })}
                            />
                        </Link>
                    </Tooltip>
                )}
                {!isRecordedRide && (
                    <Tooltip value={t('Redo')} placement="s" noDelay>
                        <Link
                            onClick={redo}
                            title={t('Redo')}
                            id="redo-button"
                            data-testid="redo-button">
                            <Redo
                                className={classNames(style.icon, {
                                    [style['disabled']]: !canRedo
                                })}
                            />
                        </Link>
                    </Tooltip>
                )}
                {!!ride && (
                    <Tooltip
                        value={
                            isBookmarked
                                ? tShareBar('Unbookmark')
                                : tShareBar('Bookmark')
                        }
                        placement="s"
                        noDelay>
                        <Link
                            className={classNames(cn, {
                                [style.bookmark]: true,
                                [style['disabled']]: !id
                            })}
                            onClick={(e) => {
                                if (e) {
                                    e.stopPropagation();
                                    e.preventDefault();
                                }
                                !!shortId && toggleBookmark(toggleBookmark);
                            }}
                            id={
                                isBookmarked
                                    ? 'unbookmark-button'
                                    : 'bookmark-button'
                            }
                            data-testid={
                                isBookmarked
                                    ? 'unbookmark-button'
                                    : 'bookmark-button'
                            }>
                            <span
                                className={classNames(style.bookmark, {
                                    [style['disabled']]: !id
                                })}>
                                <Bookmark
                                    active={isBookmarked ? true : undefined}
                                    disabled={!shortId}
                                    inactiveClassName={style.disabled}
                                />
                            </span>
                        </Link>
                    </Tooltip>
                )}
                <ShareDropdown
                    active={!!ride && (shortId || id)}
                    url={rideSharePath(shortId)}
                    iconClassName={style.icon}
                    activeClassName={style.active}
                    inactiveClassName={style.disabled}
                    id="share-button"
                />
                {showPrint && (
                    <Tooltip value={tPrint('Print')} placement="s" noDelay>
                        <div className={style.printIcon}>
                            <PrintIcon
                                onClick={onClickPrint}
                                id="print-button"
                            />
                        </div>
                    </Tooltip>
                )}
                <DownloadGPXButton
                    ride={ride}
                    padded={true}
                    position="s"
                    id="gpx-button"
                />
                <Tooltip value={t('Copy ride')} placement="s" noDelay>
                    <div>
                        <CopyIcon
                            className={classNames(style.icon, {
                                [style['disabled']]: !id
                            })}
                            onClick={() => {
                                if (!id) return;
                                analyticsRideEvent('copy ride');
                                openConvertRideModal(ride);
                            }}
                            id="copy-button"
                        />
                    </div>
                </Tooltip>
                <SaveButton padded={true} widthAuto={true} id="save-button" />
            </Actions>
        );
    }
}
//------------------------------------------------------------------------------
// Redux State -----------------------------------------------------------------
const mapStateToProps = (state, ownProps) => {
    return {
        ride: state.edit_ride.present.ride,
        canUndo: canUndo(state),
        canRedo: canRedo(state),
        isAuthenticated: isAuthenticated(state),
        profile: state.user.profile,
        isBookmarked: isBookmarked(state.edit_ride.present.ride.id, state)
    };
};
//------------------------------------------------------------------------------
// Redux Actions ---------------------------------------------------------------
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        undo: () => dispatch(undo()),
        redo: () => dispatch(redo()),
        copy: (ride) => dispatch(forkRide(ride)),
        auth: () => dispatch(auth()),
        invalidateAuth: () => dispatch(invalidateAuth()),
        setBookmark: (ride, value) => dispatch(setBookmark(ride.id, value))
    };
};
//------------------------------------------------------------------------------
// Redux Connect ---------------------------------------------------------------
const container = connect(mapStateToProps, mapDispatchToProps)(CreateActionBar);
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default container;
