// extracted by mini-css-extract-plugin
var _1 = "Footer__Footer___bkgNU";
var _2 = "Footer__active___SqoNX";
var _3 = "Footer__arrow___PrymT";
var _4 = "Footer__count___bUweM";
var _5 = "Footer__dark___LWSLo";
var _6 = "Footer__left___WmSf7";
var _7 = "Footer__map-list-view___BHFGL";
var _8 = "Footer__margin___a1wwU";
var _9 = "Footer__open___s7AzO";
var _a = "Footer__right___owqpG";
var _b = "Footer__section___XtzkF";
var _c = "Footer__toggle___nWsMg";
var _d = "Footer__wiggle___ZmMHq";
export { _1 as "Footer", _2 as "active", _3 as "arrow", _4 as "count", _5 as "dark", _6 as "left", _7 as "map-list-view", _8 as "margin", _9 as "open", _a as "right", _b as "section", _c as "toggle", _d as "wiggle" }
