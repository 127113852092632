/******************************************************************************\
* File: Twitter.jsx
*
* Author: Gigster
*
* Description: SVG icon of Twitter
*
* Notes: 
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/common/icons/Icon.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
import TwitterIcon from './social/Twitter';
class Twitter extends React.Component {
    render() {

        return (
            <div className={style['less-padding']}>
                <TwitterIcon />
            </div>
        );
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Twitter;
