import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import * as style from '@/style/mapListView/Map.scss';
import Marker from '@/components/common/map/Marker';
import Route from '@/components/common/map/icons/Route';
import MyLocation from '@/components/common/map/icons/MyLocation';
import RenderRide from '@/components/mapListView/RenderRide';
import * as ridesActions from '@/store/rides';
import * as mapActions from '@/store/map';
import { Routes } from '@/helpers/routes';
import { rideImage } from '@/helpers/functions';
import { WaypointType } from '@/helpers/constants';
import { previewRideCallback } from '@/store/rides';
import { handleTraverseRoute } from '@/store/rides/preview';
import withRouter from '@/helpers/hooks';

class MapPreview extends React.PureComponent {
    componentDidMount() {
        const {
            loadRide,
            navigate,
            ride,
            clearSelectedData,
            previewCustomRide,
            params
        } = this.props;

        if (location.pathname === Routes.RIDE_CREATE_PREVIEW) {
            // we're in preview but with a temporary ride
            if ((ride.waypoints || []).length >= 2) {
                previewCustomRide(ride);
            } else {
                navigate(Routes.RIDE_CREATE, { replace: true });
            }
        } else {
            loadRide(params.id);
        }
        clearSelectedData();
    }

    shouldTraverseRoute = (ride, prevRide, location) => {
        return (
            location.pathname === Routes.RIDE_CREATE_PREVIEW &&
            ride &&
            prevRide &&
            ride.points.length !== prevRide.points.length &&
            ride.distances.length > 0
        );
    };

    componentDidUpdate(prevProps) {
        const { ride, traverseRoute, location } = this.props;
        const { ride: prevRide } = prevProps;
        if (this.shouldTraverseRoute(ride, prevRide, location)) {
            traverseRoute(0);
        }
    }

    render() {
        const {
            className,
            ride,
            selectedRide,
            hoverWaypointId,
            currentRoutePoint,
            myLocation
        } = this.props;

        const cn = classNames(
            // style.MapPreview, 
            {
            [className]: className
        });

        const img = ride && rideImage(ride);
        return (
            <div className={cn}>
                <Helmet>
                    <title>{ride.name}</title>
                </Helmet>

                {/* My location dot: */}
                {myLocation && (
                    <Marker position={myLocation} component={MyLocation} />
                )}

                {/* Current scrubber dot on route: */}
                {currentRoutePoint && (
                    <Marker
                        position={currentRoutePoint}
                        component={MyLocation}
                        componentProps={{
                            color: '#000'
                        }}
                        zIndex={100}
                    />
                )}

                {/* The ride: */}
                <RenderRide
                    ride={ride}
                    highlightId={hoverWaypointId}
                    waypointProps={(point) =>
                        point.type === WaypointType.WAYPOINT
                            ? {}
                            : {
                                  onClick: (event) => {
                                      this.props.onMarkerClick({
                                          ...point,
                                          markerType: 'waypoint'
                                      });
                                  }
                              }
                    }
                />

                {/* The ride start marker with the image: */}
                {!!ride.waypoints && img && (
                    <Marker
                        position={ride.waypoints[0]}
                        component={Route}
                        componentProps={{
                            selected: selectedRide.id === ride.id,
                            img
                        }}
                        key={ride.id}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        myLocation: state.map.myLocation,
        hoverWaypointId: state.map.hoverWaypointId,
        currentRoutePoint: state.rides.preview.currentRoutePoint,
        selectedRide: state.rides.selected || {},
        ride: ridesActions.getCurrentRide(state) || {}
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        loadRide: (id) => dispatch(ridesActions.routing_previewRide(id)),
        clearSelectedData: () =>
            dispatch(mapActions.update('selectedData', null)),
        previewCustomRide: (ride) => dispatch(previewRideCallback(ride)),
        traverseRoute: (delta) => dispatch(handleTraverseRoute(delta))
    };
};

const container = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(MapPreview)
);

export default container;
