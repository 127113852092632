/******************************************************************************\
* File: Actions.jsx
*
* Author: Gigster
*
* Description: 
*
* Notes: 
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/common/header/Actions.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Actions extends React.Component {
    render() {
        const { className, children } = this.props;

        const cn = classNames(style.Actions, {
            [className]: !!className
        });

        return <div className={cn}>{children}</div>;
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Actions;
