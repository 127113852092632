/******************************************************************************\
* File: Redo.jsx
*
* Author: Gigster
*
* Description: 
*
* Notes: 
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/common/icons/Icon.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Redo extends React.Component {
    render() {
        const { className, onClick } = this.props;

        const cn = classNames(style.Icon, {
            [style['clickable']]: onClick,
            [className]: !!className
        });

        return (
            <svg className={cn} viewBox="0 0 18 18" onClick={onClick}>
                <path
                    fill="#FFF"
                    fillRule="evenodd"
                    d="M17.844 6.67a1.054 1.054 0 0 0-.469-.451l-3.633-1.83-1.28-.646-.279-.14-.14.279L9.578 8.8c-.245.49-.069 1.133.41 1.395.168.092.351.135.542.126.38-.016.715-.252.885-.592l1.08-2.157c.225.599.343 1.24.343 1.899 0 3.063-2.557 5.539-5.644 5.394-2.757-.129-4.994-2.372-5.123-5.136-.145-3.095 2.325-5.659 5.38-5.659.583 0 1.053-.484 1.032-1.073A1.05 1.05 0 0 0 7.428 2C3.278 2.013-.084 5.444.002 9.627c.08 3.932 3.235 7.157 7.155 7.309 4.242.165 7.746-3.248 7.746-7.465a7.5 7.5 0 0 0-.388-2.385l1.956.985a1.027 1.027 0 0 0 1.303-.326c.223-.322.25-.726.07-1.075"
                />
            </svg>
        );
    }
}
export default Redo;
