/******************************************************************************\
 * File: Dealer.jsx
 *
 * Author: Gigster
 *
 * Description: Dealer UI
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/common/cards/Dealer.scss';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------
import Card from '@/components/common/cards/Card';
import Bookmark from '@/components/common/icons/Bookmark';
import Link from '@/components/common/header/Link';
import Phone from '@/components/common/icons/Phone';
import Clock from '@/components/common/icons/Clock';
import { isAuthenticated, auth } from '@/store/auth';
import { setMyDealer } from '@/store/user';

import {
    routing_selectDealer,
    previewDealer,
    selectProgramsByType
} from '@/store/dealers';
import { updateSidebar, centerPointZoom } from '@/store/map';
import * as mapActions from '@/store/map';

//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import Tooltip from '@/components/common/form/Tooltip';
import {
    prettyAddress,
    prettyAddressSplit,
    prettyMiles,
    prettyPhone,
    phoneUrl,
    getOpenState,
    isEVDealer
} from '@/helpers/functions';
import { setBookmark, isBookmarked } from '@/store/bookmarks';
import { analyticsWithData } from '@/helpers/analytics';
import { Routes, generatePath } from '@/helpers/routes';
import { translate } from '@/helpers/i18n';
const t = translate('cards.Dealer');
const supplimentalTranslations = translate('sidebar.DealerDetails');
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------

const DealerOpenState = ({ openState }) => (
    <div className={style.DealerOpenState}>
        <Clock />
        <span 
        // className={style.openState}
        >{openState}</span>
    </div>
);

const evDealer = () => (
    <div className={style.evDealer} style={{ marginLeft: '-4px' }}>
        <img src="/img/icon-hd-logo-ev.svg" style={{ verticalAlign: 'top' }} />
        <span style={{ fontSize: '12px' }}>
            {t('Electric Motorcycle Dealer')}
        </span>
    </div>
);

const Detail = ({ dealer, phone, dealerHours, timeZone, isSidePanel }) => {
    const openState = getOpenState(dealerHours, timeZone);
    const [address, rest] = prettyAddressSplit(dealer, true) || [];
    return (
        <div>
            {isSidePanel ? (
                <div className={style.sidepanel}>
                    {!!dealer && (
                        <div 
                        // className={style.dealerAddress}
                        >
                            <div
                                dangerouslySetInnerHTML={{ __html: address }}
                            />
                            <div dangerouslySetInnerHTML={{ __html: rest }} />
                        </div>
                    )}
                    <div className={style.Detail}>
                        {openState && <DealerOpenState openState={openState} />}
                    </div>
                    {!!dealer && isEVDealer(dealer) && evDealer()}
                </div>
            ) : (
                <div className={style.Detail}>
                    {phone && (
                        <a
                            onClick={(e) => e.stopPropagation()}
                            href={phoneUrl(phone)}>
                            <Phone /> {prettyPhone(phone)}
                        </a>
                    )}
                    {openState && <DealerOpenState openState={openState} />}
                </div>
            )}
        </div>
    );
};

class Dealer extends React.Component {
    toggleBookmark = () => {
        const { item, isBookmarked, isAuthenticated, setMyDealer, auth } =
            this.props;
        !!isAuthenticated
            ? setMyDealer(item.dealerId, isBookmarked)
            : auth(() => setMyDealer(item.dealerId, isBookmarked));
    };

    render() {
        const {
            className,
            item: dealer,
            setBookmark,
            updateMap,
            previewDealer,
            selectDealer,
            openSidebar,
            onHover,
            isBookmarked,
            partiallyExpanded,
            didSelectItem,
            forkable,
            isSidePanel,
            idLabel,
            rowIndex,
            centerPointZoom,
            ...rest
        } = this.props;

        const cn = classNames(style.Dealer, {
            [classNames]: !!className
        });

        const { dealerName: name } = dealer;

        return (
            <Card
                className={cn}
                to={generatePath(Routes.MAP_DEALER, { id: dealer.dealerId })}
                onClick={() => {}}
                title={name}
                Detail={Detail}
                detailProps={{ ...dealer, isSidePanel, dealer }}
                Action={DealerAction}
                actionProps={{
                    dealer,
                    isBookmarked,
                    onClickBookmark: this.toggleBookmark,
                    rowIndex,
                    idLabel
                }}
                footerLinks={[
                    {
                        text: t('Details'),
                        onClick: () => {
                            previewDealer(dealer);
                            openSidebar('dealer');
                            centerPointZoom(dealer.position);
                        }
                    }
                ]}
                onMouseEnter={() => partiallyExpanded && onHover()}
                isSidePanel={isSidePanel}
                isDealer={true}
                {...rest}
                large={false}
                style={{ height: '196px' }}
                idLabel={idLabel}
                rowIndex={rowIndex}
            />
        );
    }
}

const DealerAction = ({
    dealer,
    isBookmarked,
    onClickBookmark,
    isSidePanel,
    idLabel,
    rowIndex
}) => {
    const cn = classNames(
    //     style.Bookmark, {
    //     [style.isSidePanel]: isSidePanel,
    //     [style.active]: isBookmarked
    // }
    );
    const id = idLabel ? `${idLabel + '-favorite-' + rowIndex}` : undefined;
    const dataId = idLabel ? `${idLabel + '-favorite-' + rowIndex}` : undefined;
    return (
        <Link
            className={cn}
            onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onClickBookmark(dealer);
                if (!isBookmarked)
                    analyticsWithData(`star dealer`, {
                        location: 'sidebar list view',
                        id: dealer.id,
                        dealerId: dealer.dealerId
                    });
            }}
            id={id}
            data-testid={dataId}>
            <Tooltip
                value={
                    isBookmarked
                        ? supplimentalTranslations('My Dealer')
                        : supplimentalTranslations('Make My Dealer')
                }
                placement="w"
                noDelay>
                <Bookmark active={isBookmarked} />
            </Tooltip>
        </Link>
    );
};

//------------------------------------------------------------------------------
// Redux State -----------------------------------------------------------------
const mapStateToProps = (state, ownProps) => {
    const { item } = ownProps;
    return {
        isBookmarked:
            item && item.dealerId === (state.user.data || {}).preferredDealer,
        isAuthenticated: isAuthenticated(state),
        user: state.user
    };
};

//------------------------------------------------------------------------------
// Redux Actions ---------------------------------------------------------------
const mapDispatchToProps = (dispatch, ownProps) => {
    const { item, didSelectItem } = ownProps;
    return {
        setBookmark: (value) => dispatch(setBookmark(item.id, value)),
        updateMap: (field) => (value) =>
            dispatch(mapActions.update(field, value)),
        onSelect: () => {
            dispatch(selectItemById(item.dealerId));
            didSelectItem && didSelectItem(item);
        },
        previewDealer: (dealer) => dispatch(previewDealer(dealer)),
        selectDealer: (id) => dispatch(routing_selectDealer(id)),
        openSidebar: (value, data = null) =>
            dispatch(updateSidebar(value, data)),
        onHover: () => {},
        setMyDealer: (dealerId, isMyDealer) =>
            dispatch(setMyDealer(dealerId, isMyDealer)),
        auth: (cb) => dispatch(auth(cb)),
        centerPointZoom: (point) => dispatch(centerPointZoom(point))
    };
};
//------------------------------------------------------------------------------
// Redux Connect ---------------------------------------------------------------
const container = connect(mapStateToProps, mapDispatchToProps)(Dealer);
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default container;
