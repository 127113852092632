/******************************************************************************\
 * File: Phone.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/common/icons/Icon.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Phone extends React.Component {
    render() {
        const { className, onClick } = this.props;

        const cn = classNames(style.Icon, {
            [style['clickable']]: onClick,
            [className]: !!className
        });

        return (
            <svg className={cn} viewBox="0 0 14 14" onClick={onClick}>
                <title>Page 1</title>
                <desc>Created with Sketch.</desc>
                <defs />
                <g id="Page-1" strokeWidth="1" fill="none" fillRule="evenodd">
                    <path d="M2.58420931,1.02692451 C1.72046531,1.36318051 1.17608131,2.59505251 1.04795331,3.44324451 C0.647953314,6.19508451 2.82427331,8.57956451 4.82427331,10.1469245 C6.60059331,11.5387965 10.0079533,13.8269243 12.0242733,11.6187965 C12.2805293,11.3469245 12.5842733,10.9469245 12.5680173,10.5469245 C12.5198925,9.87505251 11.8961453,9.39505251 11.4161453,9.02692451 C11.0480173,8.75505251 10.2798893,8.00318051 9.79982531,8.01879651 C9.36795331,8.03504611 9.09608131,8.48254051 8.82356931,8.75505251 L8.34356931,9.23505251 C8.26356931,9.31505251 7.23982531,8.64318051 7.12731331,8.56318051 C6.71105731,8.29130851 6.31105731,7.98692451 5.95918531,7.65130851 C5.60731331,7.31505251 5.28731331,6.94756451 5.01544131,6.56318051 C4.93544131,6.45130531 4.27918531,5.47505251 4.34356931,5.39505251 C4.34356931,5.39505251 4.90356931,4.78692451 5.06356931,4.54692451 C5.39982531,4.03505251 5.65544131,3.63505251 5.27169731,3.02692451 C5.12794691,2.80318051 4.95169731,2.62692451 4.75982531,2.43505251 C4.42356931,2.11505251 4.08795331,1.77879651 3.70356931,1.50692451 C3.49606531,1.34630051 3.14419331,1.05879651 2.82419331,1.01066851 C2.74420931,0.994425305 2.64795331,0.994425305 2.58420931,1.02692451 Z" />
                </g>
            </svg>
        );
    }
}
export default Phone;
