/******************************************************************************\
* File: SectionTitle.jsx
*
* Author: Gigster
*
* Description: Home page
*
* Notes: 
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/homeView/SectionTitle.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class SectionTitle extends React.Component {
    render() {
        const { text, size } = this.props;

        const sizeStyle = {
            [style[`size-${size}`]]: !!size
        };

        const cn = classNames(style.container, sizeStyle);
        const sectionTitle = classNames(style.SectionTitle, sizeStyle);

        return (
            <div className={cn}>
                <div className={sectionTitle}>{text}</div>
            </div>
        );
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default SectionTitle;
