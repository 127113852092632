/******************************************************************************\
 * File: Event.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/common/icons/Icon.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Event extends React.Component {
    render() {
        const { className, onClick, ...rest } = this.props;

        const cn = classNames(style.Icon, {
            [style['clickable']]: onClick,
            [className]: !!className
        });

        return (
            <svg className={cn} viewBox="0 0 16 16" onClick={onClick} {...rest}>
                <g fillRule="evenodd">
                    <path
                        fill="none"
                        d="M2.5 3.5h11v10h-11z"
                        style={{ fill: 'none' }}
                    />
                    <path
                        stroke="none"
                        d="M3.5 1.5h2v2h-2zM10.5 1.5h2v2h-2z"
                        style={{ stroke: 'none' }}
                    />
                    <path
                        stroke="none"
                        d="M3 3h10v3H3zM4 7h2v2H4zM4 10h2v2H4zM7 7h2v2H7zM7 10h2v2H7zM10 7h2v2h-2zM10 10h2v2h-2z"
                        style={{ stroke: 'none' }}
                    />
                </g>
            </svg>
        );
    }
}
export default Event;
