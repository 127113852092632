/******************************************************************************\
 * File: RouteChargingStationMarker.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/common/icons/Icon.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class RouteChargingStationMarker extends React.Component {
    render() {
        const { className, onClick, highlight } = this.props;

        const cn = classNames(style.Icon, 'charging-marker', {
            [style['clickable']]: onClick,
            [className]: className
        });

        return (
            <div
                style={{
                    ...style,
                    height: '40px',
                    width: '37px',
                    margin: '-40px 0 0 -19px'
                }}>
                <svg
                    style={{
                        height: '26px',
                        width: '26px'
                    }}
                    className={cn}
                    onClick={onClick}
                    viewBox="0 0 26 26">
                    <g fill="none" fillRule="evenodd">
                        <circle cx="13" cy="13" r="13" fill="#000" />
                        <path d="M0 0h26v26H0z" opacity=".6" />
                        <path
                            fill="#FFF"
                            fillRule="nonzero"
                            d="M11.432 21.775l.4-7.195-3.382.745 5.876-11.1.314 6.598 3.56-.896z"
                        />
                    </g>
                </svg>
            </div>
        );
    }
}

export default RouteChargingStationMarker;
