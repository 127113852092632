/******************************************************************************\
* File: Spinner.jsx
*
* Author: Gigster
*
* Description: 
*
* Notes: 
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/common/loading/Spinner.scss';
//------------------------------------------------------------------------------
// React Function -----------------------------------------------------------------
const Spinner = (props) => {
    const { className } = props;
    const cn = classNames(style.Spinner, {
        [className]: className
    });

    return (
        <div className={cn}>
            <div className={style.cube1} />
            <div className={style.cube2} />
        </div>
    );
};
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Spinner;
