/******************************************************************************\
* File: Pin.jsx
*
* Author: Gigster
*
* Description: 
*
* Notes: 
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/common/icons/Icon.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Pin extends React.Component {
    render() {
        const { className, onClick } = this.props;

        const cn = classNames(style.Icon, {
            [style['clickable']]: onClick,
            [style['no-stroke']]: true,
            [className]: !!className
        });

        return (
            <svg className={cn} viewBox="0 0 20 28" onClick={onClick}>
                <path
                    fill="#FFF"
                    fillRule="evenodd"
                    d="M9.765 14.371c-2.492 0-4.513-1.91-4.513-4.267s2.02-4.268 4.513-4.268 4.513 1.91 4.513 4.268c0 2.357-2.02 4.267-4.513 4.267M17 2.943h.003C13.134-.735 6.845-.75 2.955 2.907l-.039.036C-.566 6.25-.998 12.466 1.99 16.233l7.967 10.94 7.968-10.925c2.988-3.767 2.556-9.999-.926-13.305"
                />
            </svg>
        );
    }
}
export default Pin;
