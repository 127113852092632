/******************************************************************************\
 * File: ChallengesView.jsx
 *
 * Author: Gigster
 *
 * Description: Home page
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import { connect } from 'react-redux';
import withRouter from '@/helpers/hooks';
import { Helmet } from 'react-helmet';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/homeView/HomeView.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import Link from '@/components/common/header/Link';
import Hero from '@/components/homeView/Hero';
import CardGrid from '@/components/common/cards/CardGrid';
import Ride from '@/components/common/cards/Ride';
import Footer from '@/components/homeView/Footer';
import Section from '@/components/homeView/Section';
import SearchBar from '@/components/common/search/SearchBar';
import NoItems from '@/components/mapListView/NoItems';
import ChallengeModal from '@/components/homeView/ChallengeModal';
//------------------------------------------------------------------------------
// Actions ---------------------------------------------------------------------
import * as mapActions from '@/store/map';
import {
    selectItemById as selectChallengeById,
    fetchMany as fetchChallenges
} from '@/store/challenges';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import {
    __,
    setupGeolocation,
    cleanupGeolocation,
    responsiveToggle
} from '@/helpers/functions';
import { ensureGigyaWebSDK } from '@/helpers/gigya';
import { Routes } from '@/helpers/routes';
import { getSiteName } from '@/helpers/constants';
import { centerZoomToString } from '@/helpers/url';

import { translate } from '@/helpers/i18n';
const t = translate('shared.BadgesView');

//App context
import AppContext from '@/contexts/AppContext';

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class ChallengesView extends React.Component {
    componentDidMount = () => {
        const {
            onReceiveLocation,
            fetchChallenges,
            selectChallengeById,
            location
        } = this.props;

        const id = this.getId(location);
        // callback cannot be bound to the this context
        const didReceiveLocation = (locationInfo) => {
            onReceiveLocation(locationInfo, () => {
                fetchChallenges();
            });
        };
        if (id) console.log(selectChallengeById(id));

        // If browser has geolocation, setup callbacks
        ensureGigyaWebSDK(() => {
            this.watchGeoId = setupGeolocation(
                didReceiveLocation,
                window.hd.gigya
            );
        });
    };

    componentWillUnmount = () => {
        // Clear the geolocation watch callback if it was created
        cleanupGeolocation(this.watchGeoId);
    };

    getNumColumns = (mobile, tablet, desktop, fallback = 1) => {
        const { screenSize } = this.context;

        return responsiveToggle(screenSize, fallback, mobile, tablet, desktop);
    };

    selectedChallenge = (items, challengeID) => {
        if (!items) return;
        return items.data.find((item) => item.id === challengeID);
    };

    getId = ({ pathname }) => pathname.split('/').pop();

    render() {
        const { home, myLocation, centerZoom, challenges, location } =
            this.props;
        const id = this.getId(location);
        const selectedItem = this.selectedChallenge(challenges, id);
        const { isMobile } = this.context;
        const NUM_ITEMS = 9;
        const rides = __(home, 'nearRides', 'data') || [];

        const venturaCalifornia = { lat: 34.2746, lng: -119.229 };

        return (
            <div className={style.HomeView}>
                <Helmet>
                    <title>{getSiteName()}</title>
                </Helmet>

                {!!selectedItem && challenges.data.length > 0 && (
                    <ChallengeModal
                        large={true}
                        className="home-modal-modal-bg"
                        selected={selectedItem}
                        isMobile={isMobile}
                    />
                )}
                <Hero
                    title={t('Find a Ride')}
                    main
                    footer={
                        <Link
                            to={`${Routes.MAP}?pos=${centerZoomToString(
                                venturaCalifornia,
                                10
                            )}`}
                            onClick={() => centerZoom(venturaCalifornia, 10)}
                            plain>
                            {t('Ventura California')}
                            <br />
                            34.2746° N, 119.2290° W
                        </Link>
                    }>
                    <SearchBar
                        className={style.searchBar}
                        large
                        shortcutEnabled
                    />
                </Hero>

                <div className={style.content}>
                    <Section
                        title={t('Rides For You')}
                        className={style.gridSection}>
                        {rides.length > 0 ? (
                            <CardGrid
                                Item={Ride}
                                items={rides.slice(0, NUM_ITEMS)}
                                columns={this.getNumColumns(1, 2, 3)}
                                heightFromRows
                                seeMoreLink={`${
                                    Routes.MAP_RIDES
                                }?pos=${centerZoomToString(myLocation, 9)}`}
                                onSeeMore={() => {
                                    centerZoom(myLocation, 9);
                                }}
                            />
                        ) : (
                            <NoItems
                                to={Routes.MAP_RIDES}
                                action={t('View Map')}
                                loading={home.nearRides.loading}
                                text={t("We couldn't find any rides near you.")}
                            />
                        )}
                    </Section>
                </div>

                <Footer />
            </div>
        );
    }
}
//------------------------------------------------------------------------------
// Redux State -----------------------------------------------------------------
const mapStateToProps = (state) => {
    return {
        home: state.home,
        challenges: state.challenges,
        myLocation: state.map.myLocation
    };
};
//------------------------------------------------------------------------------
// Redux Actions ---------------------------------------------------------------
const mapDispatchToProps = (dispatch) => ({
    centerZoom: (center, zoom) => dispatch(mapActions.centerZoom(center, zoom)),
    selectChallengeById: (args) => dispatch(selectChallengeById(args)),
    fetchChallenges: () => dispatch(fetchChallenges()),
    onReceiveLocation: (position, onSuccess) =>
        dispatch(mapActions.didReceiveLocation(position, onSuccess())),
    centerMyLocation: () => dispatch(mapActions.centerMyLocation())
});

ChallengesView.contextType = AppContext;
//------------------------------------------------------------------------------
// Redux Connect ---------------------------------------------------------------
const container = connect(mapStateToProps, mapDispatchToProps)(ChallengesView);
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withRouter(container);
