/******************************************************************************\
* File: Close.jsx
*
* Author: Gigster
*
* Description: SVG icon of Close
*
* Notes: 
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as  css from '@/style/common/icons/Icon.scss';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Close extends React.Component {
    render() {
        const { className, style, onClick } = this.props;

        const cn = classNames(css.Icon, {
            [css['clickable']]: onClick,
            [className]: className
        });

        return (
            <svg
                className={cn}
                viewBox="0 0 12 12"
                onClick={onClick}
                style={style}
            >
                <g id="Preview" strokeWidth="0" fillRule="evenodd">
                    <g
                        id="Map-List-Rides-Expanded-FULL"
                        transform="translate(-1396.000000, -24.000000)"
                    >
                        <path
                            d="M1402,28.714311 L1397.28567,24 L1396,25.2858611 L1400.71426,30.0000428 L1396,34.7142916 L1397.28567,36 L1402,31.2857562 L1406.71433,36 L1408,34.7142916 L1403.28574,30.0000428 L1408,25.2858611 L1406.71433,24 L1402,28.714311 Z"
                            id="Combined-shape-9574"
                        />
                    </g>
                </g>
            </svg>
        );
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Close;
