/******************************************************************************\
* File: Copy.jsx
*
* Author: Gigster
*
* Description: 
*
* Notes: 
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/common/icons/Icon.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Copy extends React.Component {
    render() {
        const { className, onClick, id } = this.props;

        const cn = classNames(style.Icon, {
            [style['clickable']]: onClick,
            [className]: !!className
        });

        return (
            <svg
                className={cn}
                viewBox="0 0 24 24"
                onClick={onClick}
                id={id ? id : undefined}
                data-testid={id ? id : undefined}>
                <path
                    fill="#FFF"
                    d="M15 9V6h-2v3h-3v2h3v3h2v-3h3V9h-3zm1 12h-2v-1h2v-2h1v3h-1zM4 20h2v1H3v-3h1v2zM4 8v2H3V7h3v1H4zm-1 4h1v4H3v-4zm5 8h4v1H8v-1zM7 3h14v14H7V3z"
                />
            </svg>
        );
    }
}
export default Copy;
