// extracted by mini-css-extract-plugin
var _1 = "Footer__Footer___CL5KS";
var _2 = "Footer__Orange___URTA0";
var _3 = "Footer__Select___poKpv";
var _4 = "Footer__TextLogo___PJFrP";
var _5 = "Footer__copyright___ja9xc";
var _6 = "Footer__link___ezYZg";
var _7 = "Footer__links___akweE";
var _8 = "Footer__locale___yuHUw";
var _9 = "Footer__logo___iDK4E";
var _a = "Footer__social___niOEE";
var _b = "Footer__socialIcons___AHoEP";
var _c = "Footer__website___ZGKJp";
var _d = "Footer__wiggle___cdoyu";
export { _1 as "Footer", _2 as "Orange", _3 as "Select", _4 as "TextLogo", _5 as "copyright", _6 as "link", _7 as "links", _8 as "locale", _9 as "logo", _a as "social", _b as "socialIcons", _c as "website", _d as "wiggle" }
