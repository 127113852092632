/******************************************************************************\
 * File: RouteRestaurantMarker.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/common/icons/Icon.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class RouteHotelMarker extends React.Component {
    render() {
        const { className, onClick, highlight, isOnRoute } = this.props;

        const cn = classNames(style.Icon, {
            [style['clickable']]: onClick,
            [className]: className
        });

        return (
            <div
                style={{
                    ...style,
                    height: '40px',
                    width: '37px',
                    margin: '-40px 0 0 -19px'
                }}>
                <svg
                    style={{ height: '100%', width: '100%' }}
                    className={cn}
                    viewBox={!isOnRoute ? '0 0 31 43' : '0 0 30 43'}
                    onClick={onClick}>
                    <g fill="none" fillRule="evenodd">
                        {!isOnRoute && (
                            <circle
                                cx="14"
                                cy="15"
                                r="14"
                                fill="#000"
                                stroke="#C7C7C7"
                                strokeWidth="1"></circle>
                        )}
                        <path d="M0 0h30v30H0z" opacity=".6" />
                        {isOnRoute && (
                            <path
                                fill="#F60"
                                d="M25.612 4.41c-5.827-5.857-15.3-5.884-21.16-.058l-.06.059C-.852 9.676-1.502 19.575 2.999 25.576L15 43l12.002-17.4c4.501-6 3.851-15.924-1.395-21.19h.005z"
                            />
                        )}
                        <path
                            fill={!isOnRoute || highlight ? '#fff' : '#000'}
                            d="M6 20.8655V11.1344C6.00323 10.5073 6.48437 10 7.07706 10C7.67207 10 8 10.5107 8 11.1407V17H21V13.2768C21 12.7705 21.3315 12.3604 21.9251 12.3604C22.5152 12.3604 22.9944 12.7657 23 13.2678V21.0925C22.9944 21.5944 22.5152 22 21.9251 22C21.3315 22 21 21.5896 21 21.0835V19H8V20.8591C8 21.4871 7.67526 21.9966 7.08297 22H7.07115C6.48117 21.9966 6.00322 21.4905 6 20.8655ZM12 15.0664V12.5497C12 12.3433 12.2237 12.1758 12.4999 12.1758C12.776 12.1758 13 12.3433 13 12.5497V15.6452C13 15.8339 12.8134 15.9899 12.5709 16.0157L12.5707 16.0161H12.5666C12.5448 16.0183 12.5225 16.0194 12.4999 16.0194C12.4773 16.0194 12.455 16.0183 12.4332 16.0161H9.69884C9.47851 16.0161 9.3 15.8498 9.3 15.5295C9.3 15.2097 9.48146 15.0664 9.70179 15.0664H12ZM18.3227 12.6342C19.3151 12.6342 20.1196 13.2506 20.1196 14.0111V16.0935C20.1196 16.0962 20.1194 16.0705 20.1191 16.0447C20.1188 16.019 20.1185 15.9933 20.1185 15.996H14.0112V12.6342H18.3227ZM10.15 14.5746C10.8402 14.5746 11.4 14.015 11.4 13.3246C11.4 12.6343 10.8402 12.0746 10.15 12.0746C9.45964 12.0746 8.9 12.6343 8.9 13.3246C8.9 14.015 9.45964 14.5746 10.15 14.5746Z"
                            fillRule="nonzero"
                        />
                    </g>
                </svg>
            </div>
        );
    }
}
export default RouteHotelMarker;
