/******************************************************************************\
* File: Hamburger.jsx
*
* Author: Gigster
*
* Description: 
*
* Notes: 
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/common/icons/Icon.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Hamburger extends React.Component {
    render() {
        const { className, onClick, ...props } = this.props;

        const cn = classNames(style.Icon, {
            [style['clickable']]: onClick,
            [className]: className
        });

        return (
            <svg
                {...props}
                className={cn}
                viewBox="0 0 23 17"
                onClick={onClick}
            >
                <g
                    id="Directions-ReOrder-Hover"
                    fillRule="nonzero"
                    transform="translate(-41)"
                >
                    <path
                        id="a"
                        d="M42.236 9.714A1.225 1.225 0 0 1 41 8.5c0-.67.554-1.214 1.236-1.214h11.87c.682 0 1.236.543 1.236 1.214 0 .67-.554 1.214-1.237 1.214H42.236zm0-7.285A1.225 1.225 0 0 1 41 1.214C41 .544 41.554 0 42.236 0h20.255c.683 0 1.236.544 1.236 1.214 0 .67-.553 1.215-1.236 1.215H42.236zm0 14.571A1.225 1.225 0 0 1 41 15.786c0-.67.554-1.215 1.236-1.215h20.255c.683 0 1.236.544 1.236 1.215 0 .67-.553 1.214-1.236 1.214H42.236z"
                    />
                </g>
            </svg>
        );
    }
}
export default Hamburger;
