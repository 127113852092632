import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import withRouter from '@/helpers/hooks';

import { Helmet } from 'react-helmet';

import * as style from '@/style/homeView/HomeView.scss';
import Header from '@/components/common/header/Header';

import ArrowLink from '@/components/common/ArrowLink';
import CardGrid from '@/components/common/cards/CardGrid';
import Ride from '@/components/common/cards/Ride';
import SearchBar from '@/components/common/search/SearchBar';
import Footer from '@/components/homeView/Footer';
import Hero from '@/components/homeView/Hero';
import Section from '@/components/homeView/Section';
import NoItems from '@/components/mapListView/NoItems';
import { collections } from '@/helpers/collections';
import mobile from '@/helpers/is-mobile';

import { hasSessionToken } from '@/store/auth';
import { fetchMany as fetchChallenges } from '@/store/challenges';
import * as homeActions from '@/store/home';
import * as mapActions from '@/store/map';

import { ensureGigyaWebSDK } from '@/helpers/gigya';
import { getSiteName } from '@/helpers/constants';
import {
    cleanupGeolocation,
    responsiveToggle,
    setupGeolocation,
    __
} from '@/helpers/functions';
import { getCountry, isUS } from '@/helpers/i18n';
import { Routes } from '@/helpers/routes';
import { centerZoomToString } from '@/helpers/url';

import { createLogger } from '@/helpers/debug';
import { translate } from '@/helpers/i18n';
//App context
import AppContext from '@/contexts/AppContext';
const t = translate('homeView.HomeView');
const log = createLogger('HomeView', true);

class HomeView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isTouchDevice: mobile.isTouchDevice(),
            shouldRequestFetch: true
        };
        this.$content = React.createRef();
    }

    componentDidMount = () => {
        // TODO: Add challenges
        const { onReceiveLocation, fetchNearRides, fetchChallenges } =
            this.props;

        // callback cannot be bound to the this context
        const didReceiveLocation = (locationInfo) => {
            onReceiveLocation(locationInfo, () => {
                const { shouldRequestFetch } = this.state;

                if (shouldRequestFetch) {
                    fetchNearRides();
                    fetchChallenges();
                    this.setState({ shouldRequestFetch: false });
                }
                cleanupGeolocation(this.watchGeoId);
            });
        };
        // If browser has geolocation, setup callbacks
        ensureGigyaWebSDK(() => {
            this.watchGeoId = setupGeolocation(
                didReceiveLocation,
                window.hd.gigya
            );
        });
    };

    componentDidUpdate = (prevProps) => {
        const { fetchNearRides, hasSessionToken } = this.props;
        // if auth state has changed refresh near rides
        if (hasSessionToken !== prevProps.hasSessionToken) {
            fetchNearRides();
        }
    };

    componentWillUnmount = () => {
        // Clear the geolocation watch callback if it was created
        cleanupGeolocation(this.watchGeoId);
    };

    getNumColumns = (mobile, tablet, desktop, fallback = 1) => {
        const { screenSize } = this.context;
        const columnsNumber = responsiveToggle(
            screenSize,
            fallback,
            mobile,
            tablet,
            desktop
        );
        return columnsNumber;
    };

    generateLink(c, idx) {
        const collectionLast = collections.length - 1;
        const link = (title, link, linkTitle, center, zoom, isLast) => (
            <span
                className={
                    !isLast
                        ? style.LinkStyle
                        : classNames(style.LinkStyle, style.last)
                }
                onClick={() => {
                    this.props.centerZoom(center, zoom);
                    log(center, zoom);
                }}>
                {title}
                <span
                    className={
                        !isLast
                            ? style.LinkText
                            : classNames(style.LinkText, style.last)
                    }>
                    <ArrowLink to={link} plain text={linkTitle} />
                </span>
            </span>
        );

        return link(
            c.title,
            `${Routes.MAP_COLLECTIONS}?collection=${c.tag}&type=${c.type}&pos=${c.lat},${c.lng},${c.zoom}z`,
            'VIEW RIDES',
            { lat: c.lat, lng: c.lng },
            c.zoom,
            collectionLast === idx
        );
    }

    generateSeeMoreLink(myLocation) {
        return `${Routes.MAP_RIDES}?pos=${centerZoomToString(myLocation, 6)}`;
    }

    filterMapCollections() {
        return collections
            .filter((c) => c.active)
            .map((c, idx) => {
                const linkDisplay = this.generateLink(c, idx);
                return (
                    <span className={style.LinkSectionText} key={idx}>
                        {c.logo && <img src={c.logo} height="15" />}
                        {c.collection && (
                            <span className={style.LinkCollectionText}>
                                {' '}
                                {c.collection}{' '}
                            </span>
                        )}
                        {c.title && c.tag && <span>{linkDisplay}</span>}
                    </span>
                );
            });
    }

    getRideCollectionSection = () => {
        if (!isUS()) {
            return;
        }
        const rideCollectionTitle = `RIDE COLLECTIONS`;
        const hasItems = collections && collections.length > 0;
        return (
            <div className={style.LinkSection}>
                <div>
                    <h1>{rideCollectionTitle}</h1>
                </div>
                {hasItems && (
                    <div className={style.links}>
                        {this.filterMapCollections()}
                    </div>
                )}
            </div>
        );
    };

    render() {
        const { home, myLocation, centerZoom } = this.props;
        const NUM_ITEMS = 9;
        // fetches deep props from object
        const rides = __(home, 'nearRides', 'data') || [];
        const heroTitle = t('Find a Ride');

        return (
            <>
                {!this.props.isMobilePreview && (
                    <Header
                        showSearch={this.props.onMapView}
                        openSettingsModal={this.props.openSettingsModal}
                        openConvertRideModal={this.props.openConvertRideModal}
                        closeSettingsModal={this.props.closeSettingsModal}
                        updateUploadModal={this.props.updateUploadModal}
                        transparent={!this.props.onMapView}
                        background={!this.props.onMapView}
                    />
                )}

                <div className={style.HomeView}>
                    <Helmet>
                        <title>{getSiteName()}</title>
                    </Helmet>
                    <Hero
                        title={heroTitle}
                        main
                        footer={this.getRideCollectionSection()}>
                        <SearchBar
                            className={style.searchBar}
                            locale={getCountry()}
                            large
                            shortcutEnabled
                        />
                    </Hero>
                    <div
                        className={!isUS ? style.contentShort : style.content}
                        ref={this.$content}>
                        <Section
                            title={t('Rides For You')}
                            className={style.gridSection}>
                            {rides.length > 0 ? (
                                <CardGrid
                                    Item={Ride}
                                    items={rides.slice(0, NUM_ITEMS)}
                                    columns={this.getNumColumns(1, 2, 3)}
                                    heightFromRows
                                    seeMoreLink={this.generateSeeMoreLink(
                                        myLocation
                                    )}
                                    onSeeMore={() => {
                                        centerZoom(myLocation, 9);
                                    }}
                                    isHome={true}
                                    scrollElement={this.$content}
                                />
                            ) : (
                                <NoItems
                                    to={Routes.MAP_RIDES}
                                    action={t('View Map')}
                                    loading={home.nearRides.loading}
                                    text={t(
                                        "We couldn't find any rides near you."
                                    )}
                                />
                            )}
                        </Section>
                    </div>
                    <Footer />
                </div>
            </>
        );
    }
}
//------------------------------------------------------------------------------
// Redux State -----------------------------------------------------------------
const mapStateToProps = (state) => {
    return {
        home: state.home,
        challenges: state.challenges,
        badges: state.badges,
        myLocation: state.map.myLocation,
        hasSessionToken: hasSessionToken(state)
    };
};
//------------------------------------------------------------------------------
// Redux Actions ---------------------------------------------------------------
const mapDispatchToProps = (dispatch) => ({
    centerZoom: (center, zoom) => dispatch(mapActions.centerZoom(center, zoom)),
    fetchNearRides: () => dispatch(homeActions.fetchNearRides()),
    fetchChallenges: () => dispatch(fetchChallenges()),
    onReceiveLocation: (position, onSuccess) =>
        dispatch(mapActions.didReceiveLocation(position, onSuccess)),
    centerMyLocation: () => dispatch(mapActions.centerMyLocation())
});

HomeView.contextType = AppContext;
//------------------------------------------------------------------------------
// Redux Connect ---------------------------------------------------------------
const container = connect(mapStateToProps, mapDispatchToProps)(HomeView);
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withRouter(container);
