/******************************************************************************\
 * File: Title.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/common/Title.scss';
//------------------------------------------------------------------------------
// React Title Function --------------------------------------------------------
const Title = (props) => {
    const { text, children, className, noClip, ...rest } = props;

    const cn = classNames(style.Title, {
        [className]: !!className,
        [style['no-clip']]: !!noClip
    });

    return (
        <h2 className={cn} {...rest}>
            {children || text}
        </h2>
    );
};
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Title;
