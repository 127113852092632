// extracted by mini-css-extract-plugin
var _1 = "Tooltip__Close___SoArY";
var _2 = "Tooltip__Tooltip___ZsU7P";
var _3 = "Tooltip__accent___eg4Zs";
var _4 = "Tooltip__arrow___ujf6I";
var _5 = "Tooltip__bottom___pO5X7";
var _6 = "Tooltip__left___iJTeW";
var _7 = "Tooltip__open___joQFE";
var _8 = "Tooltip__right___gwnRf";
var _9 = "Tooltip__thin___BuSeG";
var _a = "Tooltip__top___Z0Chv";
var _b = "Tooltip__wiggle___QXwFp";
export { _1 as "Close", _2 as "Tooltip", _3 as "accent", _4 as "arrow", _5 as "bottom", _6 as "left", _7 as "open", _8 as "right", _9 as "thin", _a as "top", _b as "wiggle" }
