/******************************************************************************\
 * File: CollectionsView.jsx
 *
 * Author: Gigster
 *
 * Description: Collections view
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import { connect } from 'react-redux';
import withRouter from '@/helpers/hooks';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/collections/collections.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import CardGrid from '@/components/common/cards/CardGrid';
import Ride from '@/components/common/cards/Ride';
import Footer from '@/components/homeView/Footer';
import Section from '@/components/homeView/Section';
import NoItems from '@/components/mapListView/NoItems';
import Link from '@/components/common/header/Link';
import ArrowRight from '@/components/common/icons/ArrowRight';

//------------------------------------------------------------------------------
// Actions ---------------------------------------------------------------------
import * as mapActions from '@/store/map';
import { hasSessionToken } from '@/store/auth';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { __, responsiveToggle } from '@/helpers/functions';
import { Routes } from '@/helpers/routes';
import { collections } from '@/helpers/collections';
import { translate } from '@/helpers/i18n';

//App context
import AppContext from '@/contexts/AppContext';

const t = translate('homeView.HomeView');

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class CollectionsView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tagObject: {}
        };
        this.$content = React.createRef();
    }

    componentDidMount() {
        const { location, getFetchItemsByCollection } = this.props;

        const { pathname } = location;

        const tag = pathname.replace('/collections/', '');
        if (!!tag) {
            const tagObject = collections.find(
                (collection) => tag === collection.tag
            );
            if (!!tag && !!tagObject)
                getFetchItemsByCollection(
                    tagObject.type,
                    tagObject.key,
                    tag,
                    tagObject
                );
            this.setState({ tagObject });
        }
    }

    getNumColumns = (mobile, tablet, desktop, fallback = 1) => {
        const { screenSize } = this.context;

        return responsiveToggle(screenSize, fallback, mobile, tablet, desktop);
    };

    render() {
        const { centerZoom, rides } = this.props;
        const { tagObject } = this.state;
        const { logo, title, description, lat, lng, zoom } = tagObject || {};
        const { isMobile, screenSize } = this.context;
        const url = `${Routes.MAP_COLLECTIONS}?collection=${tagObject.tag}&type=${tagObject.type}`;
        const center = { lat, lng };
        const NUM_ITEMS = 90;

        const link = (url, center, zoom) => (
            <Link
                href={url}
                to={url}
                onClick={() => centerZoom(center, zoom)}
                // className={style.link}
                >
                <div className={style.MapLink}>
                    {t('View on map')} <ArrowRight />
                </div>
            </Link>
        );

        return (
            <div className={style.HomeView}>
                {!!tagObject && (
                    <div className={style.content} ref={this.$content}>
                        <Section className={style.headerSection}>
                            <div className={style.collectionsImage}>
                                <img src={logo} />
                            </div>
                            <div className={style.collectionDetails}>
                                <div className={style.hog}>
                                    H.O.G.
                                    <span className={style.trademark}>® </span>
                                    RIDE 365
                                </div>
                                <div className={style.collectionTitle}>
                                    <div>{title}</div>
                                    {!isMobile && link(url, center, zoom)}
                                </div>
                                <div className={style.description}>
                                    {description}
                                </div>
                                <div className={style.collectionTitle}>
                                    {isMobile && link(url, center, zoom)}
                                </div>
                            </div>
                        </Section>
                        <Section className={style.gridSection}>
                            {rides.length > 0 ? (
                                <CardGrid
                                    Item={Ride}
                                    items={rides.slice(0, NUM_ITEMS)}
                                    columns={this.getNumColumns(1, 2, 3)}
                                    heightFromRows
                                    scrollElement={this.$content}
                                />
                            ) : (
                                <div />
                            )}
                        </Section>
                    </div>
                )}
                <Footer />
            </div>
        );
    }
}
//------------------------------------------------------------------------------
// Redux State -----------------------------------------------------------------
const mapStateToProps = (state) => {
    return {
        home: state.home,
        myLocation: state.map.myLocation,
        rides: state.rides.data || [],
        hasSessionToken: hasSessionToken(state)
    };
};
//------------------------------------------------------------------------------
// Redux Actions ---------------------------------------------------------------
const mapDispatchToProps = (dispatch) => ({
    centerZoom: (center, zoom) => dispatch(mapActions.centerZoom(center, zoom)),
    getFetchItemsByCollection: (
        collectionType,
        key,
        collection,
        collectionObject
    ) =>
        dispatch(
            mapActions.getFetchItemsByCollection(
                collectionType,
                key,
                collection,
                collectionObject
            )
        )
});
CollectionsView.contextType = AppContext;
//------------------------------------------------------------------------------
// Redux Connect ---------------------------------------------------------------
const container = connect(mapStateToProps, mapDispatchToProps)(CollectionsView);
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withRouter(container);
