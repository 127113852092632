/******************************************************************************\
* File: DisplayRowAction.jsx
*
* Author: Gigster
*
* Description: 
*
* Notes: 
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import Link from '@/components/common/header/Link';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/common/cards/DisplayRowAction.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
export const DisplayRowAction = ({
    className,
    icon: IconComponent,
    text,
    active,
    onToggle,
    ...props
}) => (
    <Link
        className={classNames(style.Action, {
            [className]: !!className,
            [style['active']]: !!active
        })}
        {...props}
    >
        {!!IconComponent && (
            <IconComponent onToggle={onToggle} active={active} />
        )}
        <div>{text}</div>
    </Link>
);
