/******************************************************************************\
 * File: DescriptionSidebar.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Components ------------------------------------------------------------------
import Sidebar from '@/components/common/Sidebar';
import ArrowLink from '@/components/common/ArrowLink';
import SimpleHtmlRenderer from '@/components/common/SimpleHtmlRenderer';

import * as style from '@/style/mapListView/sidebar/DescriptionSidebar.scss';
import { translate } from '@/helpers/i18n';
const t = translate('sidebar.RideDetails');

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class DescriptionSidebar extends React.Component {
    backLink = (onClick, text) => (
        <ArrowLink
            className={style.back}
            onClick={onClick}
            left={true}
            text={text}
        />
    );

    render() {
        const { name, onRequestClose, isMobile, isOpen, description } =
            this.props;
        return (
            <Sidebar
                contentLabel={name}
                underNavigation={true}
                highZ={true}
                className={style.Sidebar}
                portalClassName={'ReactModalPortal force-open'}
                isOpen={isOpen}
                topOffset={true}>
                <div className={style.backContainer}>
                    {this.backLink(onRequestClose, t('Ride Description'))}
                </div>
                <div className={style.Description}>
                    <SimpleHtmlRenderer
                        className={style.description}
                        html={description}
                    />
                </div>
            </Sidebar>
        );
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default DescriptionSidebar;
