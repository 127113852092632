/******************************************************************************\
 * File: Input.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------
import CloseIcon from '@/components/common/icons/Close';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/common/form/Input.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Input extends React.Component {
    focus = () => this.$input && this.$input.focus();

    select = () => this.$input && this.$input.select();

    blur = () => this.$input && this.$input.blur();

    render() {
        const {
            className,
            inputProps,
            icon: IconComponent,
            onSearchClick,
            onClearClick,
            innerRef,
            plain,
            hideClose,
            transparent,
            centerIcon,
            ...rest
        } = this.props;

        const isEmpty = !(rest.value || {}).length;

        const cn = classNames(style.Input, {
            [className]: !!className,
            [style['empty']]: isEmpty,
            [style['transparent']]: transparent,
            [style['center-icon']]: centerIcon,
            [style['plain']]: !!plain
        });

        return (
            <div className={cn} onClick={this.focus}>
                {!hideClose && !!IconComponent && (
                    <IconComponent
                        className={style.icon}
                        onClick={onSearchClick}
                    />
                )}

                <input
                    ref={(el) => {
                        this.$input = el;
                        if (innerRef) innerRef(el);
                    }}
                    {...rest}
                />

                {hideClose && !!IconComponent ? (
                    <IconComponent
                        className={style.icon}
                        onClick={onSearchClick}
                    />
                ) : (
                    onClearClick && (
                        <CloseIcon
                            className={classNames(style.close, {
                                [style['show']]: !isEmpty
                            })}
                            onClick={onClearClick}
                        />
                    )
                )}
            </div>
        );
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Input;
