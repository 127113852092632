/******************************************************************************\
 * File: PinStack.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/common/icons/Icon.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class PinStack extends React.Component {
    render() {
        const { className, onClick } = this.props;

        const cn = classNames(style.Icon, {
            [style['clickable']]: onClick,
            [className]: !!className
        });

        return (
            <svg className={cn} viewBox="0 0 16 16" onClick={onClick}>
                <g fill="none" fillRule="evenodd">
                    <g fill="#FFF">
                        <path d="M10.625 1A4.38 4.38 0 0 0 6.25 5.375c0 2.814 3.246 8.542 3.616 9.186.15.262.427.428.73.438h.029a.875.875 0 0 0 .727-.388C11.502 14.39 15 9.116 15 5.375A4.38 4.38 0 0 0 10.625 1" />
                        <path d="M2.75 5.375A2.628 2.628 0 0 1 5.375 2.75a.875.875 0 1 0 0-1.75A4.38 4.38 0 0 0 1 5.375c0 2.814 3.246 8.543 3.616 9.186a.875.875 0 1 0 1.518-.872C5.193 12.051 2.75 7.365 2.75 5.375" />
                    </g>
                </g>
            </svg>
        );
    }
}
export default PinStack;
