/******************************************************************************\
 * File: OptionsToggle.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/mapListView/OptionsToggle.scss';
//------------------------------------------------------------------------------
// Components ------------------------------------------------------------------
import GearIcon from '@/components/common/icons/Gear';
import Tooltip from '@/components/common/Tooltip';
import Checkbox, { Label } from '@/components/common/form/Checkbox';
import Link from '@/components/common/header/Link';
import { clickAway } from '@/helpers/hoc';
import { analyticsForRideWithData } from '@/helpers/analytics';
import { translate } from '@/helpers/i18n';
const t = translate('mapListView.OptionsToggle');
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
const OptionsGroup = ({ title, subtitle, disabled, options }) => (
    <div className={style.OptionsGroup}>
        {title && <h3 className={style.title}>{title}</h3>}
        {subtitle && <p className={style.subtitle}>{subtitle}</p>}
        {options.map((option, i) => {
            return (
                <Label
                    label={option.label}
                    className={style.label}
                    key={`option-${i}`}
                    onChange={option.onChange}
                    checked={option.checked}>
                    <Checkbox
                        onChange={option.onChange}
                        checked={option.checked}
                        className={style.right}
                        disabled={disabled}
                        tabIndex="0"
                        onKeyDown={(e) => {
                            // simulate Link enter to click
                            e.key === 'Enter' &&
                                option.onChange(!option.checked);
                        }}
                        id={`show-map-${option.label}`
                            .toLowerCase()
                            .replace(/\s/g, '-')}
                        data-testid={`show-map-${option.label}`
                            .toLowerCase()
                            .replace(/\s/g, '-')}
                    />
                </Label>
            );
        })}
    </div>
);

class OptionsToggle extends React.Component {
    showChargingStationModal = () => {
        const { groups, toggleCheckboxModal, evBikeOwner } = this.props;

        const allUnchecked = (items) => items.every((item) => !item.checked);

        if (evBikeOwner) {
            const chargingStations = groups.pop();
            const { options } = chargingStations;

            const levels = options.slice(0, 3);
            const operators = options.slice(3, options.length - 1);
            const levelsUnchecked = allUnchecked(levels);
            const operatorsUnchecked = allUnchecked(operators);

            const showModal = allUnchecked([...levels, ...operators])
                ? false
                : levelsUnchecked || operatorsUnchecked;
            toggleCheckboxModal(showModal);
            return showModal;
        }
        toggleCheckboxModal(false);
        return false;
    };

    sendAnalytics = () => {
        const { groups } = this.props;
        const groupsData = groups.reduce((acc, o) => {
            acc[o.key] = o.checked;
            return acc;
        }, {});
        analyticsForRideWithData('Show on Map', groupsData);
    };

    toggleTooltip = () => {
        const { showTooltip, setShowTooltip } = this.props;
        if (showTooltip) this.showChargingStationModal();
        if (showTooltip) this.sendAnalytics();
        setShowTooltip(!showTooltip);
    };

    onClickAway = (event) => {
        const { showTooltip, setShowTooltip, groups } = this.props;
        if (showTooltip) {
            const [showOnMap, ...rest] = groups;
            const showOnMapData = showOnMap.options.reduce((acc, o) => {
                acc[o.label] = o.checked;
                return acc;
            }, {});
            this.sendAnalytics();
            this.showChargingStationModal();
            setShowTooltip(false);
        }
    };

    render() {
        const {
            className,
            tooltipClassName,
            title,
            displayTitle,
            options,
            groups,
            showTooltip,
            setShowTooltip
        } = this.props;

        const cn = classNames(style.OptionsToggle, {
            [className]: !!className
        });

        return (
            <div className={cn}>
                {showTooltip && (
                    <Tooltip
                        className={classNames(style.tooltip, tooltipClassName)}
                        style={{ maxHeight: '750px' }}>
                        <div className={style.options}>
                            {options && (
                                <OptionsGroup title={title} options={options} />
                            )}
                            {(groups || []).map((group, i) => (
                                <OptionsGroup key={i} {...group} />
                            ))}
                        </div>
                    </Tooltip>
                )}
                <Link
                    className={style.text}
                    onClick={this.toggleTooltip}
                    id="show-map-button"
                    data-testid="show-map-button">
                    <GearIcon className={style.gear} />
                    {t(displayTitle)}
                </Link>
            </div>
        );
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default clickAway(OptionsToggle);
