// extracted by mini-css-extract-plugin
var _1 = "Checkbox__Checkbox___pqoBB";
var _2 = "Checkbox__Label___znDVD";
var _3 = "Checkbox__checked___jGH0D";
var _4 = "Checkbox__clickable___CDRWP";
var _5 = "Checkbox__dark___TTxYA";
var _6 = "Checkbox__disabled___TODYW";
var _7 = "Checkbox__text___gIcwS";
var _8 = "Checkbox__wiggle___n9vM7";
export { _1 as "Checkbox", _2 as "Label", _3 as "checked", _4 as "clickable", _5 as "dark", _6 as "disabled", _7 as "text", _8 as "wiggle" }
