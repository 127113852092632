/******************************************************************************\
* File: Link.jsx
*
* Author: Gigster
*
* Description: Header Link
*
* Notes: 
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { Link, NavLink } from 'react-router-dom';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/common/header/Link.scss';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------

const NoopLink = ({ children, tabIndex, ...rest }) => {
    // fix react error / Unknown prop `activeClassName`
    delete rest.activeClassName;
    delete rest.exact;
    return (
        <a tabIndex={tabIndex || '0'} role="button" href="#" {...rest}>
            {children}
        </a>
    );
};

class _Link extends React.Component {
    render() {
        const {
            className,
            text,
            children,
            plain,
            clearRide,
            exact,
            activeClassName,
            ...props
        } = this.props;

        const cn = classNames({
            [style.Link]: !plain,
            [className]: className
        });
        const activeCn = classNames(cn, activeClassName);
        const url = props.to || props.href;
        return !!url && url.startsWith('http') ? (
            <a className={cn} href={url.replace(/ /g, '')} target="_blank">
                {text || children}
            </a>
        ) : props.to ? (
            activeClassName ? (
                <NavLink
                    className={({ isActive, isPending }) =>
                        isPending ? cn : isActive ? activeCn : cn
                    }
                    {...props}>
                    {' '}
                    {text || children}
                </NavLink>
            ) : (
                <Link className={cn} {...props}>
                    {' '}
                    {text || children}
                </Link>
            )
        ) : (
            <NoopLink className={cn} {...props}>
                {' '}
                {text || children}
            </NoopLink>
        );
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default _Link;
