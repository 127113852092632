/******************************************************************************\
 * File: SidepanelContainer.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import { connect } from 'react-redux';
import { matchPath } from 'react-router-dom';
import withRouter from '@/helpers/hooks';

import classNames from 'classnames';
import ArrowLink from '@/components/common/ArrowLink';
import ArrowAll from '@/components/common/icons/ArrowAll';
import SearchBar from '@/components/common/search/SearchBar';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/mapListView/sidepanel/index.scss';
import * as checkboxStyle from '@/style/common/Checkbox.scss';
import { translate } from '@/helpers/i18n';
const t = translate('myAccountView.SavedView');
const st = translate('search.SearchBar');
const mlvt = translate('mapListView.NoItemsWithActions');
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------
import CardGrid from '@/components/common/cards/CardGrid';
import Ride from '@/components/common/cards/Ride';
import Dealer from '@/components/common/cards/Dealer';
import Event from '@/components/common/cards/Event';
import Select from '@/components/common/cards/Select';

import DealerIcon from '@/components/common/icons/Dealer';
import EventIcon from '@/components/common/icons/Event';
import RideIcon from '@/components/common/icons/Ride';

//------------------------------------------------------------------------------
// Actions ---------------------------------------------------------------------
import { Routes } from '@/helpers/routes';
import { zoom, update as updateMap, refreshItems } from '@/store/map';
import { currentSection } from '@/store/sections';

//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import {
    sorts as ridesSortMap,
    ALPHANUMERIC,
    ALPHANUMERIC_REV,
    LAST_UPDATE,
    LAST_UPDATE_REV
} from '@/helpers/rides';
import { getRideCheckboxData } from '@/helpers/checkboxes';

//App context
import AppContext from '@/contexts/AppContext';

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------

const iconForSection = (name) => {
    switch (name.toLowerCase()) {
        case 'rides':
            return <RideIcon />;
        case 'dealers':
            return <DealerIcon />;
        default:
        case 'events':
            return <EventIcon />;
    }
};

const filterLink = (
    setShowSidepanelFilter,
    filteredTypes,
    pathname,
    pushUrl
) => {
    return (
        <div
            className={style.filter}
            onClick={() => {
                const isRide = !!matchPath(Routes.MAP_RIDE, pathname);
                if (isRide) pushUrl(Routes.MAP_RIDES);
                setShowSidepanelFilter();
            }}
            id="filter"
            data-testid="filter">
            <img src="/img/button-filter.svg" className={style.filterButton} />
            <span>
                {t('Filter')} ({filteredTypes.length})
            </span>
        </div>
    );
};

const displayFilteredTypes = (filteredTypes, rideTags) => {
    const rideCheckboxData = getRideCheckboxData(rideTags);
    const filterAndMapTypes = (checkboxData, group) => {
        const objs = checkboxData.filter(
            (v) =>
                v.group === group &&
                !v.hideDisplay &&
                filteredTypes.includes(v.value)
        );
        return objs.map((t) => t.label).join(', ');
    };
    const myRideTypes = filterAndMapTypes(rideCheckboxData, 'CUSTOM');
    const recommenedTypes = filterAndMapTypes(rideCheckboxData, 'CURATED');
    const poiTypes = filterAndMapTypes(rideCheckboxData, 'POI');

    return (
        <div className={style.filteredTypes}>
            {myRideTypes.length > 0 && (
                <span style={{ paddingRight: '4px' }}>
                    <span className={style.typeCollection}>
                        {t('My Rides')}:
                    </span>{' '}
                    {myRideTypes}
                </span>
            )}
            {recommenedTypes.length > 0 && (
                <span style={{ paddingRight: '4px' }}>
                    <span className={style.typeCollection}>
                        {t('Recommended')}:
                    </span>{' '}
                    {recommenedTypes}
                </span>
            )}
            {poiTypes.length > 0 && (
                <span>
                    <span className={style.typeCollection}>
                        {t('Points of interest')}:
                    </span>{' '}
                    {poiTypes}
                </span>
            )}
        </div>
    );
};

const urlForSection = {
    rides: Routes.MAP_RIDES,
    dealers: Routes.MAP_DEALERS,
    events: Routes.MAP_EVENTS
};

const sections = [
    {
        key: 'rides',
        name: 'Rides',
        stateKey: 'rides',
        columns: 3
    },
    {
        key: 'dealers',
        name: 'Dealers',
        stateKey: 'dealers',
        columns: 2
    },
    {
        key: 'events',
        name: 'Events',
        stateKey: 'events',
        columns: 2
    }
];

class SidepanelContainer extends React.Component {
    constructor(props) {
        super(props);

        const sortMap = {
            sorts: this.getSortList(),
            sortMap: ridesSortMap,
            sortKey: LAST_UPDATE
        };

        const sort = this.getSortList()[0];

        this.state = {
            sort,
            sortMap,
            hideIcons: false,
            initialLoad: true
        };
    }

    shouldComponentUpdate = (nextProps, nextState) => {
        const { props, state } = this;

        const urlChanged = (items) =>
            items.some(
                (item) => props.location[item] !== nextProps.location[item]
            );
        const itemsChanged = (items) =>
            items.some((item) => nextProps[item] !== props[item]);
        const sortChanged = (items) =>
            items.some((item) => state[item] !== nextState[item]);

        const items = [
            'rides',
            'events',
            'dealers',
            'ridesFiltered',
            'filteredRides',
            'filteredTypes'
        ];
        if (
            urlChanged(['pathname', 'search']) ||
            sortChanged(['sort', 'sortMap']) ||
            itemsChanged(items)
        )
            return true;

        return false;
    };

    getSortList = () => {
        return [
            { label: t('A-Z'), key: ALPHANUMERIC },
            { label: t('Z-A'), key: ALPHANUMERIC_REV },
            { label: t('Last Updated (Newest First)'), key: LAST_UPDATE },
            { label: t('Last Updated (Oldest First)'), key: LAST_UPDATE_REV }
        ];
    };

    getSort = (sorts, sortKey) => {
        return sorts.find((sort) => sort.key === sortKey);
    };

    getUniqueObjectsByShortId = (rides) => {
        return Object.values(
            rides.reduce((acc, ride) => {
                acc[ride.shortId] = ride;
                return acc;
            }, {})
        );
    };

    getSortedItems = () => {
        const { ridesFiltered } = this.props;
        const { sortMap, sort } = this.state;
        return sort ? sortMap.sortMap[sort.key](ridesFiltered) : ridesFiltered;
    };

    onSortChange = (option) => {
        const { onSortChange } = this.props;
        onSortChange && onSortChange(option);
        this.setState({ sort: option });
    };

    onChangeSection = (newSection) => () => {
        const { onChangeSection, hideSelectedData } = this.props;
        hideSelectedData();
        onChangeSection && onChangeSection(newSection);
    };

    backLink = ({ onClick = null, to = null, text }) => (
        <a onClick={() => this.props.navigate(to)}
        //  className={style.arrow}
         >
            <span className={style.backArrow}>
                <ArrowAll
                    // className={style.leftArrow}
                    style={{ height: '22px', width: '16px' }}
                />
                {!!text && <span 
                // className={style.arrowLink}
                >{text}</span>}
            </span>
        </a>
    );

    render() {
        const {
            rides,
            ridesFiltered,
            events,
            dealers,
            myLocation,
            location: { pathname },
            setShowSidepanelFilter,
            setShowSidePanel,
            showSidePanel,
            filteredRides,
            filteredTypes,
            rideTags,
            ridesFilters,
            navigate
        } = this.props;

        const { hideIcons } = this.state;
        const pushUrl = (url) => navigate(url);
        const containerSubStyle = { width: showSidePanel ? '368px' : '2px' };
        const isRides =
            matchPath(Routes.MAP_RIDES, pathname) ||
            matchPath(Routes.MAP_RIDE, pathname);
        const isDealers = matchPath(Routes.MAP_DEALERS, pathname);
        const isEvents = matchPath(Routes.MAP_EVENTS, pathname);

        const activeSection =
            sections.filter((section) => pathname.includes(section.key))[0] ||
            sections[0];

        const sortedRides = this.getSortedItems();
        const cardGridObj = {
            rides: {
                key: 'Rides',
                Item: Ride,
                items: sortedRides || [],
                url: Routes.MAP_RIDES
            },
            dealers: {
                key: 'Dealers',
                Item: Dealer,
                items: dealers,
                url: Routes.MAP_DEALERS
            },
            events: {
                key: 'Events',
                Item: Event,
                items: events,
                url: Routes.MAP_EVENTS
            }
        };

        const cardGrid = cardGridObj[activeSection.key];
        const containerStyle = {
            width: showSidePanel ? '390px' : '24px',
            height: 'calc(100vh - 66px)'
        };

        const expandPanelIcon = !showSidePanel
            ? '/img/icon-panel-expand.svg'
            : '/img/icon-panel-collapse.svg';
        const { sortMap, sort } = this.state;
        const labelKey = activeSection.key;

        const cn = classNames(style[labelKey]);

        const isMap = pathname === '/map';
        const showRides =
            filteredTypes.filter((t) => t !== 'events' && t !== 'hd-dealer')
                .length > 0;
        const showEvents = filteredTypes.includes('events');
        const showDealers = filteredTypes.includes('hd-dealer');

        const heightForRides =
            isMap && cardGridObj.rides.items.length > 2
                ? 3
                : cardGridObj.rides.items.length;
        const heightForDealers =
            isMap && cardGridObj.dealers.items.length > 2
                ? 3
                : cardGridObj.dealers.items.length;
        const heightForEvents =
            isMap && cardGridObj.events.items.length > 2
                ? 3
                : cardGridObj.events.items.length;
        const sectionCount = ({ items, items: { length }, key }, showAll) =>
            length < 1 ? (
                <div className={style.noCount}>
                    {mlvt('message', { items: key })}
                </div>
            ) : !showAll ? (
                <div className={style.sectionCountTitle}>
                    {t(key)} ({length})
                </div>
            ) : (
                <div
                    className={classNames(
                        style.sectionCountTitle,
                        style.allItems
                    )}>
                    <span>
                        {length} {t(key)}
                    </span>
                </div>
            );

        const seeAll = ({ url, items: { length }, key }, type) => {
            return (
                <div className={style.seeAll}>
                    <ArrowLink
                        to={url}
                        text={t('viewAll', {
                            count: length,
                            items: key
                        })}
                        id={type ? `${type + '-see-all'}` : undefined}
                        data-testid={type ? `${type + '-see-all'}` : undefined}
                    />
                </div>
            );
        };

        if (isRides || isDealers || isEvents) {
            const arr = document.body.getElementsByClassName(
                style.containerStyle
            );
            const [el, ...rest] = arr;
            if (!!el && el.scrollTop) {
                el.scrollTop = el.scrollTop || 0;
            }
        }

        return (
            <div style={containerStyle}>
                <div
                    className={classNames(style.sidePanelToggle, {
                        [style.expanded]: showSidePanel
                    })}
                    onClick={() => setShowSidePanel(!showSidePanel)}
                    id="sidepanel-close"
                    date-testid="sidepanel-close">
                    <img src={expandPanelIcon} />
                </div>
                <div className={style.containerStyle} style={containerSubStyle}>
                    {isMap && (
                        <div>
                            <div className={style.searchBarWrapper}>
                                <SearchBar
                                    className={style.searchBar}
                                    medium={true}
                                    shortcutEnabled
                                    hasId={true}
                                />
                            </div>
                            <div className={style.panelHeader}>
                                <span>{t('Show on Map')}</span>
                                <span className={style.filterContainer}>
                                    {filteredTypes &&
                                        filterLink(
                                            setShowSidepanelFilter,
                                            filteredTypes,
                                            pathname,
                                            pushUrl
                                        )}
                                </span>
                            </div>
                        </div>
                    )}
                    {!isMap && showSidePanel && (
                        <div
                            className={classNames(checkboxStyle.filterHeader)}
                            style={{
                                color: 'white',
                                height: '56px',
                                paddingBottom: '40px',
                                margin: 'unset',
                                textTransform: 'uppercase'
                            }}>
                            <div>
                                <span aria-label="Back">
                                    {this.backLink({ to: Routes.MAP })}
                                    <span className={style.backText}>
                                        {activeSection.key}
                                    </span>
                                </span>
                            </div>
                        </div>
                    )}
                    <div>
                        <div>
                            {isMap && (
                                <div>
                                    {filteredTypes &&
                                        filteredTypes.length < 20 &&
                                        rideTags && (
                                            <span
                                                className={
                                                    style.displayFilteredTypes
                                                }>
                                                {displayFilteredTypes(
                                                    filteredTypes,
                                                    rideTags
                                                )}
                                            </span>
                                        )}
                                </div>
                            )}
                            <div>
                                {(isMap || isRides) && (
                                    <div>
                                        {isMap && (
                                            <div
                                                className={
                                                    style.sectionDivider
                                                }></div>
                                        )}
                                        <div
                                            className={style.sectionCount}
                                            style={{ marginTop: '24px' }}>
                                            {sectionCount(
                                                cardGridObj.rides,
                                                isRides
                                            )}
                                            <Select
                                                // className={style.sort}
                                                selected={sort}
                                                options={sortMap.sorts}
                                                onSelect={this.onSortChange}
                                                hasId={true}
                                            />
                                        </div>
                                    </div>
                                )}
                                {showRides && (isMap || isRides) && (
                                    <div>
                                        <CardGrid
                                            Item={cardGridObj.rides.Item}
                                            items={
                                                isMap
                                                    ? cardGridObj.rides.items.slice(
                                                          0,
                                                          3
                                                      )
                                                    : cardGridObj.rides.items
                                            }
                                            columns={1}
                                            isSidePanel={true}
                                            isRides={true}
                                            heightFromRows={heightForRides}
                                            scrollElement={this.$content}
                                            idLabel="rides-card"
                                        />
                                        {isMap &&
                                            cardGridObj.rides.items.length >
                                                2 &&
                                            seeAll(cardGridObj.rides, 'rides')}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div>
                            {(isMap || isDealers) && (
                                <div>
                                    {isMap && (
                                        <div
                                            className={
                                                style.sectionDivider
                                            }></div>
                                    )}
                                    <div className={style.sectionCount}>
                                        {sectionCount(
                                            cardGridObj.dealers,
                                            isDealers
                                        )}
                                    </div>
                                </div>
                            )}
                            {showDealers && (isMap || isDealers) && (
                                <div>
                                    <CardGrid
                                        Item={cardGridObj.dealers.Item}
                                        items={
                                            isMap
                                                ? cardGridObj.dealers.items.slice(
                                                      0,
                                                      3
                                                  )
                                                : cardGridObj.dealers.items
                                        }
                                        columns={1}
                                        isSidePanel={true}
                                        isRides={true}
                                        heightFromRows={heightForDealers}
                                        scrollElement={this.$content}
                                        idLabel="dealers-card"
                                    />
                                    {isMap &&
                                        cardGridObj.dealers.items.length > 2 &&
                                        seeAll(cardGridObj.dealers, 'dealers')}
                                </div>
                            )}
                        </div>
                        <div>
                            {(isMap || isEvents) && (
                                <div>
                                    {isMap && (
                                        <div
                                            className={
                                                style.sectionDivider
                                            }></div>
                                    )}
                                    <div className={style.sectionCount}>
                                        {sectionCount(
                                            cardGridObj.events,
                                            isEvents
                                        )}
                                    </div>
                                </div>
                            )}
                            {showEvents && (isMap || isEvents) && (
                                <div>
                                    <CardGrid
                                        Item={cardGridObj.events.Item}
                                        items={
                                            isMap
                                                ? cardGridObj.events.items.slice(
                                                      0,
                                                      3
                                                  )
                                                : cardGridObj.events.items
                                        }
                                        columns={1}
                                        isSidePanel={true}
                                        isRides={true}
                                        heightFromRows={heightForEvents}
                                        scrollElement={this.$content}
                                        idLabel="events-card"
                                    />
                                    {isMap &&
                                        cardGridObj.events.items.length > 2 &&
                                        seeAll(cardGridObj.events, 'events')}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

//------------------------------------------------------------------------------
// Redux State -----------------------------------------------------------------
const mapStateToProps = (state, ownProps) => {
    return {
        dealers: state.dealers.data,
        events: state.events.data,
        rides: state.rides.data,
        ridesFiltered: state.rides.ridesFiltered,
        myLocation: state.map.myLocation,
        section: currentSection(state),
        rideTags: state.map.rideTags
    };
};
//------------------------------------------------------------------------------
// Redux Actions ---------------------------------------------------------------
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onZoom: (amount = 1) => dispatch(zoom(amount)),
        onChangeSection: (section) => dispatch(refreshItems(section)),
        onUpdateDrawerStatew: (value) => dispatch(updateDrawerState(value)),
        setSelectedData: (data) => dispatch(updateMap('selectedData', data)),
        hideSelectedData: () => dispatch(updateMap('selectedData', null)),
        setSortKey: (key, value) => dispatch(setSortKey(key, value))
    };
};

SidepanelContainer.contextType = AppContext;
//------------------------------------------------------------------------------
// Redux Connect ---------------------------------------------------------------
const container = connect(
    mapStateToProps,
    mapDispatchToProps
)(SidepanelContainer);
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withRouter(container);
