/******************************************************************************\
 * File: SidebarFilter.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';

//------------------------------------------------------------------------------
// Components ------------------------------------------------------------------
import Sidebar from '@/components/common/Sidebar';
import * as style from '@/style/common/Checkbox.scss';
import SidebarHeader from '@/components/SidebarHeader';
import SectionTitle from '@/components/homeView/SectionTitle';

// import FilterDetails from './FilterDetails';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class SidebarFilter extends React.Component {
    checkBoxGroupView = (checkboxGroups, group, checkboxGroupProp) => {
        return (
            <ul style={{ display: 'flex' }}>
                <li
                    className={style.checkbox}
                    data-checked={group.checked}
                    data-indeterminate={group.indeterminate}
                    onClick={group.onClick(group, group, checkboxGroupProp)}>
                    <input
                        value={group.value}
                        checked={group.checked}
                        type="checkbox"
                        onChange={group.onClick(
                            group,
                            group,
                            checkboxGroupProp
                        )}
                        // className={style.hiddenBox}
                    />
                    <label value={group.value}>
                        {group.label}

                        <span className={style.count}>
                            {' '}
                            ({(group.countByType || {}).total || 0})
                        </span>
                    </label>
                    <span
                        className={style.caret}
                        data-expanded={group.expanded}
                        onClick={group.onExpand(
                            checkboxGroups,
                            group,
                            checkboxGroupProp
                        )}
                    />
                </li>
            </ul>
        );
    };

    checkboxItemView = (group, option, action, i) => {
        return (
            <li
                className={style.checkbox}
                key={`option-${i}`}
                data-checked={option.checked}
                onClick={action}>
                <input
                    value={option.value}
                    checked={option.checked}
                    type="checkbox"
                    onChange={action}
                    // className={style.hiddenBox}
                />
                <label value={option.value}>
                    {option.label}
                    <span className={style.count}>
                        {' '}
                        (
                        {(group.countByType &&
                            group.countByType[option.value]) ||
                            0}
                        )
                    </span>
                </label>
            </li>
        );
    };

    checkboxItemsView = (group, g) => {
        return (
            <ul className={style.list}>
                {group.children.map((option, i) => {
                    const action = !!option.customOnClick
                        ? group.customOnClick(group, option, g)
                        : group.onClick(group, option, g);
                    if (!option.hideDisplay) {
                        return this.checkboxItemView(group, option, action, i);
                    }
                })}
            </ul>
        );
    };

    checkboxItemsWithHeadersView = (group, g) => {
        let groupLabels = group.groupLabels;
        const { opts } = group;
        return (
            <div className={style.collectionList}>
                {group.children.map((option, i) => {
                    const action = !!option.customOnClick
                        ? group.customOnClick(group, option, g)
                        : group.onClick(group, option, g);
                    const header = groupLabels.find((groupLabel) =>
                        groupLabel.collection.includes(option.value)
                    );
                    groupLabels = groupLabels.filter((e) => e !== header);
                    if (!option.hideDisplay) {
                        return (
                            <ul key={`group-${i}`}>
                                {header && !!i && (
                                    <div
                                        className={
                                            style.checkBoxHeaderBordered
                                        }>
                                        {header.label}
                                    </div>
                                )}
                                {header && !i && (
                                    <div className={style.checkBoxHeader}>
                                        {header.label}
                                    </div>
                                )}
                                {header &&
                                    opts.filters &&
                                    opts.filters[header.label] &&
                                    opts.filters[header.label]()}
                                {this.checkboxItemView(
                                    group,
                                    option,
                                    action,
                                    i
                                )}
                            </ul>
                        );
                    }
                })}
            </div>
        );
    };

    render() {
        const {
            onClickNavigation,
            selectedNavigation,
            navigationItems,
            checkboxGroups,
            onRequestClose,
            isOpen,
            label,
            style,
            isMobile
        } = this.props;
        const checkboxGroupProp = selectedNavigation.toLowerCase();
        const checkboxGroup = checkboxGroups[checkboxGroupProp];
        const checkboxAvailable =
            checkboxGroup &&
            checkboxGroup.children &&
            checkboxGroup.children.length > 0;
        return (
            <Sidebar
                contentLabel={name || 'Harley-Davidson'}
                {...this.props}
                underNavigation={true}
                topOffset={true}
                style={style}
                portalClassName={
                    !isMobile
                        ? 'ReactModalPortal force-open'
                        : 'ReactModalPortal'
                }
                isOpen={!isMobile ? true : isOpen}>
                {!isMobile ? (
                    <SidebarHeader
                        onClick={onClickNavigation}
                        selectedNavigation={selectedNavigation}
                        navigationItems={navigationItems}
                    />
                ) : (
                    <SidebarHeader navigationItems={[selectedNavigation]} />
                )}
                {checkboxAvailable &&
                    checkboxGroup.groupLabels &&
                    this.checkboxItemsWithHeadersView(
                        checkboxGroup,
                        checkboxGroupProp
                    )}
                {checkboxAvailable && !checkboxGroup.groupLabels && (
                    <div key={`checkbox-group-${checkboxGroupProp}`}>
                        {this.checkBoxGroupView(
                            checkboxGroups,
                            checkboxGroup,
                            checkboxGroupProp
                        )}
                        {checkboxAvailable &&
                            checkboxGroup.expanded &&
                            this.checkboxItemsView(
                                checkboxGroup,
                                checkboxGroupProp
                            )}
                    </div>
                )}
            </Sidebar>
        );
    }
}

//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default SidebarFilter;
