/******************************************************************************\
 * File: CardGrid.jsx
 *
 * Author: Gigster
 *
 * Description: CardGrid of rides
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import {
    AutoSizer,
    Grid,
    CellMeasurer,
    CellMeasurerCache,
    WindowScroller
} from 'react-virtualized';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/common/cards/CardGrid.scss';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------
import Ride from '@/components/common/cards/Ride';
import Select from '@/components/common/cards/Select';
import ArrowLink from '@/components/common/ArrowLink';
import { translate } from '@/helpers/i18n';
const t = translate('cards.CardGrid');
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class CardGrid extends React.Component {
    static defaultProps = {
        label: '',
        Item: Ride,
        large: true,
        itemProps: {},
        defaultSort: 0
    };

    constructor(props) {
        super(props);

        const sort = props.sorts
            ? this.getSort(props.sorts, props.sortKey) ||
              props.sorts[props.defaultSort]
            : undefined;

        this.state = {
            height: '',
            rendered: false,
            sort
        };
    }

    // Tracks dynamic height of cells
    cache = new CellMeasurerCache({
        defaultHeight: 200,
        minHeight: 100,
        fixedWidth: true
    });

    getGridHeight = () => {
        const { heightFromRows, items } = this.props;

        const rowHeight = this.cache.rowHeight({ index: 0 });

        return heightFromRows && items.length > 0
            ? `${
                  rowHeight *
                  (typeof heightFromRows === 'number'
                      ? heightFromRows
                      : this.getRowCount())
              }px`
            : '';
    };

    getRowCount = () => {
        const { items, columns } = this.props;

        return Math.ceil(items.length / columns);
    };

    shouldSetHeight = () => {
        return this.props.heightFromRows !== undefined;
    };

    getColumnWidth = (gridWidth) => {
        const { columns, fixedItemWidth, heightFromRows } = this.props;

        const rowCount = this.getRowCount();

        const fixedHeight = heightFromRows
            ? typeof heightFromRows === 'number'
                ? heightFromRows === rowCount
                : true
            : false;

        return fixedItemWidth !== undefined
            ? fixedItemWidth
            : (gridWidth - (fixedHeight ? 0 : 17)) / columns;
    };

    componentDidUpdate = (prevProps) => {
        // Enable dynamic heights of new items
        if (prevProps.Item !== this.props.Item) {
            this.cache.clearAll();
            this.setState({ rendered: false });
        }

        if (prevProps.sortKey !== this.props.sortKey) {
            this.setState({
                sort: this.getSort(this.props.sorts, this.props.sortKey)
            });
        }

        const height = this.getGridHeight();
        if (this.shouldSetHeight() && height !== this.state.height)
            this.setState({ height });
    };

    componentDidMount = () => {
        const height = this.getGridHeight();

        if (this.shouldSetHeight()) this.setState({ height });
    };

    getSort = (sorts, sortKey) => {
        return sorts.find((sort) => sort.key === sortKey);
    };

    getSortedItems = () => {
        const { items, sortMap } = this.props;
        const { sort } = this.state;

        return sort ? sortMap[sort.key](items) : items;
    };

    onSortChange = (option) => {
        const { onSortChange } = this.props;
        onSortChange && onSortChange(option);
        this.setState({ sort: option });
    };

    render() {
        const {
            className,
            Item,
            itemProps,
            label,
            selected,
            showCount,
            isRides,
            large,
            count,
            sorts,
            seeMoreLink,
            columns,
            onSeeMore,
            heightFromRows,
            windowScroll,
            scrollElement,
            isSidePanel,
            isHome,
            idLabel
        } = this.props;

        const { height, sort } = this.state;

        const cn = classNames(style.CardGrid, {
            [className]: className,
            [style['heightFromRows']]: heightFromRows
        });

        const items = this.getSortedItems();

        const Outer = windowScroll
            ? WindowScroller
            : ({ children }) => children({});

        return (
            <Outer
                className={cn}
                scrollElement={scrollElement}
                key={scrollElement}>
                {({
                    height: windowHeight,
                    isScrolling: windowIsScrolling,
                    onChildScroll: windowOnChildScroll,
                    scrollTop: windowScrollTop
                }) => [
                    showCount && (
                        <div
                            className={style.right}
                            style={{ marginTop: -56 }}
                            key={0}>
                            <div className={style.ItemCount}>
                                {typeof count !== 'undefined'
                                    ? count
                                    : items.length}{' '}
                                {label}
                            </div>
                            {sorts && (
                                <Select
                                    className={style.sort}
                                    selected={sort}
                                    options={sorts}
                                    onSelect={this.onSortChange}
                                />
                            )}
                        </div>
                    ),
                    <div className={style.items} style={{ height }} key={1}>
                        <AutoSizer>
                            {({ width: autoWidth, height: autoHeight }) => (
                                <Grid
                                    cellRenderer={({
                                        columnIndex,
                                        isScrolling,
                                        isVisible,
                                        key,
                                        parent,
                                        rowIndex,
                                        style: cellStyle
                                    }) => {
                                        const item =
                                            items[
                                                rowIndex * columns + columnIndex
                                            ];

                                        if (!item)
                                            return (
                                                <div
                                                    style={cellStyle}
                                                    key={key}
                                                />
                                            );

                                        return (
                                            <CellMeasurer
                                                cache={this.cache}
                                                columnIndex={columnIndex}
                                                key={key}
                                                parent={parent}
                                                rowIndex={rowIndex}>
                                                <div
                                                    className={classNames(
                                                        style.itemWrapper,
                                                        isRides && style.isRide
                                                    )}
                                                    style={cellStyle}>
                                                    <Item
                                                        item={item}
                                                        large={large}
                                                        isSidePanel={
                                                            isSidePanel
                                                        }
                                                        isHome={isHome}
                                                        selected={
                                                            item.id === selected
                                                        }
                                                        idLabel={idLabel}
                                                        rowIndex={rowIndex}
                                                        {...itemProps}
                                                    />
                                                </div>
                                            </CellMeasurer>
                                        );
                                    }}
                                    columnWidth={this.getColumnWidth(autoWidth)}
                                    deferredMeasurementCache={this.cache}
                                    columnCount={columns}
                                    rowHeight={this.cache.rowHeight}
                                    rowCount={this.getRowCount()}
                                    autoHeight={windowScroll}
                                    height={
                                        windowHeight ? windowHeight : autoHeight
                                    }
                                    width={autoWidth}
                                    onSectionRendered={() => {
                                        !this.state.rendered &&
                                            this.setState({ rendered: true });
                                    }}
                                    isScrolling={windowIsScrolling}
                                    onScroll={windowOnChildScroll}
                                    scrollTop={windowScrollTop}
                                />
                            )}
                        </AutoSizer>
                    </div>,
                    seeMoreLink && (
                        <div className={style.right} key={2}>
                            <ArrowLink
                                to={seeMoreLink}
                                text={t('See More')}
                                onClick={onSeeMore}
                            />
                        </div>
                    )
                ]}
            </Outer>
        );
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default CardGrid;
