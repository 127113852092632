/******************************************************************************\
* File: Locate.jsx
*
* Author: Gigster
*
* Description: SVG icon of Locate
*
* Notes:
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/common/icons/Icon.scss';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Locate extends React.Component {
    render() {
        const { className, onClick, id, ...rest } = this.props;
        const cn = classNames(style.Icon, {
            [style['clickable']]: onClick,
            [className]: className
        });

        return (
            <svg
                className={cn}
                viewBox="0 0 14 14"
                onClick={onClick}
                id={id ? id : undefined}
                data-testid={id ? id : false}
                {...rest}>
                <g
                    id="Preview"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd">
                    <g
                        id="Map-List-view-Open-No-Selected-Rides"
                        transform="translate(-1383.000000, -394.000000)"
                        fillRule="nonzero"
                        fill="#CCCCCC">
                        <g
                            id="Map-Controls"
                            transform="translate(1374.000000, 386.000000)">
                            <g id="Location-Control">
                                <g
                                    id="Locate-Icon"
                                    transform="translate(9.000000, 7.000000)">
                                    <path
                                        d="M7,14 C10.3137085,14 13,11.3137085 13,8 C13,4.6862915 10.3137085,2 7,2 C3.6862915,2 1,4.6862915 1,8 C1,11.3137085 3.6862915,14 7,14 Z M7,15 C3.13400675,15 0,11.8659932 0,8 C0,4.13400675 3.13400675,1 7,1 C10.8659932,1 14,4.13400675 14,8 C14,11.8659932 10.8659932,15 7,15 Z"
                                        id="Oval-3"
                                    />
                                    <path
                                        d="M7.5,4.10592953 C7.5,4.3820719 7.27614237,4.60592953 7,4.60592953 C6.72385763,4.60592953 6.5,4.3820719 6.5,4.10592953 L6.5,1.48092953 C6.5,1.20478715 6.72385763,0.980929525 7,0.980929525 C7.27614237,0.980929525 7.5,1.20478715 7.5,1.48092953 L7.5,4.10592953 Z M7.5,14.125 C7.5,14.4011424 7.27614237,14.625 7,14.625 C6.72385763,14.625 6.5,14.4011424 6.5,14.125 L6.5,11.5 C6.5,11.2238576 6.72385763,11 7,11 C7.27614237,11 7.5,11.2238576 7.5,11.5 L7.5,14.125 Z M10.8940705,8.5 C10.6179281,8.5 10.3940705,8.27614237 10.3940705,8 C10.3940705,7.72385763 10.6179281,7.5 10.8940705,7.5 L13.5190705,7.5 C13.7952128,7.5 14.0190705,7.72385763 14.0190705,8 C14.0190705,8.27614237 13.7952128,8.5 13.5190705,8.5 L10.8940705,8.5 Z M0.875,8.5 C0.598857625,8.5 0.375,8.27614237 0.375,8 C0.375,7.72385763 0.598857625,7.5 0.875,7.5 L3.5,7.5 C3.77614237,7.5 4,7.72385763 4,8 C4,8.27614237 3.77614237,8.5 3.5,8.5 L0.875,8.5 Z"
                                        id="Location-Icon"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Locate;
