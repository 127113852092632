/******************************************************************************\
 * File: Harley.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/common/icons/Icon.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Harley extends React.Component {
    render() {
        const { className } = this.props;

        const cn = classNames({
            [className]: className,
            [style['no-stroke']]: true
        });

        return (
            <svg className={cn} width="47px" height="38px" viewBox="0 0 47 38">
                <g
                    id="Preview"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                >
                    <g
                        id="No-Location-Selected"
                        transform="translate(-30.000000, -21.000000)"
                        fill="#FFFFFF"
                    >
                        <path
                            d="M32.9031045,43.4146652 L74.0967604,43.4146652 L74.0967604,36.9333974 L32.9031045,36.9333974 L32.9031045,43.4146652 Z M65.7922547,49.2087014 C65.7922547,49.2087014 60.477598,54.059748 53.5000676,55.8107063 L53.4999324,55.8107063 C46.522402,54.059748 41.2077453,49.2087014 41.2077453,49.2087014 C42.7923869,48.2605913 43.3258253,47.0375885 43.5049892,46.2786692 L63.4951459,46.2786692 C63.6743098,47.0375885 64.2077482,48.2605913 65.7922547,49.2087014 L65.7922547,49.2087014 Z M41.1627517,29.8493871 C41.1627517,29.8493871 47.1528537,30.1638966 53.4999324,25.4469275 L53.5000676,25.4469275 C59.8471463,30.1638966 65.8373835,29.8493871 65.8373835,29.8493871 C63.8950895,31.8197205 63.5716216,33.3919986 63.5332486,34.0693934 L43.4668865,34.0693934 C43.4285135,33.3919986 43.1049105,31.8197205 41.1627517,29.8493871 L41.1627517,29.8493871 Z M77,34.0693934 L66.6742926,34.0693934 C66.7350948,32.4297396 68.8549313,31.6461608 68.8549313,31.6461608 L68.8549313,27.4238637 C57.3250692,26.7951142 53.5000676,21 53.5000676,21 L53.4999324,21 C53.4999324,21 49.6750659,26.7951142 38.1450687,27.4238637 L38.1450687,31.6461608 C38.1450687,31.6461608 40.2650403,32.4297396 40.3258425,34.0693934 L30,34.0693934 L30,46.2786692 L40.290307,46.2786692 C39.7033627,48.3833497 36.7714784,48.9168301 36.7714784,48.9168301 C45.171241,57.6082886 53.4999324,59 53.4999324,59 L53.5000676,59 C53.5000676,59 61.828759,57.6082886 70.2285216,48.9168301 C70.2285216,48.9168301 67.2966373,48.3833497 66.709693,46.2786692 L77,46.2786692 L77,34.0693934 Z"
                            id="Harley-Alt-Logo"
                        />
                    </g>
                </g>
            </svg>
        );
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Harley;
