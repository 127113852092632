/******************************************************************************\
* File: Dealer.jsx
*
* Author: Gigster
*
* Description: SVG icon of Dealer
*
* Notes: 
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/common/icons/Icon.scss';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Dealer extends React.Component {
    render() {
        const { className, onClick } = this.props;

        const cn = classNames(style.Icon, {
            [style['clickable']]: onClick,
            [className]: className
        });

        return (
            <svg className={cn} viewBox="0 0 28 45" onClick={onClick}>
                <g id="Symbols" strokeWidth="0" fillRule="evenodd">
                    <g id="Find-a-Dealer">
                        <g id="Page-1">
                            <path
                                d="M14,35.7163 C6.78,35.7163 2.17,37.8173 2.17,39.2663 C2.17,40.7163 6.78,42.8173 14,42.8173 C21.22,42.8173 25.83,40.7163 25.83,39.2663 C25.83,37.8173 21.22,35.7163 14,35.7163 Z M14,44.9963 C7,44.9963 0,42.9963 0,39.2663 C0,35.5363 7,33.5363 14,33.5363 C21,33.5363 28,35.5363 28,39.2663 C28,42.9963 21,44.9963 14,44.9963 Z"
                                id="Fill-1"
                            />
                            <path
                                d="M18.0898,18.8267 C17.8168,18.6047 17.6058,18.3157 17.4808,17.9867 L10.4798,17.9867 C10.3568,18.3147 10.1498,18.6037 9.8798,18.8267 C11.0918,19.8217 12.4938,20.5597 13.9998,20.9967 C15.4958,20.5567 16.8868,19.8187 18.0898,18.8267"
                                id="Fill-3"
                            />
                            <path
                                d="M14,10.3067 C12.827,11.1667 11.447,11.7017 10,11.8567 C10.237,12.1507 10.411,12.4917 10.51,12.8567 L17.44,12.8567 C17.539,12.4917 17.712,12.1507 17.95,11.8567 C16.521,11.6937 15.159,11.1587 14,10.3067"
                                id="Fill-5"
                            />
                            <polygon
                                id="Fill-7"
                                points="6.67 16.3666 21.32 16.3666 21.32 14.5066 6.67 14.5066"
                            />
                            <path
                                d="M22.9404,17.9966 L19.3394,17.9966 C19.5894,18.2176 19.8934,18.3696 20.2194,18.4366 L20.7104,18.5266 L20.3604,18.8866 C18.6754,20.7766 16.4584,22.1116 14.0004,22.7166 C11.5564,22.1046 9.3534,20.7696 7.6804,18.8866 L7.3304,18.5266 L7.8304,18.4366 C8.1394,18.3616 8.4244,18.2106 8.6604,17.9966 L5.0604,17.9966 L5.0604,12.8966 L8.7594,12.8966 C8.6184,12.7336 8.4404,12.6066 8.2404,12.5266 L8.0604,12.4566 L8.0604,10.4566 L8.3194,10.4566 C12.3194,10.2366 13.7594,8.2066 13.7704,8.1866 L14.0004,7.8266 L14.2304,8.1666 C14.2304,8.1666 15.6394,10.1666 19.6794,10.4366 L19.9404,10.4366 L19.9404,12.4366 L19.7594,12.5066 C19.5594,12.5876 19.3814,12.7136 19.2404,12.8766 L22.9404,12.8766 L22.9404,17.9966 Z M23.8204,4.0866 C18.4384,-1.3344 9.6824,-1.3664 4.2614,4.0156 C4.2374,4.0386 4.2134,4.0626 4.1904,4.0866 C-0.6696,8.9966 -1.2696,18.1466 2.8794,23.7066 L14.0004,39.8566 L25.1104,23.7266 C29.2694,18.1466 28.6694,8.9966 23.8204,4.0866 L23.8204,4.0866 Z"
                                id="Fill-9"
                            />
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Dealer;
