/******************************************************************************\
 * File: App.jsx
 *
 * Author: Gigster
 *
 * Description: Top level react component loaded on Route '/'
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Route, matchPath } from 'react-router-dom';
import withRouter from '@/helpers/hooks';

import { createRoot } from 'react-dom/client';
import { flushSync } from 'react-dom';
import SmartBanner from 'react-smartbanner';
import 'react-smartbanner/dist/main.css';
import { ANDROID_APP_URL, LOCALHOST } from '@/helpers/constants';
import { languages } from '@/helpers/languages';

//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/App.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import Header from '@/components/common/header/Header';
import CreateHeader from '@/components/common/header/CreateHeader';
import PreviewHeader from '@/components/common/header/PreviewHeader';
import MobileMenu from '@/components/common/header/MobileMenu';
import ProtectedRoute from '@/components/auth/ProtectedRoute';
import MapListView from '@/components/mapListView/MapListView';
import MapCollectionView from '@/components/mapListView/MapCollectionView';
import ProfileView from '@/components/myAccountView/ProfileView';
import SavedView from '@/components/myAccountView/SavedView';
import BadgesView from '@/components/shared/BadgesView';
import CollectionsView from '@/components/collections/CollectionsView';
import ChallengesView from '@/components/shared/ChallengesView';
import HomeView from '@/components/homeView/HomeView';
import SettingsModal from '@/components/homeView/SettingsModal';
import CheckboxModal from '@/components/homeView/CheckboxModal';
import UploadModal from '@/components/homeView/UploadModal';
import ConvertRideModal from '@/components/homeView/ConvertRideModal';
import NotFoundView from '@/components/errorView/NotFoundView';
import Notifications from '@/components/common/Notifications';
import ErrorBoundary from '@/components/common/ErrorBoundary';
import TransitionSwitch from '@/components/common/TransitionSwitch';
//import Switch from '@/components/common/ConnectedSwitch';

//------------------------------------------------------------------------------
// Store -----------------------------------------------------------------------
import { getFetchRideTags } from '@/store/map';

import { updateUrl } from '@/store/routing';
import { fetchDealerCodes } from '@/store/dealers';
import { monitorNetworkStatus } from '@/store/error';
import { setPrint } from '@/store/map';
import { checkSessionTimeout } from '@/store/auth';
import { SESSION_TIMEOUT_ERROR, setError } from '@/store/error';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { ensureGigyaWebSDK } from '@/helpers/gigya';
import { getScreenSize } from '@/helpers/responsive';
import { ScreenSizes } from '@/helpers/constants';
import { Routes as RoutesDom } from 'react-router-dom';
import { Routes } from '@/helpers/routes';
import IEModal from '@/components/homeView/IEModal';
import ShowModal from '@/components/homeView/ShowModal';
import { analyticsEvent } from '@/helpers/analytics';
import { getAppContent } from '@/helpers/api';

import {
    set as setLocalStore,
    get as getLocalStore
} from '@/helpers/persistor';
import { dateExpired, rideViews, onRideView, tb } from '@/helpers/functions';
import { trackFocus } from '@/helpers/dom';
import { setLocale, getCurrentLocale } from '@/helpers/i18n';
import history from '@/helpers/history';
import AppContext from '@/contexts/AppContext';

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
const AnimatedHeader = ({
    openSettingsModal,
    openConvertRideModal,
    closeSettingsModal,
    updateUploadModal,
    onClickPrint,
    closeUploadModal
}) => {
    return (
        <TransitionSwitch
            transitionProps={{
                classNames: {
                    enter: style.headerEnter,
                    enterActive: style.headerEnterActive,
                    exit: style.headerExit,
                    exitActive: style.headerExitActive
                },
                timeout: 400
            }}>
            <Route
                path={Routes.RIDE_PREVIEW}
                element={
                    <PreviewHeader
                        onClickPrint={onClickPrint}
                        openSettingsModal={openSettingsModal}
                        openConvertRideModal={openConvertRideModal}
                        closeSettingsModal={closeSettingsModal}
                        updateUploadModal={updateUploadModal}
                    />
                }
            />
            <Route
                path={Routes.RIDE_CREATE_PREVIEW}
                element={
                    <PreviewHeader
                        onClickPrint={onClickPrint}
                        openSettingsModal={openSettingsModal}
                        openConvertRideModal={openConvertRideModal}
                        closeSettingsModal={closeSettingsModal}
                        updateUploadModal={updateUploadModal}
                    />
                }
            />

            <Route
                path={Routes.RIDE_EDIT}
                element={
                    <CreateHeader
                        onClickPrint={onClickPrint}
                        openSettingsModal={openSettingsModal}
                        closeSettingsModal={closeSettingsModal}
                        updateUploadModal={updateUploadModal}
                        openConvertRideModal={openConvertRideModal}
                    />
                }
            />

            <Route
                path={Routes.RIDE_CREATE}
                element={
                    <CreateHeader
                        onClickPrint={onClickPrint}
                        openSettingsModal={openSettingsModal}
                        closeSettingsModal={closeSettingsModal}
                        updateUploadModal={updateUploadModal}
                        closeUploadModal={closeUploadModal}
                        openConvertRideModal={openConvertRideModal}
                    />
                }
            />
            <Route
                path={Routes.SHARED_RIDE}
                element={
                    <PreviewHeader
                        onClickPrint={onClickPrint}
                        openSettingsModal={openSettingsModal}
                        openConvertRideModal={openConvertRideModal}
                        closeSettingsModal={closeSettingsModal}
                        updateUploadModal={updateUploadModal}
                    />
                }
            />
        </TransitionSwitch>
    );
};

class App extends React.Component {
    ieDateExpired = dateExpired(getLocalStore('ieModalShown'), 30);

    state = {
        screenSize: ScreenSizes.MOBILE,
        windowWidth: 0,
        locale: null,
        showIEModal: false || (!!document.documentMode && this.ieDateExpired),
        showShowModal: false,
        showConvertRideModal: false,
        selectedRide: null,
        showUploadModal: false,
        showSettingsModal: false,
        showCheckboxModal: false
    };

    islocaleSetByUser = JSON.parse(localStorage.getItem('localeSetByUser')); //getLocalStore('localeSetByUser');

    setLocale = (l, localeSetByUser = this.islocaleSetByUser) => {
        // Used to trigger update.
        this.setState({ locale: l });
        // Do not change this value
        // This is used for gigya screens
        const getGigyaLocale = (l) => {
            const langCountry = l.toLowerCase().split('//').reverse()[0];
            const countryLang = langCountry.split('-').join('_');
            return countryLang;
        };

        setLocalStore('localeSetByUser', { source: localeSetByUser });

        const refreshApp = (l) => {
            setLocalStore('locale', l);
            localStorage.gigyaLocale = getGigyaLocale(l);
        };

        return setLocale(l, localeSetByUser).then((ctx) => {
            this.setState({ locale: l });
            if (!ctx || l === ctx.locale) return;
            try {
                refreshApp(l);
            } catch (error) {
                console.log('err', error);
            }
        });
    };

    getContextValue = () => {
        const { screenSize, windowWidth } = this.state;
        return {
            isMobile: screenSize === ScreenSizes.MOBILE,
            windowWidth,
            screenSize
        };
    };
    handleWindowResize = () => {
        this.setState({
            screenSize: getScreenSize(),
            windowWidth: window.innerWidth
        });
    };

    setLanguageFromURL = () => {
        const params = new URLSearchParams(window.location.search);
        const hdCountry = params.get('country');
        const { source } = getLocalStore('localeSetByUser') || {};
        if (source === 'user' || !hdCountry) return;
        const language = hdCountry.replace('_', '-');
        const languageDetails = languages
            .filter((l) => !!l.default)
            .find((lang) => lang.code === language || language === lang.locale);

        if (languageDetails && !!language) {
            const newLocale = `${languageDetails.name}//${languageDetails.locale}`;
            this.setLocale(newLocale, 'url');
        }
    };

    showModalContentFetched = false;
    showModalContent = null;
    getDeepLink = () => {
        const { href } = window.location;
        const deepLink = href.includes(LOCALHOST)
            ? href.replace(LOCALHOST, 'devmaps.harley-davidson.com')
            : href;
        const transformData = (data) => {
            return {
                title: data.title,
                contentDisplayEndDateTime: data.endTime,
                description: data.content,
                fallbackURL: data.externalLink
            };
        };
        getAppContent(deepLink).then((res) => {
            this.showModalContent = transformData(res.data.appContent);
            this.showModalContentFetched = true;
        });
    };

    componentDidMount() {
        const container = document.getElementById('content');
        const root = createRoot(container);
        root.render(
            <SmartBanner
                title={'Harley-Davidson'}
                storeText={{ android: 'In Google Play' }}
                button={'open'}
                position={'bottom'}
                url={{ android: ANDROID_APP_URL }}
            />
        );

        window.location.pathname.includes('/show/content/')
            ? this.getDeepLink()
            : (this.showModalContentFetched = true);

        // add gigya api
        ensureGigyaWebSDK(() => {
            // make sure we're logged in
            const { checkSessionTimeout, setTimeoutError } = this.props;
            if (checkSessionTimeout()) {
                setTimeoutError();
            }
            this.authCheck = setInterval(checkSessionTimeout, 5 * 60 * 1000);
        });

        // sync url with location changes
        const {
            location,
            updateUrl,
            fetchRideTags,
            location: { search }
        } = this.props;
        fetchRideTags();
        const extendLastUpdated = new Date().toISOString().slice(0, 10);
        const tbt = tb(search);
        if (location.pathname === Routes.HOME && tbt)
            setLocalStore(tbt, { [tbt]: extendLastUpdated });

        updateUrl(location);
        history.listen(updateUrl);

        // make sure the app is online
        this.props.monitorNetworkStatus();

        // track window size
        window.addEventListener('resize', this.handleWindowResize);
        this.handleWindowResize();

        this.untrackFocus = trackFocus(document.body);

        window.setLocale = this.setLocale;

        // If user has not selected a language - check for HD supplied country
        this.setLanguageFromURL();

        // restore user's locale
        const locale = getCurrentLocale();
        const { source } = getLocalStore('localeSetByUser') || {};
        if (source === 'user') this.setLocale(locale, source);
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.location !== this.props.location) {
            const { location } = this.props;
            const { pathname } = location;
            const shouldShowModal = pathname.includes('/show/');
            this.setState({ showShowModal: shouldShowModal });
        }
    };

    componentWillUnmount() {
        //check this 'cause according to React documentation, we should not call setState() in this lifecycle
        // https://legacy.reactjs.org/docs/react-component.html#componentwillunmount
        this.setState({
            showShowModal: false,
            showConvertRideModal: false,
            selectedRide: null
        });
        window.removeEventListener('resize', this.handleWindowResize);
        this.untrackFocus();
        clearInterval(this.authCheck);
    }

    closeIEModal = () => {
        this.setState({ showIEModal: false });
        setLocalStore('ieModalShown', Date.now());
    };

    openSettingsModal = () => {
        analyticsEvent('profile settings');
        this.setState({ showSettingsModal: true });
    };

    openConvertRideModal = (selectedRide = null) => {
        this.setState({ showConvertRideModal: true, selectedRide });
    };

    closeSettingsModal = () => {
        this.setState({ showSettingsModal: false });
        setLocalStore('settingsModalShown', Date.now());
    };

    updateUploadModal = (
        fileRef,
        uploadMsg,
        successfullyUploaded,
        uploading,
        isRecorded = false
    ) => {
        this.closeUploadModal();
        flushSync(() => {
            this.setState({
                showUploadModal: true,
                fileRef,
                uploadMsg,
                successfullyUploaded,
                uploading,
                isRecorded
            });
        });
    };

    closeUploadModal = () => {
        flushSync(() => {
            this.setState({
                showUploadModal: false,
                fileRef: null,
                successfullyUploaded: false,
                uploadMsg: null,
                isRecorded: false
            });
        });
    };

    closeConvertRideModal = () =>
        this.setState({ showConvertRideModal: false, selectedRide: null });

    closeShowModal = () => this.setState({ showShowModal: false });

    setToggleModal = (prop) => (val) => {
        const newVal = typeof val === 'boolean' ? val : !this.state[prop];
        this.setState({ [prop]: newVal });
    };

    setShowOptionsPanel = () => this.setState({ showOptionsPanel: true });

    setShowOptions = (val) => this.setState({ showOptionsPanel: val });

    render() {
        const { location, mobileMenu, onClickPrint } = this.props;
        const { pathname } = location;
        const {
            locale,
            showIEModal,
            showSettingsModal,
            showUploadModal,
            fileRef,
            uploadMsg,
            successfullyUploaded,
            uploading,
            isRecorded,
            showCheckboxModal,
            showShowModal,
            showConvertRideModal,
            selectedRide,
            showOptionsPanel
        } = this.state;

        const onMapView = !!matchPath({ path: Routes.MAP }, pathname);
        const toggleCheckboxModal = this.setToggleModal(
            'showCheckboxModal',
            true
        );

        const isMobile = this.getContextValue().isMobile;
        const isMobilePreview =
            isMobile && onRideView(pathname, rideViews, matchPath);
        const cn = classNames(style.AppContainer, {
            // [style['menu-open']]: mobileMenu
        });

        const showAnimatedHeader = location
            ? onRideView(pathname, rideViews, matchPath)
            : false;

        const handlePrintClick = () => {
            return onClickPrint(
                this.props.navigate,
                this.props.location.pathname
            );
        };
        return (
            <AppContext.Provider value={this.getContextValue()}>
                <div className={cn} tabIndex={-1} key={locale}>
                    <div id="content" />
                    <ErrorBoundary>
                        <MobileMenu />
                    </ErrorBoundary>
                    <div className={style.App}>
                        {showAnimatedHeader && (
                            <ErrorBoundary>
                                <AnimatedHeader
                                    location={location}
                                    showSearch={onMapView}
                                    openSettingsModal={this.openSettingsModal}
                                    openConvertRideModal={
                                        this.openConvertRideModal
                                    }
                                    closeSettingsModal={this.closeSettingsModal}
                                    updateUploadModal={this.updateUploadModal}
                                    closeUploadModal={this.closeUploadModal}
                                    onClickPrint={handlePrintClick}
                                />
                            </ErrorBoundary>
                        )}
                        {!isMobilePreview && (
                            <Header
                                showSearch={onMapView}
                                openSettingsModal={this.openSettingsModal}
                                openConvertRideModal={this.openConvertRideModal}
                                closeSettingsModal={this.closeSettingsModal}
                                updateUploadModal={this.updateUploadModal}
                                transparent={!onMapView}
                                background={!onMapView}
                            />
                        )}
                        <ErrorBoundary>
                            <Notifications />
                        </ErrorBoundary>

                        <ErrorBoundary>
                            {showIEModal && (
                                <IEModal
                                    isOpen={showIEModal}
                                    onRequestClose={this.closeIEModal}
                                />
                            )}
                        </ErrorBoundary>

                        <ErrorBoundary>
                            {showShowModal && this.showModalContentFetched && (
                                <ShowModal
                                    isOpen
                                    isMobile={isMobile}
                                    onRequestClose={this.closeShowModal}
                                    content={this.showModalContent}
                                />
                            )}
                        </ErrorBoundary>

                        <ErrorBoundary>
                            {showSettingsModal && (
                                <SettingsModal
                                    isOpen={showSettingsModal}
                                    onRequestClose={this.closeSettingsModal}
                                />
                            )}
                        </ErrorBoundary>
                        <ErrorBoundary>
                            {showCheckboxModal && (
                                <CheckboxModal
                                    isOpen={showCheckboxModal}
                                    onRequestClose={toggleCheckboxModal}
                                    onRequestCTA={() => {
                                        toggleCheckboxModal();
                                        this.setShowOptions(true);
                                    }}
                                />
                            )}
                        </ErrorBoundary>
                        <ErrorBoundary>
                            {(showUploadModal || uploadMsg) && (
                                <UploadModal
                                    isOpen={showUploadModal}
                                    onRequestClose={this.closeUploadModal}
                                    updateUploadModal={this.updateUploadModal}
                                    fileRef={fileRef}
                                    uploadMsg={uploadMsg}
                                    successfullyUploaded={successfullyUploaded}
                                    uploading={uploading}
                                    isRecorded={isRecorded}
                                />
                            )}
                        </ErrorBoundary>
                        <ErrorBoundary>
                            {showConvertRideModal && selectedRide && (
                                <ConvertRideModal
                                    isOpen={showConvertRideModal}
                                    onRequestClose={this.closeConvertRideModal}
                                    currentRide={selectedRide}
                                />
                            )}
                        </ErrorBoundary>

                        <ErrorBoundary>
                            <RoutesDom>
                                <Route
                                    exact
                                    path={Routes.SHARED_CHALLENGE}
                                    element={<ChallengesView />}
                                />
                                <Route
                                    exact
                                    path={Routes.HOME}
                                    element={
                                        <HomeView
                                            showSearch={onMapView}
                                            openSettingsModal={
                                                this.openSettingsModal
                                            }
                                            openConvertRideModal={
                                                this.openConvertRideModal
                                            }
                                            closeSettingsModal={
                                                this.closeSettingsModal
                                            }
                                            updateUploadModal={
                                                this.updateUploadModal
                                            }
                                            transparent={!onMapView}
                                            background={!onMapView}
                                            isMobilePreview={isMobilePreview}
                                        />
                                    }
                                />
                                <Route
                                    exact
                                    path={Routes.SHOW}
                                    element={<HomeView />}
                                />
                                <Route
                                    path={Routes.MAP_COLLECTIONS}
                                    element={
                                        <MapCollectionView
                                            toggleCheckboxModal={
                                                toggleCheckboxModal
                                            }
                                            showOptions={showOptionsPanel}
                                            setShowOptions={this.setShowOptions}
                                        />
                                    }
                                />
                                {/* DEFAULT MAP ROUTES */}
                                <Route
                                    path={Routes.MAP}
                                    element={
                                        <MapListView
                                            type="default_map"
                                            toggleCheckboxModal={
                                                toggleCheckboxModal
                                            }
                                            showOptions={showOptionsPanel}
                                            setShowOptions={this.setShowOptions}
                                            updateUploadModal={
                                                this.updateUploadModal
                                            }
                                        />
                                    }
                                />
                                {/* Specific section routes */}
                                <Route
                                    path={Routes.MAP_RIDE}
                                    element={
                                        <MapListView
                                            type="default_map"
                                            toggleCheckboxModal={
                                                toggleCheckboxModal
                                            }
                                            showOptions={showOptionsPanel}
                                            setShowOptions={this.setShowOptions}
                                            updateUploadModal={
                                                this.updateUploadModal
                                            }
                                        />
                                    }
                                />
                                <Route
                                    path={Routes.MAP_DEALER}
                                    element={
                                        <MapListView
                                            type="default_map"
                                            toggleCheckboxModal={
                                                toggleCheckboxModal
                                            }
                                            showOptions={showOptionsPanel}
                                            setShowOptions={this.setShowOptions}
                                            updateUploadModal={
                                                this.updateUploadModal
                                            }
                                        />
                                    }
                                />
                                <Route
                                    path={Routes.MAP_EVENT}
                                    element={
                                        <MapListView
                                            type="default_map"
                                            toggleCheckboxModal={
                                                toggleCheckboxModal
                                            }
                                            showOptions={showOptionsPanel}
                                            setShowOptions={this.setShowOptions}
                                            updateUploadModal={
                                                this.updateUploadModal
                                            }
                                        />
                                    }
                                />
                                {/* Section routes */}
                                <Route
                                    path={Routes.MAP_RIDES}
                                    element={
                                        <MapListView
                                            type="default_map"
                                            toggleCheckboxModal={
                                                toggleCheckboxModal
                                            }
                                            showOptions={showOptionsPanel}
                                            setShowOptions={this.setShowOptions}
                                            updateUploadModal={
                                                this.updateUploadModal
                                            }
                                        />
                                    }
                                />
                                <Route
                                    path={Routes.MAP_DEALERS}
                                    element={
                                        <MapListView
                                            type="default_map"
                                            toggleCheckboxModal={
                                                toggleCheckboxModal
                                            }
                                            showOptions={showOptionsPanel}
                                            setShowOptions={this.setShowOptions}
                                            updateUploadModal={
                                                this.updateUploadModal
                                            }
                                        />
                                    }
                                />
                                <Route
                                    path={Routes.MAP_EVENTS}
                                    element={
                                        <MapListView
                                            type="default_map"
                                            toggleCheckboxModal={
                                                toggleCheckboxModal
                                            }
                                            showOptions={showOptionsPanel}
                                            setShowOptions={this.setShowOptions}
                                            updateUploadModal={
                                                this.updateUploadModal
                                            }
                                        />
                                    }
                                />
                                {/* CREATE ROUTES */}

                                <Route
                                    path={Routes.RIDE_CREATE}
                                    element={
                                        <MapListView
                                            type="create"
                                            toggleCheckboxModal={
                                                toggleCheckboxModal
                                            }
                                            showOptions={showOptionsPanel}
                                            setShowOptions={this.setShowOptions}
                                            updateUploadModal={
                                                this.updateUploadModal
                                            }
                                        />
                                    }
                                />
                                <Route
                                    path={Routes.RIDE_EDIT}
                                    element={
                                        <MapListView
                                            type="create"
                                            toggleCheckboxModal={
                                                toggleCheckboxModal
                                            }
                                            showOptions={showOptionsPanel}
                                            setShowOptions={this.setShowOptions}
                                            updateUploadModal={
                                                this.updateUploadModal
                                            }
                                        />
                                    }
                                />

                                {/* PREVIEW ROUTES */}
                                <Route
                                    path={Routes.RIDE_PREVIEW}
                                    element={
                                        <MapListView
                                            type="preview"
                                            toggleCheckboxModal={
                                                toggleCheckboxModal
                                            }
                                            showOptions={showOptionsPanel}
                                            setShowOptions={this.setShowOptions}
                                            updateUploadModal={
                                                this.updateUploadModal
                                            }
                                        />
                                    }
                                />
                                <Route
                                    path={Routes.RIDE_CREATE_PREVIEW}
                                    element={
                                        <MapListView
                                            type="preview"
                                            toggleCheckboxModal={
                                                toggleCheckboxModal
                                            }
                                            showOptions={showOptionsPanel}
                                            setShowOptions={this.setShowOptions}
                                            updateUploadModal={
                                                this.updateUploadModal
                                            }
                                        />
                                    }
                                />
                                <Route
                                    path={Routes.SHARED_RIDE}
                                    element={
                                        <MapListView
                                            type="preview"
                                            toggleCheckboxModal={
                                                toggleCheckboxModal
                                            }
                                            showOptions={showOptionsPanel}
                                            setShowOptions={this.setShowOptions}
                                        />
                                    }
                                />

                                <Route
                                    path={Routes.SHARED_EVENT}
                                    element={
                                        <MapListView
                                            type="default_map"
                                            toggleCheckboxModal={
                                                toggleCheckboxModal
                                            }
                                            showOptions={showOptionsPanel}
                                            setShowOptions={this.setShowOptions}
                                        />
                                    }
                                />
                                <Route
                                    exact
                                    path={Routes.PROFILE}
                                    element={
                                        <ProtectedRoute
                                            component={ProfileView}
                                        />
                                    }
                                />
                                <Route
                                    exact
                                    path={Routes.SAVED}
                                    element={
                                        <ProtectedRoute component={SavedView} />
                                    }
                                />
                                <Route
                                    exact
                                    path={Routes.COLLECTION}
                                    element={<CollectionsView />}
                                />
                                <Route
                                    exact
                                    path={Routes.BADGES}
                                    element={<BadgesView />}
                                />
                                <Route element={<NotFoundView />} />
                            </RoutesDom>
                        </ErrorBoundary>
                    </div>
                </div>
            </AppContext.Provider>
        );
    }
}
//------------------------------------------------------------------------------
// Redux State -----------------------------------------------------------------
const mapStateToProps = (state) => {
    return {
        mobileMenu: state.app.mobileMenu,
        createModalShowing: state.app.createModalShowing
    };
};
//------------------------------------------------------------------------------
// Redux Actions ---------------------------------------------------------------
const mapDispatchToProps = (dispatch) => {
    return {
        checkSessionTimeout: () => dispatch(checkSessionTimeout()),
        monitorNetworkStatus: () => dispatch(monitorNetworkStatus()),
        fetchRideTags: () => dispatch(getFetchRideTags()),
        fetchDealerCodes: () => dispatch(fetchDealerCodes()),
        updateUrl: (url, action) => dispatch(updateUrl(url, action)),
        setTimeoutError: () => dispatch(setError(SESSION_TIMEOUT_ERROR)),
        onClickPrint: (navigate, pathname) => {
            navigate(pathname);
            dispatch(setPrint(true));
        }
    };
};
//------------------------------------------------------------------------------
// Redux Connect ---------------------------------------------------------------
const container = connect(mapStateToProps, mapDispatchToProps)(App);
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withRouter(container);
