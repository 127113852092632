/******************************************************************************\
 * File: DisplayRow.jsx
 *
 * Author: Gigster
 *
 * Description: DisplayRow
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/common/cards/DisplayRow.scss';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------
import ArrowLink from '@/components/common/ArrowLink';
import Link from '@/components/common/header/Link';
import { selectItemById, previewEvent } from '@/store/events';
import { setEventBookmark, isEventBookmarked } from '@/store/eventBookmarks';

import { DisplayRowAction } from '@/components/common/cards/DisplayRowAction';
import { updateSidebar } from '@/store/map';

import {
    prettyHourRange,
    displayRowDate,
    formattedDateRange
} from '@/helpers/functions';
import { createLogger } from '@/helpers/debug';
import { translate } from '@/helpers/i18n';
const t = translate('cards.DisplayRow');
const log = createLogger('Display Row', true);

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class DisplayRow extends Component {
    static defaultProps = {
        label: '',
        large: true,
        itemProps: {},
        defaultSort: 0
    };

    constructor(props) {
        super(props);

        this.state = {
            rendered: false
        };
    }

    getDealerLink = (dealer) => {
        return `/map/dealer/${dealer.dealerId}?sidebar=1`;
    };

    getDealer = (item) => {
        return item.eventActivities[0].dealer;
    };

    addDefaultSrc = (e) => {
        const defaultImage = '/img/image-route-thumbnail-placeholder.jpg';
        e.target.src = defaultImage;
    };

    getFormattedDateRange = (item, narrow) => {
        const startDate = displayRowDate(item.startDate);
        const endDate = displayRowDate(item.endDate);
        const cnStartDate = classNames({
            [style['startDate']]: !!narrow
        });
        const cnEndDate = classNames({
            [style['endDate']]: !!narrow
        });

        const startDateFormatted = (
            <div className={cnStartDate}>
                <div className={style.month}>{startDate[0]}</div>
                <div className={style.day}>{startDate[1]}</div>
            </div>
        );

        const endDateFormatted =
            item.startDate !== item.endDate ? (
                <div className={cnEndDate}>
                    <div className={style.month}>{endDate[0]}</div>
                    <div className={style.day}>{endDate[1]}</div>
                </div>
            ) : (
                ''
            );

        const cn = classNames({
            [style['dates']]: !narrow,
            [style['narrowDates']]: !!narrow
        });

        return (
            <div className={cn}>
                {startDateFormatted}
                {endDateFormatted && <div className={style.hypen}>-</div>}
                {endDateFormatted}
            </div>
        );
    };

    render() {
        const {
            item,
            icon,
            isEventBookmarked,
            openSidebar,
            label,
            labelKey,
            deleteEventBookmark,
            setEventBookmark,
            narrow,
            dealerDetails,
            isDealer
        } = this.props;

        if (labelKey === 'events') {
            const eventHostDescription = !!item.eventActivities[0].address.name
                ? item.eventActivities[0].address.name
                : item.eventHostDescription;
            const hostedBy = (this.getDealer(item) || {}).dealerName ? (
                <Link plain to={this.getDealerLink(this.getDealer(item))}>
                    {this.getDealer(item).dealerName}
                </Link>
            ) : !!eventHostDescription ? (
                <span>{eventHostDescription}</span>
            ) : (
                ''
            );
            const cn = classNames(style.item, {
                [style['narrowItem']]: !!narrow
            });
            const activities = item.eventActivities || [];
            const { hours: eventHours } = activities[0];

            return (
                labelKey === 'events' && (
                    <div className={cn}>
                        {this.getFormattedDateRange(item, narrow)}
                        <div className={style.titles}>
                            <div
                                className={classNames(
                                    style.title,
                                    style.eventTitle
                                )}>
                                {item.eventName}
                            </div>
                            {!dealerDetails && (
                                <div className={style.subtitle}>{hostedBy}</div>
                            )}
                            {dealerDetails && (
                                <div className={style.subtitle}>
                                    {formattedDateRange(
                                        item.startDate,
                                        item.endDate
                                    )}
                                </div>
                            )}
                            {eventHours[0] && (
                                <div className={style.subtitle}>
                                    {prettyHourRange(
                                        eventHours[0]
                                    ).toUpperCase()}
                                </div>
                            )}
                            <div className={style.detailsLink}>
                                <ArrowLink
                                    className={style.LinkText}
                                    {...{
                                        text: t('Details'),
                                        onClick: openSidebar
                                    }}
                                />
                            </div>
                        </div>
                        <DisplayRowAction
                            icon={icon}
                            className={style.right}
                            active={!!isEventBookmarked}
                            onToggle={() => {
                                setEventBookmark(!isEventBookmarked);
                                deleteEventBookmark(item.eventId);
                            }}
                        />
                    </div>
                )
            );
        }
    }
}
//------------------------------------------------------------------------------
// Redux State -----------------------------------------------------------------
const mapStateToProps = (state, ownProps) => {
    const { item } = ownProps;
    return {
        isEventBookmarked: isEventBookmarked(item.eventId, state)
    };
};

//------------------------------------------------------------------------------
// Redux Actions ---------------------------------------------------------------
const mapDispatchToProps = (dispatch, ownProps) => {
    const { item, didSelectItem, isDealer } = ownProps;
    return {
        setEventBookmark: (value) => {
            dispatch(setEventBookmark(item.eventId, value));
        },
        navigateToEvent: () => dispatch(navigateToPoint(item)),
        openSidebar: () => {
            dispatch(updateSidebar('event', null));
            dispatch(previewEvent(item, isDealer));
            didSelectItem && didSelectItem(item);
        }
    };
};

//------------------------------------------------------------------------------
// Redux Connect ---------------------------------------------------------------
const container = connect(mapStateToProps, mapDispatchToProps)(DisplayRow);
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default container;
