/******************************************************************************\
 * File: Logo.jsx
 *
 * Author: Gigster
 *
 * Description: HD Logo
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
//------------------------------------------------------------------------------
// Actions ---------------------------------------------------------------------
import { setConfirm, canSaveRide, clearRide } from '@/store/edit_ride';
import { clickAway } from '@/helpers/hoc';
import { isAuthenticated } from '@/store/auth';

// My Modules ------------------------------------------------------------------
import Link from '@/components/common/header/Link';
import Harley from '@/components/common/icons/Harley';
import * as mapActions from '@/store/map';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/common/icons/Icon.scss';
import withRouter from '@/helpers/hooks';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Logo extends React.Component {
    static defaultProps = {
        showImage: true
    };

    render() {
        const {
            className,
            iconClassName,
            isAuthenticated,
            canSaveRide,
            rideMeta,
            setConfirm,
            clearselecteddata,
            pushUrl,
            onClick,
            showImage,
            text,
            exact,
            navigate,
            ...rest
        } = this.props;

        const cn = classNames({
            [className]: className,
            [style['no-stroke']]: true
        });

        const checkConfirm = () =>
            isAuthenticated && !rideMeta.persisted && canSaveRide;

        return (
            <Link
                to={checkConfirm() ? null : '/'}
                className={cn}
                onClick={() => {
                    if (checkConfirm()) {
                        setConfirm('confirm').then(() => {
                            clearselecteddata();
                            navigate('/');
                        });
                    } else {
                        clearselecteddata();
                        navigate('/');
                    }
                }}
                exact
                {...rest}>
                {showImage && <Harley className={iconClassName} />}
                {text}
            </Link>
        );
    }
}

//------------------------------------------------------------------------------
// Redux State -----------------------------------------------------------------
const mapStateToProps = (state, ownProps) => {
    return {
        rideMeta: state.edit_ride.present.meta,
        isAuthenticated: isAuthenticated(state),
        canSaveRide: canSaveRide(state)
    };
};
//------------------------------------------------------------------------------
// Redux Actions ---------------------------------------------------------------
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setConfirm: (value) => dispatch(setConfirm(value)),
        clearselecteddata: () =>
            dispatch(mapActions.update('selectedData', null)),
        clearRide: () => dispatch(clearRide())
    };
};
//------------------------------------------------------------------------------
// Redux Connect ---------------------------------------------------------------
const container = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Logo)
);
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default clickAway(container);
