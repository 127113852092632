/******************************************************************************\
 * File: MobileMenu.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { showMobileMenu } from '@/store/app';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/common/header/MobileMenu.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import HeaderLinks from '@/components/common/header/HeaderLinks';
import AppStoreBadges from '@/components/common/AppStoreBadges';
import Close from '@/components/common/icons/Close';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { escapable } from '@/helpers/hoc';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class MobileMenu extends React.Component {
    render() {
        const { showMobileMenu, isOpen } = this.props;

        const cn = classNames(style.MobileMenu, {
            [style['open']]: isOpen
        });
        return (
            <div className={cn}>
                <Close
                    className={style.close}
                    onClick={() => {
                        showMobileMenu(false);
                    }}
                />
                <HeaderLinks
                    className={style.links}
                    onLink={() => {
                        showMobileMenu(false);
                    }}
                    isMobile={true}
                    descriptor={{
                        homeLink: true,
                        newRideLink: true,
                        savedRidesLink: true,
                        mapLink: true,
                        userLink: true,
                        inlineUser: true,
                        column: true,
                        showIcons: true,
                        hdLink: true
                    }}
                />
                <AppStoreBadges className={style.badges} />
            </div>
        );
    }
}
//------------------------------------------------------------------------------
const mapStateToProps = (state, ownProps) => {
    return {
        isOpen: state.app.mobileMenu
    };
};
//------------------------------------------------------------------------------
// Redux Actions ---------------------------------------------------------------
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        showMobileMenu: (open) => dispatch(showMobileMenu(open))
    };
};
// Export ----------------------------------------------------------------------
//export default escapable(MobileMenu);
const container = connect(
    mapStateToProps,
    mapDispatchToProps
)(escapable(MobileMenu));
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default container;
