/******************************************************************************\
 * File: CheckboxModal.jsx
 *
 * Author: Gigster
 *
 * Description: Checkbox modal
 *
 * Notes:
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------
import Modal, { Title, Subtitle, Button } from '@/components/common/Modal';

//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/homeView/SettingsModal.scss';

import { translate } from '@/helpers/i18n';
const t = translate('modal.checkboxModal');
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class CheckboxModal extends React.Component {
    render() {
        const { onRequestClose, onRequestCTA, isOpen, ...rest } = this.props;
        const title = t('CHARGE STATIONS');
        const subtitle = t(
            'Please select both a Level and Charge Network in Options to see charge stations on the map.'
        );
        return (
            <Modal {...this.props} contentLabel={title}>
                <div>
                    <Title>{title}</Title>
                    {subtitle && (
                        <Subtitle style={{ marginTop: 16, marginBottom: 16 }}>
                            {subtitle}
                        </Subtitle>
                    )}
                </div>
                <Button positive onClick={onRequestCTA}>
                    {t('Change Options')}
                </Button>
            </Modal>
        );
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default CheckboxModal;
