/******************************************************************************\
 * File: GasStation.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/common/icons/Icon.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class GasStation extends React.Component {
    render() {
        const { className, onClick } = this.props;

        const cn = classNames(style.Icon, {
            [style['clickable']]: onClick,
            [style['no-stroke']]: true,
            [className]: !!className
        });

        return (
            <svg className={cn} viewBox="0 0 9 10" onClick={onClick}>
                <title>gasStation</title>
                <desc>Created with Sketch.</desc>
                <defs />
                <g
                    id="Page-1"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                >
                    <path
                        d="M3.824,3.922 L1.661,3.922 C1.40535688,3.92145259 1.19809896,3.71464134 1.197,3.459 L1.197,1.739 C1.197,1.483 1.406,1.275 1.661,1.275 L3.824,1.275 C4.079,1.275 4.288,1.483 4.288,1.738 L4.288,3.458 C4.28690104,3.71464134 4.07964312,3.92145259 3.824,3.922 Z M6.824,1.647 L6.37,2.067 L6.856,2.591 C6.961,2.689 7.034,2.775 7.097,2.86 C6.888,3.026 6.6,3.334 6.6,3.77 C6.6,4.042 6.717,4.525 7.419,4.614 L7.419,7.517 C7.40925342,7.69572422 7.26148979,7.83564925 7.0825,7.83564925 C6.90351021,7.83564925 6.75574658,7.69572422 6.746,7.517 L6.746,5.81 C6.74544895,5.33968183 6.36431817,4.95855105 5.894,4.958 L5.485,4.958 L5.485,1.47 C5.485,0.662 4.823,0 4.015,0 L1.47,0 C0.662,0 0,0.662 0,1.47 L0,8.67 C0,8.884 0.174,9.058 0.388,9.058 L4.998,9.058 C5.267,9.058 5.485,8.84 5.485,8.57 L5.485,5.575 L5.895,5.575 C6.023,5.575 6.128,5.68 6.128,5.809 L6.128,7.517 C6.14467587,8.03213321 6.56709694,8.44110097 7.0825,8.44110097 C7.59790306,8.44110097 8.02032413,8.03213321 8.037,7.517 L8.037,3.733 C8.037,3.697 8.029,2.845 7.302,2.163 L6.824,1.647 Z"
                        id="gasStation"
                        fill="#FFFFFF"
                        fillRule="nonzero"
                    />
                </g>
            </svg>
        );
    }
}
export default GasStation;
