/******************************************************************************\
 * File: Map.jsx
 *
 * Author: Gigster
 *
 * Description: Default Map View
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { matchPath } from 'react-router-dom';
import withRouter from '@/helpers/hooks';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/mapListView/Map.scss';
//------------------------------------------------------------------------------
// Components ------------------------------------------------------------------
import Marker from '@/components/common/map/Marker';
import HereMarker from '@/components/common/map/here/Marker';
import RouteDealerMarker from '@/components/common/map/icons/RouteDealerMarker';
import RouteEventMarker from '@/components/common/map/icons/RouteEventMarker';

import Line from '@/components/common/map/Line';
import AnimLine from '@/components/common/map/AnimLine';
import RideMarker from '@/components/common/map/icons/RideMarker';
import RideOffRoadMarker from '@/components/common/map/icons/RideOffRoadMarker';
import RouteEnd from '@/components/common/map/icons/RouteEnd';
import MyLocation from '@/components/common/map/icons/MyLocation';
import MarkerCluster from '@/components/common/map/here/MarkerCluster';
import { unsafeCalculateRide } from '@/helpers/here';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import * as ridesActions from '@/store/rides';
import { editRide } from '@/store/edit_ride';
import { DrawerState } from '@/helpers/constants';
import { getToolTipDirection } from '@/helpers/tooltip';
import {
    getEventMarkers,
    getDealerMarkers,
    stackedMarkerProps
} from '@/helpers/markers';
import { currentSection } from '@/store/sections';
import {
    selectItemById as selectDealerId,
    routing_selectDealer
} from '@/store/dealers';
import {
    selectItemById as selectEventId,
    routing_selectEvent
} from '@/store/events';
import { Routes } from '@/helpers/routes';
import { currentUserCanEditRide, currentUserId } from '@/store/auth';
import { get as getLocalStore } from '@/helpers/persistor';
import { setBookmark, isBookmarked } from '@/store/bookmarks';
import { isAuthenticated, auth } from '@/store/auth';

//------------------------------------------------------------------------------
// Debug -----------------------------------------------------------------------
import { translate } from '@/helpers/i18n';
const t = translate('views.MapDefault');
import MapContext from '@/contexts/MapContext';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
const LineWithEndMarker = ({
    points,
    sections,
    isLoop,
    line: LineComponent,
    showEnd,
    onClick,
    onPointerEnter,
    onPointerLeave,
    isCollection,
    isSelectedCollectionRideId,
    ride
}) => {
    const lineColor = 'rgba(255,102,0,.6)';
    const expandedClickableAreaStrokeColor = 'rgba(255,102,0,.01)';
    const lineHighLightColor = 'rgba(255,102,0,1)';
    const strokeColor = !isCollection
        ? lineHighLightColor
        : (isCollection && showEnd) || isSelectedCollectionRideId
          ? lineHighLightColor
          : lineColor;
    return (
        <div>
            {showEnd ||
                (isSelectedCollectionRideId && (
                    <Marker
                        position={ride.waypoints[0]}
                        component={RideMarker}
                        componentProps={{
                            style: { pointerEvents: 'none' },
                            zIndex: 100,
                            onClick: { onClick },
                            onPointerEnter: { onPointerEnter },
                            fontSize: '12px',
                            ride: ride
                        }}
                    />
                ))}
            {(isCollection || isSelectedCollectionRideId) && (
                <LineComponent
                    points={points || []}
                    sections={sections || []}
                    style={{
                        lineWidth: 12,
                        strokeColor: expandedClickableAreaStrokeColor,
                        zIndex: 99
                    }}
                    onClick={onClick}
                    onPointerEnter={onPointerEnter}
                    onPointerLeave={onPointerLeave}
                />
            )}
            <LineComponent
                points={points || []}
                sections={sections || []}
                style={{
                    lineWidth: 5,
                    strokeColor: strokeColor
                }}
                onClick={onClick}
                onPointerEnter={onPointerEnter}
                onPointerLeave={onPointerLeave}
            />
            {!isLoop && points && (showEnd || isSelectedCollectionRideId) && (
                <div>
                    <Marker
                        position={points[0]}
                        component={RideMarker}
                        componentProps={{
                            style: { pointerEvents: 'none' },
                            zIndex: 100
                        }}
                        onPointerEnter={onPointerEnter}
                        onPointerLeave={onPointerLeave}
                    />
                    <Marker
                        position={points[points.length - 1]}
                        component={RouteEnd}
                        componentProps={{
                            style: { pointerEvents: 'none' },
                            zIndex: 100,
                            onClick: { onClick },
                            onPointerEnter: { onPointerEnter },
                            onPointerLeave: { onPointerLeave }
                        }}
                    />
                </div>
            )}
        </div>
    );
};

class MapDefault extends React.PureComponent {
    state = {
        hoveredRideId: null,
        paths: []
    };
    componentDidMount() {
        this.updateSelectedItem(this.props.location.pathname);
    }

    //ldgonzalezmedina try with getDerivatedState could be an improvement
    componentDidUpdate(prevProps) {
        const { location } = this.props;
        if (location.pathname !== prevProps.location.pathname) {
            this.updateSelectedItem(location.pathname);
        }
    }

    componentWillUnmount() {
        this.setState({ paths: [] });
        this.collectionRequested = false;
    }

    collectionRequested = false;

    updateSelectedItem(url) {
        const { loadRide, loadDealer, loadEvent } = this.props;

        /**
         * Because we don't have explicit components for any of these selected
         * items, manually match and load them:
         */
        const ridePath = matchPath({ path: Routes.MAP_RIDE }, url);
        ridePath && loadRide(ridePath ? ridePath.params.id : undefined);

        const dealerPath = matchPath({ path: Routes.MAP_DEALER }, url);
        dealerPath && loadDealer(dealerPath.params.id);

        const eventPath =
            matchPath({ path: Routes.MAP_EVENT }, url) ||
            matchPath({ path: Routes.SHARED_EVENT }, url);
        eventPath && loadEvent(eventPath.params.id);
    }

    renderRideCluster = (ride, count, rides) => {
        const { onSelectRide, onHoverRide, onUnhoverRide, userId, navigate } =
            this.props;
        const { id: rideId } = ride;
        const componentProps = stackedMarkerProps(rides, userId);
        const props =
            count === 1
                ? {
                      onClick: () => onSelectRide(rideId, navigate),
                      onPointerEnter: () => onHoverRide(rideId),
                      onPointerLeave: () => onUnhoverRide(rideId)
                  }
                : {};

        return {
            component: ride.offRoad ? RideOffRoadMarker : RideMarker,
            componentProps,
            ...props
        };
    };

    clusterForSection = (section) => {
        const { onMarkerClick, rides, location, map, eventSelected } =
            this.props;

        // Note: we pass a key to each MarkerCluster so React treats them as
        // different elements. For now, MarkerCluster doesn't support updating
        // its render prop on the fly
        const isCollection = location.search.includes('collection=');
        if (isCollection) section.key = 'collections';

        if (!this.collectionRequested && (rides || []).length > 0) {
            if (isCollection) {
                this.collectionRequested = true;
                const promises = [];
                rides.forEach((ride) => {
                    const rideObj = ride;
                    if ((ride.waypoints || []).length < 2) return;
                    promises.push(
                        unsafeCalculateRide(ride).then((ride) => {
                            ride.hideEnd = true;
                            ride.ride = rideObj;
                            return ride;
                        })
                    );
                });

                Promise.all(promises).then((response) => {
                    this.setState({ paths: response });
                });
            }
        }

        if (!this.collectionRequested) {
            const { rides, ridesFilters, ridesFiltered } = this.props;

            const rideFilterData = (data, key, value) => {
                return data.reduce(
                    (result, item) => {
                        result[item[key] === value ? 1 : 0].push(item);
                        return result;
                    },
                    [[], []]
                );
            };

            const [onRoadRides, offRoadRides] = rideFilterData(
                ridesFiltered || rides,
                'offRoad',
                true
            );

            const { events, dealers } = this.props;
            // Group Events into arrays by Dealer, for infobubble presentation
            const groupedEvents = (events || []).reduce((acc, item) => {
                const { dealerId } = item;
                const key = dealerId;
                if (!acc[key]) acc[key] = [];
                if (!!eventSelected && item.eventId == eventSelected.eventId) {
                    acc[key].unshift(item);
                } else {
                    acc[key].push(item);
                }
                return acc;
            }, {});

            const groupedEventValues = Object.values(groupedEvents);
            const { pathname } = this.props.location;
            const isMap = matchPath(Routes.MAP, pathname);
            const isRides = matchPath(Routes.MAP_RIDES, pathname);
            const isDealers =
                matchPath(Routes.MAP_DEALERS, pathname) ||
                matchPath(Routes.MAP_DEALER, pathname);
            const isEvents =
                matchPath(Routes.MAP_EVENTS, pathname) ||
                matchPath(Routes.MAP_EVENT, pathname);

            const filteredTypes = getLocalStore('filteredTypes');
            const showEvents = !!(filteredTypes || []).find(
                (t) => t === 'events'
            );
            const showDealers = !!(filteredTypes || []).find(
                (t) => t === 'hd-dealer'
            );
            return (
                <div>
                    {(isMap || isRides) && (
                        <div>
                            <MarkerCluster
                                key={`rides-${(ridesFilters || []).length}-${
                                    (onRoadRides || []).length
                                }`}
                                items={onRoadRides || []}
                                getItemPosition={(ride) =>
                                    (ride.waypoints ||
                                        ride.locationHistory ||
                                        [])[0]
                                }
                                render={this.renderRideCluster}
                                onMarkerClick={onMarkerClick}
                            />
                            <MarkerCluster
                                key={`offRoadRides-${(ridesFilters || []).length}-${
                                    (offRoadRides || []).length
                                }`}
                                items={offRoadRides || []}
                                getItemPosition={(ride) =>
                                    (ride.waypoints ||
                                        ride.locationHistory ||
                                        [])[0]
                                }
                                render={this.renderRideCluster}
                                onMarkerClick={onMarkerClick}
                            />
                        </div>
                    )}
                    {((isMap && showEvents) || isEvents) &&
                        (groupedEventValues || []).map((marker) => (
                            <div>
                                <HereMarker
                                    key={marker[0].eventId.toString()}
                                    position={marker[0].position}
                                    component={RouteEventMarker}
                                    onClick={() => onMarkerClick(marker, map)}
                                    componentProps={{
                                        ...marker[0].componentProps
                                    }}
                                />
                            </div>
                        ))}
                    {((isMap && showDealers) || isDealers) &&
                        (dealers || []).map((marker) => (
                            <div>
                                <HereMarker
                                    key={marker.id}
                                    position={marker.position}
                                    component={RouteDealerMarker}
                                    onClick={() => onMarkerClick(marker, map)}
                                    componentProps={{
                                        ...marker.componentProps
                                    }}
                                />
                            </div>
                        ))}
                </div>
            );
        }

        if (this.collectionRequested) {
            const { onSelectCollectionRideId, selectedCollectionRideId } =
                this.props;
            const { hoveredRideId, paths } = this.state;
            return (
                <div>
                    (
                    {paths.length > 0 &&
                        paths.map((ride, idx) => (
                            <div>
                                <LineWithEndMarker
                                    ride={ride.ride}
                                    sections={ride.sections}
                                    line={Line}
                                    showEnd={hoveredRideId === ride.ride.id}
                                    points={(ride || {}).points || []}
                                    isLoop={(ride || {}).isLoop}
                                    onClick={() => {
                                        onSelectCollectionRideId(ride.ride.id);
                                    }}
                                    onPointerEnter={() => {
                                        if (
                                            this.state.hoveredRideId !==
                                            ride.ride.id
                                        ) {
                                            this.setState({
                                                hoveredRideId: ride.ride.id
                                            });
                                        }
                                    }}
                                    onPointerLeave={() => {
                                        this.setState({
                                            hoveredRideId: null
                                        });
                                    }}
                                    hoveredRideId={hoveredRideId}
                                    isCollection={true}
                                    isSelectedCollectionRideId={
                                        selectedCollectionRideId ===
                                        ride.ride.id
                                    }
                                />
                                (
                                {selectedCollectionRideId === ride.ride.id && (
                                    <Marker
                                        position={ride.points[0]}
                                        onClick={() =>
                                            onMarkerClick(ride.ride, map, true)
                                        }
                                        component={RideMarker}
                                    />
                                )}
                                )
                            </div>
                        ))}
                    )
                </div>
            );
        }
        return null;
    };

    render() {
        const {
            className,
            map,
            drawerState,
            rides,
            collectionRides,
            section,
            selectedRide,
            canEditSelectedRide,
            selectedCollectionRideId,
            isRideBookmarked,
            setRideBookmark,
            isAuthenticated,
            auth,
            onMarkerClick,
            ...rest
        } = this.props;

        const { myLocation, hoverRide } = map;
        const { points, sections } = selectedRide || {};
        const cn = classNames(style.Map, {
            [className]: className
        });

        const { map: hereMap, isMobile } = this.context;

        const tooltipOffset = {
            x: 0,
            y: drawerState === DrawerState.OPEN ? 180 : 0
        };

        const direction =
            points &&
            points.length &&
            getToolTipDirection({
                map: hereMap,
                points,
                dimensions: { w: 220, h: 120 },
                offset: tooltipOffset
            }).direction;
        return (
            <div className={cn}>
                <Helmet>
                    <title>{t('Find a Ride')}</title>
                </Helmet>

                {myLocation && (
                    <Marker position={myLocation} component={MyLocation} />
                )}

                {section.key === 'rides' &&
                !isMobile &&
                ((selectedRide || {}).waypoints || {}).length ? (
                    <Marker
                        position={selectedRide.waypoints[0]}
                        onClick={() => onMarkerClick(selectedRide, map, true)}
                        component={RideMarker}
                    />
                ) : (
                    this.clusterForSection(section)
                )}

                {section.key === 'rides' && (
                    <div>
                        <LineWithEndMarker
                            line={Line}
                            points={(selectedRide || {}).points}
                            sections={(selectedRide || {}).sections}
                            isLoop={(selectedRide || {}).isLoop}
                        />

                        {!!(hoverRide || {}).points && (
                            <LineWithEndMarker
                                line={AnimLine}
                                points={hoverRide.points}
                                sections={(selectedRide || {}).sections}
                                isLoop={hoverRide.isLoop}
                            />
                        )}
                    </div>
                )}
            </div>
        );
    }
}
//------------------------------------------------------------------------------
// Redux State -----------------------------------------------------------------
const mapStateToProps = (state, ownProps) => {
    const selectedRide = state.rides.selected || {};
    const selectedCollectionRideId = state.rides.selectedCollectionRide || {};
    //ldgonzalezmedina check this out
    return {
        map: state.map,
        events: getEventMarkers(state.events) || [],
        dealers: getDealerMarkers(state.dealers) || [],
        rides: state.rides.data,
        ridesFilters: state.rides.ridesFilters,
        ridesFiltered: state.rides.ridesFiltered,
        selectedRide,
        selectedCollectionRideId,
        userId: currentUserId(state),
        canEditSelectedRide: currentUserCanEditRide(state, selectedRide),
        section: currentSection(state),
        drawerState: state.map.drawerState,
        isRideBookmarked: isBookmarked(
            selectedRide?.id || selectedCollectionRideId,
            state
        ),
        eventSelected: state.events.selected,
        isAuthenticated: isAuthenticated(state)
    };
};
//------------------------------------------------------------------------------
// Redux Actions ---------------------------------------------------------------
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        loadRide: (id) =>
            dispatch(
                id
                    ? ridesActions.routing_selectRide(id)
                    : ridesActions.routing_unselectRide()
            ),
        loadDealer: (id) => dispatch(routing_selectDealer(id)),
        loadEvent: (id) => dispatch(routing_selectEvent(id)),
        onSelectRide: (rideId, navigate) =>
            dispatch(ridesActions.selectRide(rideId, navigate)),
        onSelectCollectionRideId: (rideId) =>
            dispatch(ridesActions.selectCollectionRide(rideId)),
        onSelectDealer: (dealer) => dispatch(selectDealerId(dealer.dealerId)),
        onSelectEvent: (event) => dispatch(selectEventId(event.eventId)),
        onPreviewRide: (rideId) => dispatch(ridesActions.previewRide(rideId)),
        onEditRide: (ride) => dispatch(editRide(ride)),
        onHoverRide: (rideId) => dispatch(ridesActions.hoverRide(rideId)),
        onUnhoverRide: (rideId) => dispatch(ridesActions.unhoverRide(rideId)),
        setRideBookmark: (rideId, value, ride) =>
            dispatch(setBookmark(rideId, value, ride)),
        auth: (cb) => dispatch(auth(cb))
    };
};

MapDefault.contextType = MapContext;
//------------------------------------------------------------------------------
// Redux Connect ---------------------------------------------------------------
const container = connect(mapStateToProps, mapDispatchToProps)(MapDefault);
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withRouter(container);
