/******************************************************************************\
 * File: DarkModal.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------
import Modal from '@/components/common/Modal';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/common/DarkModal.scss';
import * as linkStyle from '@/style/common/header/Link.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
export const LinkWithIcon = ({ icon: Icon, text, ...rest }) => (
    <a className={classNames(linkStyle.Link, style['list-item'])} {...rest}>
        <Icon className={style.icon} />
        {text}
    </a>
);

const DarkModal = ({ label, children, ...rest }) => {
    return (
        <Modal
            {...rest}
            className={style.DarkModal}
            contentLabel={label}
            withBackground>
            <div className={style['not-a-wrapper']}>
                <div style={{ width: 200 }}>{children}</div>
            </div>
        </Modal>
    );
};
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default DarkModal;
