/******************************************************************************\
 * File: Progress.jsx
 *
 * Author: Gigster
 *
 * Description: Progress
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/mapListView/Progress.scss';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------
import { clamp, prettyMiles } from '@/helpers/functions';
import features from '@/helpers/features';
import { translate, formatDistance } from '@/helpers/i18n';
import { analyticsForRideWithData } from '@/helpers/analytics';
const t = translate('mapListView.Progress');
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
const Progress = (props) => {
    const {
        className,
        label,
        length,
        waypoints,
        points,
        onClick,
        value,
        dropdownOpen,
        onSelectPosition
    } = props;

    const cn = classNames(style.Progress, {
        [className]: className,
        [style.disabled]: waypoints && waypoints.length < 2,
        [style.dropdownOpen]: dropdownOpen,
        [style['clickable']]: onClick
    });

    const placeholder = !length;

    const getMostSignificantDigit = (arrayLength) => {
        const arrayLengthString = String(arrayLength);
        const significantDigit = arrayLengthString.length;
        const numberOfRepeats =
            significantDigit > 1 ? significantDigit - 2 : significantDigit - 1;
        const step =
            '0.' +
            '0'.repeat(numberOfRepeats) +
            arrayLengthString[0] +
            arrayLengthString[1];

        return { step, significantDigit };
    };

    const defaultLabel = t('Start');
    const labelValue =
        placeholder || length * value < 1
            ? label || defaultLabel
            : formatDistance(value * length);

    const stepValue =
        points && points.length
            ? getMostSignificantDigit(points.length)
            : { step: '0.1', significantDigit: 1 };

    const { step, significantDigit: fixValue } = stepValue;
    const isCreate = window.location.pathname.includes('/create');

    return (
        <div className={cn}>
            <div
                className={classNames(style.startText, {
                    [style.isCreate]:
                        isCreate && waypoints && waypoints.length < 2
                })}>
                <span
                    className={classNames(style.fixedText, {
                        [style.placeholder]: placeholder
                    })}>
                    {labelValue}
                </span>
                <span className={style.spaceholder}>
                    {!!length && formatDistance(length)}
                </span>
            </div>
            <div className={style.progressBarContainer}>
                <div className={style.progressWrap}>
                    <div
                        className={style.progressBar}
                        style={{
                            width: `${clamp(value * 100, 0, 100)}%`
                        }}
                    />
                </div>
                <input
                    className={style.range}
                    style={features.IE ? { marginTop: '14px' } : {}}
                    type="range"
                    disabled={(waypoints || {}).length === 0}
                    value={value * 100}
                    onChange={(e) => {
                        const value = e.target.value / 100;
                        const percentage = parseFloat(value.toFixed(fixValue));
                        onSelectPosition(percentage);
                    }}
                    onMouseUp={(e) => {
                        const value = e.target.value / 100;
                        const percentage = parseFloat(value.toFixed(fixValue));
                        analyticsForRideWithData('ride preview', {
                            percent: percentage
                        });
                    }}
                    step={step}
                />
            </div>
            <div
                className={classNames(style.endText, {
                    [style.placeholder]: placeholder
                })}>
                {prettyMiles(length || 0)}
            </div>
        </div>
    );
};
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Progress;
