/******************************************************************************\
 * File: ClockFill.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/common/icons/Icon.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class ClockFill extends React.Component {
    render() {
        const { className, onClick } = this.props;

        const cn = classNames(style.Icon, {
            [style['clickable']]: onClick,
            [className]: !!className
        });

        return (
            <svg className={cn} viewBox="0 0 16 16" onClick={onClick}>
                <g fill="none" fillRule="evenodd">
                    <path
                        fill="#FFF"
                        d="M7.588 8.412V4.706H6.353v4.941h4.941V8.412H7.588zM8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14z"
                    />
                </g>
            </svg>
        );
    }
}
export default ClockFill;
