/******************************************************************************\
 * File: SettingsModal.jsx
 *
 * Author: Gigster
 *
 * Description: SettingsModal modal
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------
import Modal from '@/components/common/Modal';
import {
    get as getLocalStore,
    set as setLocalStore
} from '@/helpers/persistor';

//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/homeView/SettingsModal.scss';
import { setPrefUnits } from '@/helpers/i18n';
import { translate } from '@/helpers/i18n';
const t = translate('modal.settings');
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class SettingsModal extends React.Component {
    onAfterOpen = () => {
        const { onAfterOpen } = this.props;
        onAfterOpen && onAfterOpen();

        this.$input && this.$input.select();
    };

    openLinkInNewTab(url) {
        window.open(url, '_blank');
        win.focus();
    }

    changeUnitSystem = (value) => {
        setPrefUnits(value);
    };
    onClick = (prop, val, e) => {
        let obj = getLocalStore('userPrefs') || {};
        if (prop === 'distance' && obj[prop] !== val) {
            let metricVal =
                val === 'metric'
                    ? H.ui.UnitSystem.METRIC
                    : H.ui.UnitSystem.IMPERIAL;
            this.changeUnitSystem(metricVal);
        }
        obj[prop] = val;
        obj.userSet = true;
        setLocalStore('userPrefs', obj);

        const el = document.getElementById(e.target.getAttribute('for'));
        if (!!el) el.checked = true;
    };

    render() {
        const { onRequestClose, isOpen, ...rest } = this.props;
        // TODO - translations
        const headerText = t('Settings');

        const distanceProp = 'distance';
        const distance = {
            // TODO - translations
            header: t('DISTANCE & TIRE PRESSURE'),
            values: [
                { value: 'imperial', display: t('Imperial (MI, PSI)') },
                { value: 'metric', display: t('Metric (KM, KPA)') }
            ],
            propName: distanceProp
        };

        const temperatureProp = 'temperature';
        const temperature = {
            // TODO - translations - add temperature units to darksky
            header: t('Temperature'),
            values: [
                { value: 'fahrenheit', display: t('Fahrenheit (F)') },
                { value: 'celcius', display: t('Celcius (C)') }
            ],
            propName: temperatureProp
        };

        const options = [temperature, distance];
        const userPrefs = getLocalStore('userPrefs') || {};
        const last = options.length - 1;
        return (
            <Modal
                {...rest}
                isOpen={isOpen}
                onAfterOpen={this.onAfterOpen}
                onRequestClose={onRequestClose}>
                <div className={style.setttingsContainer}>
                    <h1>{headerText}</h1>
                    <hr />
                    {options.map((option, i) => (
                        <div
                            key={`options-${i}`}
                            className={style.optionsContainer}>
                            <h2>{option.header}</h2>
                            <form>
                                <ul>
                                    {option.values.map((obj, idx) => (
                                        <li key={`op-${i}-${idx}`}>
                                            <label
                                                // className={style.radioLabel}
                                                htmlFor={`option-${i}-${idx}`}
                                                onClick={(e) => {
                                                    this.onClick(
                                                        option.propName,
                                                        obj.value,
                                                        e
                                                    );
                                                }}>
                                                {obj.display}
                                            </label>
                                            <input
                                                // className={style.radioButton}
                                                id={`option-${i}-${idx}`}
                                                name="selector"
                                                type="radio"
                                                value={obj.value}
                                                checked={
                                                    obj.value ===
                                                    userPrefs[option.propName]
                                                }
                                                onChange={() => {}}
                                            />
                                            <span
                                                className={style.check}
                                                htmlFor={`option-${i}-${idx}`}
                                                onClick={(e) => {
                                                    this.onClick(
                                                        option.propName,
                                                        obj.value,
                                                        e
                                                    );
                                                }}
                                            />
                                        </li>
                                    ))}
                                </ul>
                                {last !== i && <hr />}
                            </form>
                        </div>
                    ))}
                </div>
            </Modal>
        );
    }
}

//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default SettingsModal;
