/******************************************************************************\
 * File: EventDetails.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
//------------------------------------------------------------------------------
// Components ------------------------------------------------------------------
import PhoneIcon from '@/components/common/icons/Phone';
import BookmarkIcon from '@/components/common/icons/Bookmark';
// import LinkIcon from '@/components/common/icons/Link';
import DealerIcon from '@/components/common/icons/Dealer';
import { ActionBar, Action } from '@/components/mapListView/sidebar/ActionBar';
import Section from '@/components/mapListView/sidebar/Section';
import Info from '@/components/mapListView/sidebar/Info';
import Inline from '@/components/mapListView/sidebar/Inline';
import { setEventBookmark, isEventBookmarked } from '@/store/eventBookmarks';
import Event from '@/components/common/icons/Event';
import Link from '@/components/common/header/Link';
import Google from '@/components/common/icons/Google';
import Apple from '@/components/common/icons/Apple';
import Microsoft from '@/components/common/icons/Microsoft';
import Yahoo from '@/components/common/icons/Yahoo';
import DarkModal, { LinkWithIcon } from '@/components/common/DarkModal';
import calendar from '@/helpers/calendar';
import SimpleHtmlRenderer from '@/components/common/SimpleHtmlRenderer';
import ArrowRight from '@/components/common/icons/ArrowRight';
import history from '@/helpers/history';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import {
    prettyAddress,
    prettyHourRange,
    formattedDateRange,
    prettyPhone,
    phoneUrl,
    hdEventToCalendarEvent
} from '@/helpers/functions';

import { formatDate, formatHDTimeRange } from '@/helpers/i18n';
import { analyticsWithData } from '@/helpers/analytics';

import { navigateToPoint } from '@/store/edit_ride';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/mapListView/sidebar/EventSidebar.scss';

import { translate } from '@/helpers/i18n';
const t = translate('sidebar.EventDetails');

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class EventDetails extends React.Component {
    static defaultProps = {
        isExpanded: true
    };

    state = {
        showCalendarModal: false
    };

    getCalendarLink = () => {
        const { eventId } = this.props.event || {};
        return eventId
            ? `http://www.harley-davidson.com/eventservices/services/rest/event/calendar/${eventId}.ics`
            : '';
    };

    getEventLink = () => {
        const { eventDetailsUrl: url } = this.props.event || {};
        return url ? `http://www.harley-davidson.com${url}` : '';
    };

    getDealerLink = (dealerId) => {
        return `/map/dealers/${dealerId}?sidebar=1`;
    };

    openCalendarModal = () => {
        this.setState({ showCalendarModal: true });
    };

    closeCalendarModal = () => {
        this.setState({ showCalendarModal: false });
    };

    generateCalendarLinks = () => {
        const event = hdEventToCalendarEvent(this.props.event);
        return [
            {
                icon: Google,
                text: 'Google',
                href: calendar.buildUrl(event, 'google'),
                target: '_blank'
            },
            {
                icon: Microsoft,
                text: 'Outlook.com',
                href: calendar.buildUrl(event, 'outlook.com'),
                target: '_blank'
            },
            {
                icon: Yahoo,
                text: 'Yahoo!',
                href: calendar.buildUrl(event, 'yahoo'),
                target: '_blank'
            },
            {
                icon: Apple,
                text: 'Apple',
                href: calendar.buildUrl(event, 'apple'),
                download: 'download.ics'
            },
            {
                icon: Microsoft,
                text: 'Outlook',
                href: calendar.buildUrl(event, 'outlook'),
                download: 'download.ics'
            }
        ];
    };

    backLink = (onClick, text) => (
        <a onClick={onClick} className={style.arrow}>
            <span>
                <ArrowRight className={style.leftArrow} />
                <span className={style.arrowLink}>{text}</span>
            </span>
        </a>
    );

    render() {
        const {
            event,
            eventBookmark,
            isEventBookmarked,
            setEventBookmark,
            onRequestClose,
            isExpanded,
            navigateToEvent
        } = this.props;

        const { showCalendarModal } = this.state;
        const formattedDate = formattedDateRange(
            event.startDate,
            event.endDate
        );
        const fillColor = '#ff6600';
        const strokeColor = '#ff6600';

        const {
            id,
            //phone,
            startDate,
            endDate,
            timeDescription,
            eventId,
            eventDetailsUrl: url,
            eventName: name,
            eventActivities,
            eventHost: host,
            eventHostDescription: hostDescription,
            eventDescription: description
        } = event || {};

        const eventHostName = !!eventActivities[0].address.name
            ? eventActivities[0].address.name
            : hostDescription;
        const activities = eventActivities || [];
        const dealerId = activities[0].dealerId || '';

        const calendarLink = this.getCalendarLink();

        const { contactName } = activities[0].eventActivityContacts || {};
        let homeMobileDetail =
            activities[0]?.eventActivityContacts[0].eventActivityContactDetails.find(
                (contactDetail) =>
                    contactDetail.contactTypeCode === 'HM' ||
                    contactDetail.contactTypeCode === 'WK' ||
                    contactDetail.contactTypeCode === 'FAX'
            );
        let contactPhone = homeMobileDetail?.contactDetailValue || undefined;
        const eventContactInfo =
            activities[0]?.eventActivityContacts[0]
                ?.eventActivityContactDetails || [];
        const uniqueEventContactDetails = [
            ...new Map(
                eventContactInfo.map((contact) => [
                    contact['contactDetailValue'],
                    contact
                ])
            ).values()
        ];

        const { address, hours: eventHours } = activities[0];

        const locationDetails = () => {
            const locationAddress = prettyAddress(address);

            // Missing description (dealer name) in BE response
            if (address.description)
                return `${address.description}, ${locationAddress}`;

            return locationAddress;
        };

        const isDealer = window.location.search.includes('isDealer');

        const eventDescription = description;

        return (
            <div className={style.EventSidebar}>
                <span 
                // className={style.backNavigation}
                >
                    {isDealer &&
                        this.backLink(() => {
                            history.back();
                        }, t('Back to Dealer'))}
                </span>
                <DarkModal
                    isOpen={showCalendarModal}
                    onRequestClose={this.closeCalendarModal}>
                    {this.generateCalendarLinks().map((link, i) => (
                        <LinkWithIcon {...link} key={i} />
                    ))}
                </DarkModal>

                {/* Event Title and Hours */}
                <Info header={name} shortText={!isExpanded}>
                    {!!startDate && !endDate && (
                        <div>{formatDate(startDate)}</div>
                    )}
                    {!!startDate && !!endDate && (
                        <div>{formattedDateRange(startDate, endDate)}</div>
                    )}
                    {eventHours[0] && (
                        <div>{formatHDTimeRange(eventHours[0])}</div>
                    )}
                </Info>

                {/* Location and dealer info */}
                {activities.length && (
                    <Section title={t('Location')} expanded>
                        <SimpleHtmlRenderer
                            className={style.description}
                            html={locationDetails()}
                        />

                        <br />
                        <br />
                        {dealerId ? (
                            <div
                                onClick={() => {
                                    history.push(this.getDealerLink(dealerId), {
                                        from: `/map/events/${eventId}`,
                                        eventId: eventId
                                    });
                                }}>
                                <Link plain to={this.getDealerLink(dealerId)}>
                                    {t('hostedBy', {
                                        host: eventHostName || '',
                                        t: 'Hosted By: {host}'
                                    })}
                                </Link>
                            </div>
                        ) : (
                            <span>
                                {t('hostedBy', {
                                    host: eventHostName || '',
                                    t: 'Hosted By: {host}'
                                })}
                            </span>
                        )}
                        {(contactName || uniqueEventContactDetails) && (
                            <div>
                                {(!address || {}).description &&
                                    contactName && (
                                        <div 
                                        // className={style.Contact}
                                        >
                                            {contactName}
                                        </div>
                                    )}
                                {(uniqueEventContactDetails || []).map(
                                    (contact) => {
                                        const { contactTypeCode } = contact;
                                        if (
                                            contactTypeCode == 'HM' ||
                                            contactTypeCode == 'WK' ||
                                            contactTypeCode == 'FAX'
                                        ) {
                                            return (
                                                <div>
                                                    <a
                                                        href={phoneUrl(
                                                            contact.contactDetailValue
                                                        )}>
                                                        {prettyPhone(
                                                            contact.contactDetailValue
                                                        )}
                                                    </a>
                                                    <br />
                                                </div>
                                            );
                                        } else if (contactTypeCode == 'WEB') {
                                            return (
                                                <>
                                                    <a
                                                        href={
                                                            contact.contactDetailValue
                                                        }
                                                        target="_blank"
                                                        style={{
                                                            textTransform:
                                                                'lowercase'
                                                        }}>
                                                        {
                                                            contact.contactDetailValue
                                                        }
                                                    </a>
                                                    <br />
                                                </>
                                            );
                                        } else if (contactTypeCode == 'EML') {
                                            return (
                                                <>
                                                    <a
                                                        href={`mailto:${contact.contactDetailValue}`}
                                                        target="_blank"
                                                        style={{
                                                            textTransform:
                                                                'lowercase'
                                                        }}>
                                                        {
                                                            contact.contactDetailValue
                                                        }
                                                    </a>
                                                    <br />
                                                </>
                                            );
                                        }
                                    }
                                )}
                            </div>
                        )}
                    </Section>
                )}

                <ActionBar>
                    <Action
                        text={
                            isEventBookmarked
                                ? t('Unsave Event')
                                : t('Save Event')
                        }
                        icon={BookmarkIcon}
                        className={
                            isEventBookmarked ? { }: style.inactive
                            // isEventBookmarked ? style.active : style.inactive
                        }
                        color={'#FA6600'}
                        active={!!isEventBookmarked}
                        onClick={() => {
                            const onOff = !isEventBookmarked ? 'on' : 'off';
                            setEventBookmark(!isEventBookmarked);
                            analyticsWithData('save event', {
                                value: onOff,
                                id: id,
                                dealerId: dealerId
                            });
                        }}
                    />

                    {uniqueEventContactDetails && (
                        <Action
                            href={phoneUrl(contactPhone)}
                            icon={PhoneIcon}
                            text={t('Contact Event')}
                        />
                    )}

                    <Action
                        text={t('Directions')}
                        onClick={() => {
                            navigateToEvent();
                            onRequestClose && onRequestClose();
                        }}
                        icon={DealerIcon}
                    />

                    {calendarLink && (
                        <Action
                            icon={Event}
                            text={t('Add to Calendar')}
                            onClick={this.openCalendarModal}
                        />
                    )}
                </ActionBar>

                {description && (
                    <Section title={t('Event Description')} expanded>
                        <SimpleHtmlRenderer
                            className={style.description}
                            html={eventDescription}
                        />
                    </Section>
                )}
                <Section title={t('Notice')} expanded>
                    <SimpleHtmlRenderer
                        className={style.description}
                        html={t(
                            'All dates, locations, times and activities are subject to change. Check back here often for the latest information.'
                        )}
                    />
                </Section>
            </div>
        );
    }
}
//------------------------------------------------------------------------------
// Redux State -----------------------------------------------------------------
const mapStateToProps = (state, ownProps) => {
    return {
        isEventBookmarked: isEventBookmarked(ownProps.event.eventId, state)
    };
};

//------------------------------------------------------------------------------
// Redux Actions ---------------------------------------------------------------
const mapDispatchToProps = (dispatch, ownProps) => ({
    setEventBookmark: (value) =>
        dispatch(setEventBookmark(ownProps.event.eventId, value)),
    navigateToEvent: () => dispatch(navigateToPoint(ownProps.event))
});
//------------------------------------------------------------------------------
// Redux Connect ---------------------------------------------------------------
const container = connect(mapStateToProps, mapDispatchToProps)(EventDetails);
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default container;
