/******************************************************************************\
 * File: AvoidOptionsToggle.jsx
 *
 * Author: Gigster
 *
 * Description: Map footer
 *
 * Notes:
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';

//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/mapListView/AvoidOptionsToggle.scss';
//------------------------------------------------------------------------------
// Components ------------------------------------------------------------------
import GearIcon from '@/components/common/icons/Gear';
import Tooltip from '@/components/common/Tooltip';
import Checkbox, { Label } from '@/components/common/form/Checkbox';
import Link from '@/components/common/header/Link';
import { clickAway } from '@/helpers/hoc';
import { analyticsForRideWithData } from '@/helpers/analytics';

import { translate } from '@/helpers/i18n';
const t = translate('mapListView.OptionsToggle');

const Options = ({ title, options }) => (
    <div className={style.OptionsGroup}>
        {title && <h3 className={style.title}>{title}</h3>}
        {options.map((option, i) => {
            return (
                <Label
                    label={option.label}
                    className={style.label}
                    key={`option-${i}`}
                    onChange={option.onChange}
                    checked={option.checked}>
                    <Checkbox
                        id={`${option.label}`.toLowerCase().replace(/\s/g, '-')}
                        data-testid={`${option.label}`
                            .toLowerCase()
                            .replace(/\s/g, '-')}
                        onChange={option.onChange}
                        checked={option.checked}
                        className={style.right}
                        tabIndex="0"
                        onKeyDown={(e) => {
                            // simulate Link enter to click
                            e.key === 'Enter' &&
                                option.onChange(!option.checked);
                        }}
                    />
                </Label>
            );
        })}
    </div>
);

class AvoidOptionsToggle extends React.Component {
    sendAnalytics = () => {
        const { showTooltip, options } = this.props;
        if (showTooltip) {
            const avoidData = options.reduce((acc, o) => {
                acc[o.key] = o.checked;
                return acc;
            }, {});
            analyticsForRideWithData('avoidances', avoidData);
        }
    };

    toggleTooltip = () => {
        const { showTooltip, setShowTooltip } = this.props;
        setShowTooltip(!showTooltip);
        this.sendAnalytics();
    };

    onClickAway = (event) => {
        const { showTooltip, setShowTooltip, options } = this.props;
        this.sendAnalytics();
        if (showTooltip) setShowTooltip(false);
    };

    render() {
        const {
            className,
            tooltipClassName,
            title,
            isCreate,
            displayTitle,
            showTooltip,
            options,
            selected,
            setShowTooltip
        } = this.props;

        const cn = classNames(style.OptionsToggle, {
            [className]: !!className
        });
        const linkStyle = { display: 'inline' };
        if (!isCreate) linkStyle.cursor = 'default';
        return (
            <div className={cn} style={{ background: '#202020' }}>
                {showTooltip && (
                    <Tooltip
                        className={classNames(style.tooltip, tooltipClassName)}
                        style={{ maxHeight: '750px' }}>
                        <div className={style.options}>
                            {options && (
                                <Options title={title} options={options} />
                            )}
                        </div>
                    </Tooltip>
                )}
                <div
                    className={classNames(style.optionsControl, {
                        [style.isPreview]: !isCreate
                    })}>
                    <Link
                        className={style.text}
                        style={linkStyle}
                        id="avoid"
                        data-testid="avoid"
                        onClick={isCreate ? this.toggleTooltip : () => {}}>
                        <span>
                            <GearIcon className={style.gear} />
                            <span className={style.titleSelected}>
                                {t(displayTitle)}:
                                <span className={style.selected}>
                                    {!!selected.length
                                        ? selected.join(', ')
                                        : t('none')}
                                </span>
                            </span>
                        </span>
                    </Link>
                </div>
            </div>
        );
    }
}

//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default clickAway(AvoidOptionsToggle);
