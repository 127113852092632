/******************************************************************************\
* File: ContextMenu.jsx
*
* Author: Gigster
*
* Description: ContextMenu
*
* Notes:
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { clamp } from '@/helpers/functions';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/mapListView/ContextMenu.scss';
//MenuContext
import MenuContext from '@/contexts/MenuContext';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
export class MenuItem extends React.Component {
    render() {
        const { children, item, onClick } = this.props;
        const { onRequestClose } = this.context;
        return (
            <div
                className={style.item}
                onContextMenu={(event) => {
                    event.preventDefault();
                    onClick(item, event);
                    onRequestClose();
                }}
                onClick={(event) => {
                    event.preventDefault();
                    onClick(item, event);
                    onRequestClose();
                }}>
                {children}
            </div>
        );
    }
}
MenuItem.contextType = MenuContext;

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class ContextMenu extends React.Component {
    static defaultProps = {
        style: {},
        onRequestClose: () => {}
    };

    componentDidMount() {
        document.addEventListener('touchstart', this.handleClickOutside);
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('touchstart', this.handleClickOutside);
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        // request close if the user has clicked away
        if (this.$menu && !this.$menu.contains(event.target)) {
            const { onRequestClose } = this.props;
            onRequestClose && onRequestClose(event);
        }
    };

    ensureInBounds = (style) => {
        const { top, left } = style;
        return {
            ...style,
            transform: `
            ${top > window.innerHeight - 200 ? 'translateY(-100%)' : ''}
            ${left > window.innerWidth - 200 ? 'translateX(-100%)' : ''}
            `
        };
    };

    render() {
        const { className, children, isOpen, style: propStyle } = this.props;

        const cn = classNames(style.ContextMenu, {
            [className]: className
        });

        return (
            <MenuContext.Provider
                value={{ onRequestClose: this.props.onRequestClose }}>
                <div
                    className={cn}
                    style={this.ensureInBounds(propStyle)}
                    ref={(el) => (this.$menu = el)}
                    onContextMenu={(event) => event.preventDefault()}>
                    {children}
                </div>
            </MenuContext.Provider>
        );
    }
}
ContextMenu.contextType = MenuContext;
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default ContextMenu;
