// extracted by mini-css-extract-plugin
var _1 = "Select__Select____WKMK";
var _2 = "Select__angle___MpyWd";
var _3 = "Select__dropdown___y_KAA";
var _4 = "Select__item___rJNHE";
var _5 = "Select__label___lZ9pv";
var _6 = "Select__list___T0cqf";
var _7 = "Select__show___OAp2C";
var _8 = "Select__triangle___uqGor";
var _9 = "Select__wiggle___uVq3F";
var _a = "Select__wrapper___pBtNw";
export { _1 as "Select", _2 as "angle", _3 as "dropdown", _4 as "item", _5 as "label", _6 as "list", _7 as "show", _8 as "triangle", _9 as "wiggle", _a as "wrapper" }
