/******************************************************************************\
 * File: SidepanelFilter.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
/******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import { connect } from 'react-redux';
import Sidebar from '@/components/common/Sidebar';
import ArrowRight from '@/components/common/icons/ArrowRight';
import Tooltip from '@/components/common/form/Tooltip';
import classNames from 'classnames';
import { normalizeIds } from '@/helpers/qa';

import {
    checkBoxGroupConfig,
    getRideCheckboxData,
    getRideTypes
} from '@/helpers/checkboxes';

import * as style from '@/style/common/Checkbox.scss';

import { translate } from '@/helpers/i18n';
const t = translate('myAccountView.SavedView');

class SidepanelFilter extends React.Component {
    constructor(props) {
        // Required step: always call the parent class' constructor
        super(props);

        // Set the state directly. Use props if necessary.
        this.state = {
            selectedTypes: this.props.ridesFilters
        };
    }

    render() {
        const {
            selectedNavigation,
            checkboxGroups,
            setShowSidepanelFilter,
            ridesFilters,
            ridesCheckboxCounts,
            groupCheckboxCounts,
            isOpen,
            applyFilters,
            rideTags,
            isMobile,
            poisFiltersCheckboxCounts
        } = this.props;

        const checkboxGroupProp = selectedNavigation.toLowerCase();
        const checkboxGroup = checkboxGroups[checkboxGroupProp];
        const checkboxAvailable =
            !!checkboxGroup && !!Object.entries(checkboxGroup);
        const checkboxGroupEntries = Object.entries(checkboxGroup);

        const rideCheckboxData = getRideCheckboxData(rideTags);

        const rideCheckboxGroups = checkBoxGroupConfig({
            data: rideCheckboxData,
            selectedCheckboxes: [],
            countByType: () => {},
            onClickFunc: () => {},
            // onExpand: this.onExpandCheckBoxGroups,
            onExpand: () => {},
            parentLabel: t('My Rides'),
            groupLabels: [
                {
                    label: t('My Rides'),
                    collection: getRideTypes(rideCheckboxData, 'CUSTOM'),
                    count: (groupCheckboxCounts || {}).CUSTOM,
                    value: 'CUSTOM'
                },
                {
                    label: t('Recommended'),
                    collection: [
                        ...getRideTypes(rideCheckboxData, 'CURATED'),
                        ...getRideTypes(rideCheckboxData, 'HOG_CHAPTER')
                    ],
                    count: (groupCheckboxCounts || {}).CURATED,
                    value: 'CURATED'
                },
                {
                    label: t('Points of interest'),
                    collection: ['events', 'hd-dealer'],
                    count: 0,
                    value: 'POI'
                }
            ]
        });
        const checkboxItemView = (group, item, i, ridesFilters, count) => {
            const option = group.children.find((child) => child.value === item);
            const { selectedTypes } = this.state;
            const cn = classNames(style.checkbox, style.checkboxItemView);

            return (
                <li
                    className={cn}
                    key={`option-${i}-${(option || {}).value}`}
                    data-checked={(selectedTypes || []).includes(
                        (option || {}).value
                    )}
                    onClick={() => {
                        const newTypes = (selectedTypes || []).includes(
                            (option || {}).value
                        )
                            ? (selectedTypes || []).filter((value) => {
                                  if (!Array.isArray(option.group))
                                      return (option || {}).value !== value;
                                  return (
                                      (option || {}).value !== value &&
                                      !option.group.includes(value)
                                  );
                              })
                            : [(option || {}).value || 'ab', ...selectedTypes];
                        this.setState({ selectedTypes: newTypes });
                    }}
                    id={normalizeIds(option.value)}
                    data-testid={normalizeIds(option.value)}
                    style={{ marginTop: '16px', width: '256px' }}>
                    <input
                        value={(option || {}).value}
                        checked={(ridesFilters || []).includes(
                            (option || {}).value
                        )}
                        type="checkbox"
                        onChange={() => {
                            this.setState({
                                selectedTypes: selectedTypes.push(
                                    (option || {}).value
                                )
                            });
                        }}
                        // className={style.hiddenBox}
                    />
                    <label value={(option || {}).value}>
                        {(option || {}).label}
                        <span className={style.count}>
                            {' '}
                            (
                            {count ||
                                (option.customCount &&
                                    this.props.poisFiltersCheckboxCounts[
                                        option.customCount
                                    ]) ||
                                0}
                            )
                        </span>
                    </label>
                </li>
            );
        };

        const isGroupIndeterminate = (selectedTypes, group) => {
            const { collection } = group;
            const length = selectedTypes.filter((val) =>
                collection.includes(val)
            ).length;
            return collection.length !== length && length > 0;
        };

        const isGroupChecked = (selectedTypes, group) => {
            const { collection } = group;
            const length = selectedTypes.filter((val) =>
                collection.includes(val)
            ).length;
            return collection.length === length;
        };

        const onGroupClicked = (selectedTypes, group) => {
            const { collection } = group;
            const selectedLength = collection.filter((item) =>
                selectedTypes.includes(item)
            ).length;
            const newTypes =
                selectedLength === collection.length
                    ? selectedTypes.filter(
                          (value) => !group.collection.includes(value)
                      )
                    : [...new Set([...group.collection, ...selectedTypes])];
            return newTypes;
        };

        const { selectedTypes } = this.state;

        return (
            <Sidebar
                contentLabel={name || 'Harley-Davidson'}
                underNavigation={true}
                style={{ width: '364px' }}
                portalClassName={
                    !isMobile
                        ? 'ReactModalPortal force-open'
                        : 'ReactModalPortal'
                }
                isOpen={!isMobile ? true : isOpen}>
                <div className={style.filterHeader}>
                    <div
                        onClick={setShowSidepanelFilter}
                        className={style.rightArrow}
                        style={{ height: '20px' }}
                        id="sidepanel-back"
                        data-testid="sidepanel-back">
                        <Tooltip value={t('Back')} placement="s" noDelay={true}>
                            <ArrowRight className={style.rightArrow} />
                        </Tooltip>
                    </div>
                    <div
                        onClick={() => applyFilters(selectedTypes)}
                        id="sidepanel-save"
                        style={{ height: '20px' }}
                        data-testid="sidepanel-save">
                        {t('Save')}
                    </div>
                </div>
                {checkboxAvailable &&
                    rideCheckboxGroups.groupLabels.map((group, idx) => {
                        return (
                            <div
                                key={`checkbox-group-${checkboxGroupProp}-${idx}`}
                                style={{ marginTop: '64px' }}>
                                <ul
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}>
                                    <li
                                        className={style.checkbox}
                                        data-checked={isGroupChecked(
                                            selectedTypes,
                                            group
                                        )}
                                        data-indeterminate={isGroupIndeterminate(
                                            selectedTypes || [],
                                            group
                                        )}
                                        id={normalizeIds(group.label)}
                                        data-testid={normalizeIds(group.label)}
                                        onClick={() => {
                                            const newTypes = onGroupClicked(
                                                selectedTypes || [],
                                                group
                                            );
                                            this.setState({
                                                selectedTypes: newTypes
                                            });
                                        }}>
                                        <input
                                            value={group.value}
                                            checked={(
                                                ridesFilters || []
                                            ).includes(group.value)}
                                            type="checkbox"
                                            // className={style.hiddenBox}
                                        />
                                        <label value={group.value}>
                                            {group.label}

                                            {false && group.label && (
                                                <span className={style.count}>
                                                    {' '}
                                                    ({group.count || 0})
                                                </span>
                                            )}
                                        </label>
                                    </li>
                                    <ul className={style.checkboxContainer}>
                                        {group.collection.map((item, i) => {
                                            return checkboxItemView(
                                                rideCheckboxGroups,
                                                item,
                                                idx,
                                                ridesFilters,
                                                (ridesCheckboxCounts || {})[
                                                    item
                                                ] || 0
                                            );
                                        })}
                                    </ul>
                                </ul>
                            </div>
                        );
                    })}
            </Sidebar>
        );
    }
}

//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default SidepanelFilter;
