// extracted by mini-css-extract-plugin
var _1 = "DescriptionSidebar__Description___bDPh4";
var _2 = "DescriptionSidebar__Sidebar___B4agm";
var _3 = "DescriptionSidebar__arrow___v5qNA";
var _4 = "DescriptionSidebar__back___UacXM";
var _5 = "DescriptionSidebar__backContainer___JVqtH";
var _6 = "DescriptionSidebar__children___qM_eJ";
var _7 = "DescriptionSidebar__description___hzaeF";
var _8 = "DescriptionSidebar__wiggle___j0jji";
export { _1 as "Description", _2 as "Sidebar", _3 as "arrow", _4 as "back", _5 as "backContainer", _6 as "children", _7 as "description", _8 as "wiggle" }
