// extracted by mini-css-extract-plugin
var _1 = "Toggle__checked___GXrwE";
var _2 = "Toggle__container___zXHK9";
var _3 = "Toggle__displayFlex___rogZY";
var _4 = "Toggle__icon___ggOWD";
var _5 = "Toggle__longText___ga_KO";
var _6 = "Toggle__narrow___UufAs";
var _7 = "Toggle__paddingRight___ZlBfz";
var _8 = "Toggle__text____qgi4";
var _9 = "Toggle__toggle___YwyUd";
var _a = "Toggle__wiggle___d29_4";
export { _1 as "checked", _2 as "container", _3 as "displayFlex", _4 as "icon", _5 as "longText", _6 as "narrow", _7 as "paddingRight", _8 as "text", _9 as "toggle", _a as "wiggle" }
