/******************************************************************************\
 * File: Header.jsx
 *
 * Author: Gigster
 *
 * Description: App header
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/common/header/Header.scss';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------
import Logo from '@/components/common/header/Logo';
import HeaderLinks from '@/components/common/header/HeaderLinks';
import MenuToggle from '@/components/common/header/MenuToggle';
import { translate } from '@/helpers/i18n';
//App context
import AppContext from '@/contexts/AppContext';
import App from '../../App';
const t = translate('header.Header');
//------------------------------------------------------------------------------
// React Function --------------------------------------------------------------
const Header = (props) => {
    const {
        className,
        showSearch,
        transparent,
        background,
        openSettingsModal
    } = props;

    const { isMobile } = useContext(AppContext);

    const cn = classNames(style.Header, {
        [className]: className,
        [style['transparent']]: transparent,
        [style['background']]: background
    });

    return (
        <div className={style.outer}>
            <div className={cn}>
                <div className={style.left}>
                    <div className={style.logoGroup}>
                        <Logo
                            className={style.logo}
                            tabIndex={0}
                            id="harley-logo"
                            data-testid="harley-logo"
                        />
                        {!isMobile && (
                            <h1 className={style.logoText}>
                                {t('Ride Planner')}
                            </h1>
                        )}
                    </div>
                </div>
                {(!isMobile && (
                    <div className={style.right}>
                        <HeaderLinks
                            className={style.links}
                            descriptor={{
                                userLink: true,
                                inlineUser: false,
                                responsiveText: true,
                                newRideLink: !isMobile,
                                savedRidesLink: !isMobile,
                                mapLink: !isMobile,
                                showIcons: !isMobile,
                                openSettingsModal: openSettingsModal,
                                hdLink: true,
                                hdInline: true
                            }}
                            openSettingsModal={openSettingsModal}
                        />
                    </div>
                )) || (
                    <div className={style.right}>
                        <MenuToggle dark={showSearch} />
                    </div>
                )}
            </div>
        </div>
    );
};

//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Header;
