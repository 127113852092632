/******************************************************************************\
 * File: PrintView.jsx
 *
 * Author: Gigster
 *
 * Description: Modal that displays the print preview, covers app entirely.
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import ReactModal from 'react-modal';
import classNames from 'classnames';
import { connect } from 'react-redux';
import withRouter from '@/helpers/hooks';
import * as ridesActions from '@/store/rides';
import * as mapActions from '@/store/map';
if (process.env.NODE_ENV !== 'test') ReactModal.setAppElement('#app');
//------------------------------------------------------------------------------
// Components ------------------------------------------------------------------
//------------------------------------------------------------------------------
import Map from '@/components/common/map/here/Map';
import RenderRide from '@/components/mapListView/RenderRide';
import Checkbox, { Label } from '@/components/common/form/Checkbox';
import DirectionsList from '@/components/mapListView/directions/DirectionsList';
// Helpers ---------------------------------------------------------------------
import { prettyTime, prettyMiles } from '@/helpers/functions';
import { centerZoomForPoints } from '@/helpers/map';
import { getSiteName } from '@/helpers/constants';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/mapListView/directions/PrintView.scss';
import { translate } from '@/helpers/i18n';
const t = translate('directions.PrintView');
//------------------------------------------------------------------------------
// Helper Class ----------------------------------------------------------------

/** Button. */
const Button = ({ text, primary, onClick }) => (
    <div
        className={classNames(style.Button, { [style['primary']]: primary })}
        onClick={onClick}>
        {text || children}
    </div>
);
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class PrintView extends React.Component {
    state = {
        showImages: true,
        showDirections: true,
        map: null,
        center: false,
        styleToHideOrShowRide: style.hidePrint
    };

    setImages = (showImages) => this.setState({ showImages });

    setDirections = (showDirections) => this.setState({ showDirections });

    onAfterOpen = () => {
        this.props.onAfterOpen && this.props.onAfterOpen();
        const { map, printZoom } = this.props;
        map.zoom = printZoom;
        if (map) this.setState({ map });
    };

    componentDidMount() {
        console.log('componentDidMount() lifecycle');
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { ride } = prevProps;
        const { ride: nextRide } = this.props;

        if (!(ride || {}).points && (nextRide || {}).points) {
            this.resizeToRide(nextRide);
        }
    };
    componentWillUnmount = () => {
        this.props.setPrint(false);
        this.props.onZoom(1);
    };

    bindMapRef = (ref) => {
        this.$map = ref;
    };

    bindPrintRef = (ref) => {
        this.$printMap = ref;
    };

    resizeToRide(ride) {
        const { map } = this.props;
        const points = ride.points || ride.waypoints || [];
        const [mapCanvas, printCanvas, ...rest] =
            document.getElementsByTagName('canvas');
        if (printCanvas && points.length) {
            const {
                style: { width, height }
            } = printCanvas;

            this.setState({
                map: {
                    ...map,
                    ...centerZoomForPoints(points, { width, height })
                }
            });

            return true;
        }

        return false;
    }

    update = (key) => (val) => {
        this.setState({ map: { ...this.state.map, [key]: val } });
    };

    printRidePreview = () => {
        this.setState({ styleToHideOrShowRide: style.printOnly });
        setTimeout(function () {
            window.print();
        }, 500);
    };

    render() {
        const { className, ride, ...props } = this.props;
        const { showImages, showDirections, map, styleToHideOrShowRide } =
            this.state;
        const directions = ride.legs || [];
        const { waypoints } = ride || {};

        const hasRide = (ride.points || []).length > 0;

        const cn = classNames(style.content, {
            className: !!className
        });

        window.addEventListener('afterprint', (event) => {
            this.setState({ styleToHideOrShowRide: style.hidePrint });
            //this.setState({ map: {...this.state.map} });
        });

        return (
            <ReactModal
                portalClassName="ReactModalPortal force-open"
                overlayClassName={style.overlay}
                className={cn}
                closeTimeoutMS={0}
                contentLabel={t('title', {
                    name: ride.name,
                    t: 'Print {name}'
                })}
                {...props}
                onAfterOpen={this.onAfterOpen}>
                {/* Hide everything else when this component is visible: */}
                <style
                    dangerouslySetInnerHTML={{
                        __html: `@media print { #app, .ReactModalPortal:not(.force-open) { display: none; } }`
                    }}
                />
                <div className={style.PrintView}>
                    <div className={style.Header}>
                        <Label
                            label={t('Include driving instructions')}
                            // className={style.checkbox}
                            >
                            <Checkbox
                                dark
                                onChange={this.setDirections}
                                checked={showDirections}
                            />
                        </Label>
                        <Label
                            label={t('Include images')}
                            // className={style.checkbox}
                            >
                            <Checkbox
                                dark
                                onChange={this.setImages}
                                checked={showImages}
                            />
                        </Label>
                        <Button
                            text={t('Close')}
                            onClick={props.onRequestClose}
                        />
                        <Button
                            primary
                            text={t('Print')}
                            onClick={this.printRidePreview}
                        />
                    </div>

                    <div className={style.body}>
                        <div className={style.top}>
                            <h1 className={style.title}>
                                {ride.name || getSiteName()}
                            </h1>
                            {!!ride.length && !!ride.duration && (
                                <p className={style.stats}>
                                    {prettyMiles(ride.length)} /{' '}
                                    {prettyTime(ride.duration)}
                                </p>
                            )}
                            {!!ride.description && (
                                <p className={style.description}>
                                    {ride.description}
                                </p>
                            )}
                        </div>

                        {/* Responsive print preview (not shown during print): */}
                        {showImages && (
                            <Map
                                className={styleToHideOrShowRide}
                                ref={this.bindMapRef}
                                inline
                                tall={!hasRide}
                                map={map || props.map}
                                update={this.update}
                                wheelZoomEnabled={!hasRide}
                                showUiControls
                                onCenterLocation={this.onAfterOpen}
                                onZoom={(amount) =>
                                    this.update('zoom')(
                                        this.state.map.zoom + amount
                                    )
                                }>
                                <RenderRide ride={ride} />
                            </Map>
                        )}

                        {/* Only visible while printing (sized to A4 page): */}
                        {/*zoomChangedToPrint && showImages && (
                            <Map
                                className={style.printOnly}
                                ref={this.bindMapRef}
                                map={!!map ? map : props.map}
                                update={() => () => {}}
                                key={map}
                            >
                                <RenderRide ride={ride} />
                            </Map>
                        )*/}

                        {showDirections && (
                            <DirectionsList
                                className={style.directions}
                                directions={directions}
                                rideDuration={ride.duration}
                                rideLength={ride.length}
                                waypoints={waypoints}
                                print
                            />
                        )}

                        <div className={style.Footer}>
                            {showImages ? (
                                <img width="80" src="/img/hd-logo.png" />
                            ) : (
                                <span>
                                    {t('Harley-Davidson Motor Company')}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            </ReactModal>
        );
    }
}

//------------------------------------------------------------------------------
// Redux -----------------------------------------------------------------------
const mapStateToProps = (state) => {
    return {
        printZoom: state.map.zoom - 1,
        prevZoom: state.map.zoom,
        map: state.map,
        ride: ridesActions.getCurrentRide(state)
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setPrint: (value) => dispatch(mapActions.setPrint(value))
    };
};

const container = connect(mapStateToProps, mapDispatchToProps)(PrintView);
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withRouter(container);
