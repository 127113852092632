/******************************************************************************\
 * File: imageView.jsx
 *
 * Author: Gigster
 *
 * Description: Modal that displays the print preview, covers app entirely.
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import ReactModal from 'react-modal';
import classNames from 'classnames';
import { connect } from 'react-redux';
if (process.env.NODE_ENV !== 'test') ReactModal.setAppElement('#app');
//------------------------------------------------------------------------------
// Components ------------------------------------------------------------------
import ImageCarousel from '@/components/mapListView/directions/ImageCarousel';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/mapListView/directions/ImageView.scss';
import { translate } from '@/helpers/i18n';
const t = translate('directions.printView');
//------------------------------------------------------------------------------
// Helper Class ----------------------------------------------------------------

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class ImageView extends React.Component {
    update = (key) => (val) => {
        this.setState({ map: { ...this.state.map, [key]: val } });
    };

    render() {
        const {
            className,
            images,
            setShowImagesSidebar,
            setImages,
            openSidebar,
            setImageIndex,
            imageIndex,
            large,
            showImages,
            ...props
        } = this.props;

        const cn = classNames(style.content, {
            className: !!className
        });

        return (
            <ReactModal
                portalClassName="ReactModalPortal force-open"
                overlayClassName={style.overlay}
                className={cn}
                closeTimeoutMS={0}
                isOpen={showImages}
                {...props}>
                <div className={style.ImageView}>
                    {images && images.length > 0 && (
                        <ImageCarousel
                            images={images}
                            setShowImagesSidebar={setShowImagesSidebar}
                            setImages={setImages}
                            setImageIndex={setImageIndex}
                            imageIndex={imageIndex}
                            slidesPerView={1}
                            large={large}
                            tall={true}
                        />
                    )}
                </div>
            </ReactModal>
        );
    }
}

//------------------------------------------------------------------------------
// Redux -----------------------------------------------------------------------
const mapStateToProps = (state) => {
    return {
        imageIndex: state.map.imageIndex,
        showImages: state.map.showImages
    };
};

const container = connect(mapStateToProps)(ImageView);
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default container;
