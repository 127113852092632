/******************************************************************************\
* File: Yahoo.jsx
*
* Author: Gigster
*
* Description: 
*
* Notes: 
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/common/icons/Icon.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Yahoo extends React.Component {
    render() {
        const { className, onClick } = this.props;

        const cn = classNames(style.Icon, {
            [style['clickable']]: onClick,
            [className]: !!className
        });

        return (
            <svg className={cn} viewBox="0 0 1792 1792" onClick={onClick}>
                <path
                    d="M987 957l13 707q-62-11-105-11-41 0-105 11l13-707q-40-69-168.5-295.5t-216.5-374.5-181-287q58 15 108 15 44 0 111-15 63 111 133.5 229.5t167 276.5 138.5 227q37-61 109.5-177.5t117.5-190 105-176 107-189.5q54 14 107 14 56 0 114-14-28 39-60 88.5t-49.5 78.5-56.5 96-49 84q-146 248-353 610z"
                    fill="#fff"
                />
            </svg>
        );
    }
}
export default Yahoo;
