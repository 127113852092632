import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isBetween from 'dayjs/plugin/isBetween';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { ScreenSizes, LOCAL_STORAGE_CACHE_KEY } from '@/helpers/constants';
import features from '@/helpers/features';
import { Routes } from '@/shared/routing';
import { compressToUTF16, decompressFromUTF16 } from 'async-lz-string';

import { createLogger } from '@/helpers/debug';
import {
    translate,
    formatPhone,
    formatPhoneUrl,
    formatTime,
    formatDistance,
    formatSpeed
} from '@/helpers/i18n';

const t = translate('helpers.functions');
const log = createLogger('functions', true);

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween);
/** Clamps val between [min, max] inclusive. */
export const clamp = (
    val,
    lower = -Number.MAX_VALUE,
    upper = Number.MAX_VALUE
) => Math.max(lower, Math.min(upper, val));

/** Curries a funciton to add caching. */
export function memoize(func, keyFn = (args) => JSON.stringify(args)) {
    const memo = {};

    return function () {
        const key = keyFn(arguments);
        return key in memo
            ? memo[key]
            : (memo[key] = func.apply(this, arguments));
    };
}

/**
 * Converts 6 digit hex or rgb(a) colors to an array of the 3 color components.
 */
export const colorComponents = (hexOrRgb) => {
    if ((hexOrRgb || '').indexOf('rgb') === 0)
        return hexOrRgb.match(/\d+/g).map((c) => parseInt(c, 10));

    if ((hexOrRgb || '').indexOf('#') === 0)
        return [
            parseInt(hexOrRgb.substr(1, 2), 16) || 0,
            parseInt(hexOrRgb.substr(3, 2), 16) || 0,
            parseInt(hexOrRgb.substr(5, 2), 16) || 0
        ];

    return [0, 0, 0];
};

export const shortenHtmlString = (
    str,
    maxLen,
    separator = ' ',
    suffix = '...'
) => {
    if (str.length <= maxLen) return str;
    const htmlStrSplit = str.split(/<br?\/>/);
    const htmlStrAfterSplit = htmlStrSplit.slice(0, 2).join('<br />');
    return `${htmlStrAfterSplit.substr(
        0,
        str.lastIndexOf(separator, maxLen)
    )}${suffix}`;
};

export const shortenString = (str, maxLen, separator = ' ', suffix = '...') => {
    if (str.length <= maxLen) return str;
    return `${str.substr(0, str.lastIndexOf(separator, maxLen))}${suffix}`;
};

export const capitalize = (str) =>
    (str || '').charAt(0).toUpperCase() + str.slice(1).toLowerCase();

const MINUTES_IN_DAY = 1440;
const HOURS_IN_DAY = 24;
const MINUTES_IN_HOUR = 60;

/**
 * Does some magic.
 * Assumes each time block is separated by a vertical pipe | character.
 * e.g. "{days} d|{hours} hr|{minutes} min"
 */
const fmtTime = (time = {}) =>
    t('time', {
        days: '$null',
        hours: '$null',
        minutes: '$null',
        t: '{days} d|{hours} hr|{minutes} min',
        ...time
    })
        .split('|')
        .filter((str) => !str.includes('$null'))
        .join(' ');

export const prettyTime = (totalMinutes, neverZero = true) => {
    const days = Math.floor(totalMinutes / MINUTES_IN_DAY);
    const hours =
        Math.floor(totalMinutes / MINUTES_IN_HOUR) - days * HOURS_IN_DAY;
    let minutes = totalMinutes % MINUTES_IN_HOUR;

    if (neverZero && !days && !hours && minutes < 1) {
        minutes = 1;
    }

    if (totalMinutes === 0) {
        return fmtTime({ hours: 0, minutes: 0 });
    }

    return fmtTime({
        days: ~~days > 0 ? ~~days : '$null',
        hours: ~~hours > 0 ? ~~hours : '$null',
        minutes: ~~minutes > 0 ? ~~minutes : '$null'
    });
};

export const prettyMiles = formatDistance;

const MILES_IN_METER = 0.000621371192;
export const metersToMiles = (meters) => meters * MILES_IN_METER;

export const secondsToMinutes = (seconds) => seconds / 60;

export const prettySeconds = (seconds) => prettyTime(secondsToMinutes(seconds));

export const prettyMeters = (meters) => prettyMiles(metersToMiles(meters));

export const prettyMPH = formatSpeed;

export const prettyPhone = formatPhone;
export const phoneUrl = formatPhoneUrl;

export const prettyHour = ({ hour, minute }) =>
    formatTime(`${hour}:${minute}`, true);

export const prettyHourRange = ({ open, close }, delim = '-') => {
    if (!open) return '';
    // Pending to add a regex to validate the date string if it's an ISO format or hour:minutes am format
    if (open.length > 10) {
        return (
            formatTime(open, true) +
            (close ? ` ${delim} ${formatTime(close, true)}` : '')
        );
    }
    return prettyHour(open) + (close ? ` ${delim} ${prettyHour(close)}` : '');
};

export const ocpiPrettyHour = (time) => {
    return formatTime(`${time}`);
};

export const ocpiPrettyHourRange = (
    { periodBegin, periodEnd },
    delim = '-'
) => {
    if (!periodBegin) return '';
    return (
        ocpiPrettyHour(periodBegin) +
        (periodEnd ? ` ${delim} ${ocpiPrettyHour(periodEnd)}` : '')
    );
};

export const displayRowDate = (yyyyMMDD) => {
    const date = dayjs(yyyyMMDD);
    return [date.format(`MMM`), date.format(`D`)];
};

const notEmpty = (item) => ![undefined, null, ''].includes(item);

const shouldUseDaynameDayMonth = () => {
    const regex = /\w\w-/;
    const locale = features.localStorage ? localStorage.locale : null;
    const dateLocale = notEmpty(locale)
        ? locale
        : 'United States – English//en-US';
    const lang = dateLocale.match(regex)[0];
    const daynameDayMonthArr = ['fr-', 'de-', 'cs-'];
    return daynameDayMonthArr.includes(lang);
};
export const prettyDate = (yyyyMMDD, opts = {}) => {
    // Allow options to override showYear. Default: only show when not current year
    const defaultOpts = {};
    opts = { ...defaultOpts, ...opts };

    const date = dayjs(yyyyMMDD);

    // Allow options to override showYear. Default: only show when not current year
    const showYear =
        opts.showYear !== undefined
            ? opts.showYear
            : dayjs().year() !== date.year();

    const dateFormat = shouldUseDaynameDayMonth()
        ? `ddd, D MMM ${showYear ? ', YYYY' : ''}`
        : `ddd, MMM D${showYear ? ', YYYY' : ''}`;

    return date.format(dateFormat);
};

export const prettyAddress = ({
    address1,
    city,
    stateCode,
    countryCode,
    postalCode
}) => {
    const maybe = (val, delim = '') => (val ? `${val}${delim}` : '');
    return `${maybe(address1, ',')} ${maybe(city, ', ')}${maybe(
        stateCode,
        ', '
    )}${maybe(postalCode, ' ')}${maybe(countryCode, ' ')}`.trim();
};

export const usCityStateZip = ({
    city,
    stateCode,
    countryCode,
    postalCode
}) => {
    const maybe = (val, delim = '') => (val ? `${val}${delim}` : '');
    return `${maybe(city, ', ')}${maybe(stateCode, ', ')}${maybe(
        postalCode,
        ' '
    )}${maybe(countryCode)}`.trim();
};

export const prettyAddressSplit = (
    { address1, city, stateCode, countryCode, postalCode },
    isInfoBubbleDealer
) => {
    const country = isInfoBubbleDealer ? '' : countryCode;
    const zipCode = isInfoBubbleDealer ? postalCode.split('-')[0] : postalCode;
    const maybe = (val, delim = '') => (val ? `${val}${delim}` : '');
    const address = maybe(address1, ',');
    const allTheRest = `${maybe(city, ', ')}${maybe(stateCode, ', ')}${maybe(
        zipCode,
        ' '
    )}${maybe(country, ' ')}`.trim();

    return [address, allTheRest];
};

export const rideDetails = (ride) =>
    `${prettyTime(ride.duration)} • ${prettyMiles(ride.length)}`;

export const rideAverageSpeed = (ride) => {
    const { locationHistory } = ride;

    if (locationHistory) {
        const filtered = locationHistory.filter(
            (e) => typeof e.speed === 'number'
        );
        const speed =
            filtered.reduce((speed, e) => speed + e.speed, 0) / filtered.length;
        const MAX_RIDE_SPEED = 10000;
        return speed <= MAX_RIDE_SPEED
            ? speed
            : (ride.length / ride.duration) * 60;
    }

    return ride.duration > 0 ? ride.length / (ride.duration * 60) : 0;
};

export const latLngEqual = (o1, o2) => {
    if (!o1 || !o2) {
        return false;
    }
    return o1.lat === o2.lat && o1.lng === o2.lng;
};

export const fuzzyEqual = (a, b, epsilon = 0) => Math.abs(a - b) < epsilon;

export const latLngFuzzyEqual = (o1, o2) => {
    if (!o1 || !o2) {
        return false;
    }
    return fuzzyEqual(o1.lat, o2.lat) && fuzzyEqual(o1.lng, o2.lng);
};

export const normalizeLatLng = (point) => {
    if (!point && normalizeLatLng.caller)
        log('Invalid point - normalizeLatLng', point, normalizeLatLng.caller);
    return {
        lat: [point.lat, point.latitude, point.Latitude].find(
            (p) => typeof p === 'number'
        ),
        lng: [point.lng, point.lon, point.longitude, point.Longitude].find(
            (p) => typeof p === 'number'
        )
    };
};

export const latLngValid = (point) => {
    const p = normalizeLatLng(point);
    return (
        typeof p.lat === 'number' &&
        typeof p.lng === 'number' &&
        p.lat >= -90 &&
        p.lat <= 90 &&
        p.lng >= -180 &&
        p.lng <= 180
    );
};

export const testSupportsPassive = () => {
    try {
        let supportsPassive = true;
        const opts = Object.defineProperty({}, 'passive', {
            get: () => supportsPassive
        });
        window.addEventListener('testPassive', null, opts);
        window.removeEventListener('testPassive', null, opts);
        return true;
    } catch (e) {
        return;
    }
};

/** Given a string returns a valid LatLng object or false. */
export const parseLatLng = (str) => {
    const parts = (str || '').split(',').map((str) => str.trim());

    const lat = parseFloat(parts[0] || '', 10);
    const lng = parseFloat(parts[1] || '', 10);

    const result = { lat, lng };
    return latLngValid(result) && result;
};

export const normalizeBounds = (bounds) => {
    if (typeof (bounds || {}).getTopLeft === 'function') {
        return {
            ne: { lat: bounds.getTop(), lng: bounds.getRight() },
            sw: { lat: bounds.getBottom(), lng: bounds.getLeft() }
        };
    }

    if ((bounds || {}).ne) {
        return bounds;
    }

    return {
        ne: { lat: 0, lng: 0 },
        sw: { lat: 0, lng: 0 }
    };
};

export const toHereBounds = (bounds) => {
    return new H.geo.Rect(
        bounds.ne.lat,
        bounds.sw.lng,
        bounds.sw.lat,
        bounds.ne.lng
    );
};

export const boundsValid = (bounds) =>
    latLngValid(bounds.ne || {}) && latLngValid(bounds.sw || {});

export const convertHereBounds = (bounds) => {
    const TopLeft = { Latitude: bounds.south, Longitude: bounds.west } || {};
    const BottomRight =
        { Latitude: bounds.north, Longitude: bounds.east } || {};

    return normalizeBounds({
        ne: normalizeLatLng(TopLeft || {}),
        sw: normalizeLatLng(BottomRight || {})
    });
};

export const shallowEqual = (o1, o2) => {
    const keys1 = Object.keys(o1);
    return (
        o1 === o2 ||
        (keys1.length === Object.keys(o2).length &&
            keys1.every((key) => o1[key] === o2[key]))
    );
};

/** Returns a copy of obj with only keys. */
export const pick = (obj, keys) =>
    keys
        .map((key) => [key, obj[key]])
        .reduce((memo, e) => {
            memo[e[0]] = e[1];
            return memo;
        }, {});

/** Shallow object diff, favors o2 on mismatch. */
export const diff = (o1, o2) =>
    Object.keys(o2).reduce((diff, key) => {
        if (!(key in o2)) diff[key] = o2[key];
        if (o1[key] !== o2[key]) diff[key] = o2[key];
        return diff;
    }, {});

/** Modulo with negative numbers. */
export const mod = (a, b) => ((a % b) + b) % b;

/** Makes `n` approach `to` in increments of `by` */
export const move = (n, to, by = 1) =>
    n < to ? Math.min(n + by, to) : Math.max(n - by, to);

/** Ensures that a is between [-b, b]. */
export const limit = (a, b) => (a > b || a < -b ? Math.sign(a) * b : a);

/** Removes html tags from the input string. */
export const stripTags = (str) => (str || '').replace(/(<([^>]+)>)/gi, '');
export const replaceTags = (str, rep = '') =>
    (str || '').replace(/(<([^>]+)>)/gi, rep);

export const stripParens = (str) => (str || '').replace(/\(.*\)/g, '');

/** Removes all non-numeric characters from str. */
export const numbersOnly = (str) => (str || '').replace(/[^\d]/g, '');

/** Converts a string into a url-friendly slug. */
export const slugify = (str) =>
    (str || '')
        .toString()
        .toLowerCase()
        .replace(/\s+/g, '-')
        .replace(/[^\w-]+/g, '')
        .replace(/--+/g, '-')
        .replace(/^-+/, '')
        .replace(/-+$/, '');

export const stripTrailingSlash = (path) =>
    path.slice(-1) === '/' ? path.slice(0, -1) : path;

const transformLocationCallback = (callback) => (position) =>
    callback(
        position
            ? {
                  latLng: {
                      lat: position.coords.latitude,
                      lng: position.coords.longitude
                  },
                  accuracy: 2
              }
            : {
                  latLng: null,
                  accuracy: 2
              }
    );
export const setupGeolocation = (locationCallback, gigya) => {
    try {
        const locationInfo = {
            latLng: {
                lat: gigya.geoLatitude,
                lng: gigya.geoLongitude
            }
        };
        locationCallback({ locationInfo });
    } catch {
        return;
    }

    if ('geolocation' in navigator) {
        // Watch for changes to position with high accuracy
        return navigator.geolocation.watchPosition(
            transformLocationCallback(locationCallback),
            () => {
                transformLocationCallback(locationCallback)();
            },
            { enableHighAccuracy: true }
        );
    }
};

export const cleanupGeolocation = (watchGeoId) => {
    if (watchGeoId !== undefined) {
        navigator.geolocation.clearWatch(watchGeoId);
    }
};

export const __ = (...props) => {
    if (props.length < 2) return props[0];
    return __((props[0] || {})[props[1]], ...props.slice(2));
};

/** Source: https://davidwalsh.name/javascript-debounce-function */
export function debounce(func, wait, immediate) {
    let timeout;
    return function () {
        let context = this,
            args = arguments;
        let later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        let callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

export const convertToLatLng = ({ lat, latitude, lon, longitude, lng }) => {
    const long = lon || lng || longitude;
    const lati = lat || latitude;
    return { lat: lati, lng: long, lon: long };
};

export const debounceAction =
    (func, wait, immediate) =>
    (...args) =>
        debounce((dispatch) => dispatch(func(...args)), wait, immediate);

export const placeholderForCount = (count) =>
    count === 0 ? t('Add start location') : t('Add location');

/** Truncates n and keeps up to prec decimal digits. */
export const trunc = (n, prec = 5) =>
    Math.round(n * Math.pow(10, prec)) / Math.pow(10, prec);

/** Waypoint name is a computed prop. */
export const waypointName = (waypoint) => {
    const { name, label, address, lat, lng, addressComponents } = waypoint;
    return (
        (addressComponents || {}).label ||
        name ||
        address ||
        label ||
        `${trunc(lat)}, ${trunc(lng)}`
    );
};

export const unique = (arr) => {
    return Object.keys(arr.reduce((acc, val) => ({ ...acc, [val]: true }), {}));
};

export const arrDelete = (arr, el) => {
    let index;

    if ((index = arr.findIndex((x) => x === el)) !== -1) {
        return [...arr.slice(0, index), ...arr.slice(index + 1)];
    }

    return arr;
};

export const arrSub = (a, b) => {
    return a.filter((a_el) => !b.some((b_el) => a_el === b_el));
};

export const datetimeToDayjs = (datetime) => {
    const now = typeof datetime === 'string' ? dayjs(datetime) : dayjs();
    // Default to now
    const adjustedDatetime = {
        date:
            datetime.date !== undefined
                ? datetime.date
                : dayjs(now).startOf('day'),
        time: datetime.time !== undefined ? datetime.time : now.hour()
    };
    return dayjs(adjustedDatetime.date).hour(adjustedDatetime.time);
};

const timeToDayjs = (base, time) => {
    return dayjs(base)
        .set('hour', time.hour)
        .set('minute', time.minute)
        .set('second', time.second);
};

const dayToRange = (base, day) => {
    return {
        open: (day || {}).open && timeToDayjs(base, (day || {}).open),
        close: (day || {}).close && timeToDayjs(base, (day || {}).close)
    };
};

export const getOpenState = (dealerHours, timeZone) => {
    if (!timeZone || !dealerHours || !dealerHours.length) return '';

    const timeZoneName = timeZone.timeZoneName;

    const adjustedNow = dayjs().tz(timeZoneName);
    const nowDayNum = adjustedNow.day();
    const dealerDay = dealerHours.find((day) => day.dayNum === nowDayNum);
    const isObject = (val) => typeof val === 'object';
    if (!isObject(adjustedNow) || !isObject(dealerDay)) return '';

    const dealerDayRange = dayToRange(adjustedNow, dealerDay);

    if (adjustedNow.isBetween(dealerDayRange.open, dealerDayRange.close)) {
        if (dealerDayRange.close.diff(adjustedNow, 'minute') < 60) {
            return t('Closing Soon');
        } else {
            return t('Open Now');
        }
    } else {
        return t('Closed');
    }
};

export const once = (fn) => {
    let done = false;

    return function () {
        return done ? void 0 : ((done = true), fn.apply(this, arguments));
    };
};

export const dedupe = (collection, prop) => {
    const seen = {};
    return (collection || []).filter((item) => {
        const key = typeof prop === 'function' ? prop(item) : item[prop];
        if (seen[key]) return false;
        seen[key] = true;
        return true;
    });
};

export const isChildOf = (node, fn) => {
    while ((node = node.parentNode)) {
        if (fn(node)) return true;
    }
    return false;
};

export const adjustRadius = (radius) => {
    // radius is set on state.map using bounds
    // use window width / height to box request radius
    // to the smaller of the two
    const { innerWidth, innerHeight } = window;
    const [smaller, larger] = [innerHeight, innerWidth].sort((a, b) => a - b);
    const radiusPercentage = smaller / larger;
    return radius * radiusPercentage;
};

export const responsiveToggle = (
    screenSize,
    fallback,
    mobile,
    tablet,
    desktop
) => {
    return screenSize === ScreenSizes.MOBILE
        ? mobile
        : screenSize === ScreenSizes.TABLET
          ? tablet
          : screenSize === ScreenSizes.DESKTOP
            ? desktop
            : fallback;
};

export const dateRangeStr = (startDate, endDate, opts = {}) => {
    const defaultOpts = { delim: '-', date: {} };
    opts = { ...defaultOpts, ...opts };
    const startStr = prettyDate(startDate, opts);
    const endStr = prettyDate(endDate, opts);

    return `${startStr}${
        endStr && startStr !== endStr ? ` ${opts.delim} ${endStr}` : ''
    }`;
};

export const dateOptsShowYear = (
    dateAsString,
    showYear,
    opts = { date: {} }
) => {
    const today = dayjs();
    let newOpts = opts.date ? opts : { date: {} }; // Create a new opts object if necessary
    // Check if the year of dateAsString matches the current year and if opts.date is empty
    if (
        dateAsString.substring(0, 4) === `${today.year()}` &&
        !showYear &&
        (!opts.date || Object.keys(opts.date).length === 0)
    )
        return {};
    newOpts.showYear = showYear;
    return newOpts;
};

export const formattedDateRangeOrDaysLeft = (startDate, endDate) => {
    const today = dayjs();
    const start = dayjs(startDate.split('T')[0]);
    const end = dayjs(endDate.split('T')[0]);
    const daysLeft = end.diff(today, 'days');
    if (today.diff(start, 'days') > -1 && daysLeft < 30) {
        if (daysLeft > 1)
            return t('daysLeft', {
                count: daysLeft,
                t: '{count} days left'
            });
        if (daysLeft === 1) return t('1 day left');
        if (daysLeft === 0) return t('Final day');
    }
    if (daysLeft < 0) {
        const endDateNoTZ = endDate.split('T')[0];
        return dayjs(endDateNoTZ).format('MMM YYYY');
    }
    return formattedDateRange(startDate.split('T')[0], endDate.split('T')[0]);
};

export const displayCountWithString = (count, single, plural) => {
    const string = count > 1 || count === 0 ? plural : single;
    return t(string, {
        count: count,
        t: `{count} ${string}`
    });
};

export const formattedDateRange = (startDate, endDate) => {
    const newStartDate = dayjs.utc(startDate).tz('UTC');
    const newEndDate = dayjs.utc(endDate).tz('UTC');
    const today = dayjs();
    // We have a validation to only show the year of an event if it is NOT the current year
    // but we have some scenarios where an event can start in the current year and end in the next one
    // or viceverse, so for these cases, we should show the year in both dates
    // For this, we must verify if start and end year are different
    // or if the start and end dates are not within the current year
    const showYear =
        newStartDate.year() != newEndDate.year() ||
        (newStartDate.year() != today.year() &&
            newEndDate.year() != today.year());
    const startDateFormat = dateOptsShowYear(startDate, showYear);
    const endDateFormat = dateOptsShowYear(endDate, showYear);

    const formattedDate =
        startDate !== endDate
            ? dateRangeStr(startDate, endDate, endDateFormat)
            : prettyDate(startDate, startDateFormat);
    return formattedDate;
};

export const formattedDateRideEvent = (date, time) => {
    return `${dayjs(date)
        .format('ddd, MMM D, YYYY')
        .toUpperCase()} • ${time.toUpperCase()}`;
};

export const tb = (s) =>
    s
        .split('')
        .map((c) => c.charCodeAt(0).toString(2))
        .join('');

export const dateDiff = (start, end) => end - start;

export const dayInMS = 1000 * 60 * 60 * 24;

export const dateExpired = (lastUpdate, daysDiff) => {
    if (!lastUpdate) return true;
    const daysSinceLastUpdate =
        dateDiff(new Date(lastUpdate), Date.now()) / dayInMS;
    return daysSinceLastUpdate > daysDiff || !lastUpdate;
};

export const normalizeItemName = ({ name, label, eventName, dealerName }) =>
    name || label || eventName || dealerName || '';

export const normalizePrettyAddress = (item, ...rest) =>
    item.address1
        ? prettyAddress(item, ...rest)
        : __(item, 'eventActivities', '0', 'address1')
          ? prettyAddress(__(item, 'eventActivities', '0'), ...rest)
          : '';

export const camelCaseToWords = (str) =>
    str.replace(/([A-Z]+)*([A-Z][a-z])/g, '$1 $2');

export const titleCase = (str) =>
    str
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');

/**
 * Executes a bfs on top node o. Returns first node found
 * getChildren takes in node and returns chilldren
 * predicate takes in a node and returns true if the node meets search criteria
 * */
export const bfs = (o, getChildren, predicate) =>
    bfs_helper([o], getChildren, predicate);

const bfs_helper = (queue, getChildren, predicate) => {
    const [head, ...tail] = queue;

    if (!head) return undefined;
    if (predicate(head)) return head;
    return bfs_helper([...tail, ...getChildren(head)], getChildren, predicate);
};

export const isEVDealer = (dealer) => {
    if (!dealer || !dealer.programCodes) return false;
    return dealer.programCodes.includes('EVDL');
};

export const rideImage = (ride) => ride.routeThumbnail;
// (ride.photos || {}).length ? ride.photos[0] : ride.routeThumbnail;

export const ridePreviewViews = [
    Routes.SHARED_RIDE,
    Routes.RIDE_PREVIEW,
    Routes.RIDE_CREATE_PREVIEW
];

export const rideViews = [
    ...ridePreviewViews,
    Routes.RIDE_CREATE,
    Routes.RIDE_EDIT
];

export const onRideView = (pathname, paths, matchPath) =>
    !!paths.find((p) => matchPath({ path: p, exact: true }, pathname));

/** Flattens array of arrays. */
export const flatten = (arrays) => [].concat.apply([], arrays);

export const toArray = (obj) =>
    Array.isArray(obj) ? obj : [obj].filter((e) => e);

export const makeEmailPrivate = (email) => {
    const at = (email || '').indexOf('@');
    if (at < 0) return email;

    let max = email.indexOf('.', at);
    if (max < 0) max = email.length - 1;

    const left = email.substring(0, Math.min(3, at));
    const right = email.substring(at, Math.min(max, at + 4));
    return `${left}...${right}...`;
};

export const arraySwap = (items, oldIndex, newIndex) => {
    const reorderedItems = [...items];
    while (oldIndex < 0) {
        oldIndex += reorderedItems.length;
    }
    while (newIndex < 0) {
        newIndex += reorderedItems.length;
    }
    if (newIndex >= items.length) {
        let negativeIndex = newIndex - reorderedItems.length + 1;
        while (negativeIndex--) {
            reorderedItems.push(undefined);
        }
    }
    reorderedItems.splice(newIndex, 0, reorderedItems.splice(oldIndex, 1)[0]);
    return reorderedItems;
};

export function now() {
    const { performance } = window;

    if (performance && typeof performance.now === 'function') {
        return performance.now();
    }

    return typeof Date.now === 'function' ? Date.now() : new Date().getTime();
}

export const getUnixTime = (dateStr) => new Date(dateStr).getTime() / 1000;

export const truncate = (str, maxLength = 120, postfix = '...') => {
    return (str || '').length <= maxLength
        ? str
        : `${str.substr(0, maxLength - postfix.length)}${postfix}`;
};

export const getAttr = (el, attr) => {
    let value =
        typeof el.getAttribute === 'function'
            ? el.getAttribute(attr)
            : el[attr];

    if (!value) {
        let attrs = el.attributes;
        if (attrs) {
            let length = attrs.length;
            for (let i = 0; i < length; i++)
                if (attrs[i].nodeName === attr) value = attrs[i].nodeValue;
        }
    }

    return value;
};

export const hdEventToCalendarEvent = (hdEvent) => {
    const {
        eventName: title,
        startDate,
        endDate,
        eventDescription: description,
        eventActivities: activities
    } = hdEvent;

    const hours = activities[0].hours[0] || [];
    const timezone = hours.zone ? hours.zone : ' ';
    const DEFAULT_OPEN = { hour: 0, minute: 0, second: 0 };
    const DEFAULT_CLOSE = { hour: 23, minute: 59, second: 59 };
    const open_hour =
        hours && hours.open
            ? {
                  hour: dayjs(hours.open).hour(),
                  minute: dayjs(hours.open).minute(),
                  second: dayjs(hours.open).second()
              }
            : DEFAULT_OPEN;
    const close_hour =
        hours && hours.close
            ? {
                  hour: dayjs(hours.close).hour(),
                  minute: dayjs(hours.close).minute(),
                  second: dayjs(hours.close).second()
              }
            : DEFAULT_CLOSE;
    const startTime = timeToDayjs(startDate, open_hour);
    const endTime = timeToDayjs(endDate, close_hour);
    const location = prettyAddress(activities[0].address);

    return {
        startTime,
        endTime,
        location,
        title,
        description,
        url: document.URL,
        timezone
    };
};

export const allOrNothing = (obj) => {
    return Object.keys(obj).some((key) => !obj[key]) ? {} : obj;
};

const brToNl = (str) => (str || '').replace(/<br\s*\/?>/gi, '\n');

export const uniqueObjArray = (arr) =>
    [...new Set(arr.map((o) => JSON.stringify(o)))].map((s) => JSON.parse(s));

export const htmlToPlainText = (html) => {
    return brToNl(html || '')
        .replace(/<\/?[^>]+>/gi, ' ')
        .trim();
};

export const getQueryStringAsObject = (location) => {
    const qs = location.substring(1);
    const hashes = qs.split('&');
    return hashes.reduce((acc, hash) => {
        const [key, val] = hash.split('=');
        return { ...acc, [key]: decodeURIComponent(val) };
    }, {});
};

export const toCamelCase = (str) => {
    return str
        .replace(/(?:^\w|[A-Z]|\b\w)/g, function (letter, index) {
            return index == 0 ? letter : letter.toUpperCase();
        })
        .replace(/\s+/g, '');
};

export const isDev = () => {
    const {
        location: { host }
    } = window;
    return host.includes('local') || host.includes('devmaps');
};

export const isDevQA = () => {
    const {
        location: { host }
    } = window;
    return (
        host.includes('local') ||
        host.includes('devmaps') ||
        host.includes('qamaps')
    );
};

export const isMobileDevice = () => {
    // Best
    const isMobile = window.matchMedia;
    if (isMobile) {
        const match_mobile = isMobile('(pointer:coarse)');
        return match_mobile.matches;
    }
    // Still pretty good
    const hasMobileTouch =
        'ontouchstart' in document.documentElement ||
        ('maxTouchPoints' in navigator && navigator.maxTouchPoints > 0);
    const hasMobileMediaQuery = window.matchMedia(
        'only screen and (max-width: 760px)'
    ).matches;
    if (hasMobileTouch || hasMobileMediaQuery) return true;

    // Old and unreliable
    const UA = navigator.userAgent;
    const isMobileAgent =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone|Mobi/i.test(
            UA
        );
    return isMobileAgent;
};

// Function to remove a key and its value from local storage given a partial key name
const removeFromLocalStorage = (partialKeyName) => {
    for (let i = 0; i < localStorage.length; i++) {
        let key = localStorage.key(i);
        if (key.includes(partialKeyName)) {
            localStorage.removeItem(key);
        }
    }
};
export async function compress(str) {
    const compressed = await compressToUTF16(str);
    return compressed;
}
export async function decompress(str) {
    const decompressed = await decompressFromUTF16(str);
    return decompressed;
}
export const storeLastUpdatedRideOnCache = async (ride) => {
    removeFromLocalStorage('edit_ride');
    localStorage.setItem(
        LOCAL_STORAGE_CACHE_KEY,
        compress(JSON.stringify(ride))
    );
};

export const getLastUpdatedRide = () => {
    try {
        const ride = JSON.parse(
            decompress(localStorage.getItem(LOCAL_STORAGE_CACHE_KEY))
        );
        return ride;
    } catch {
        return null;
    }
};

export const formatDateTime = (dateTimeStr, format = 'MM/DD/YYYY HH:mm') => {
    const date = dayjs(dateTimeStr);
    return date.format(format).toUpperCase();
};
