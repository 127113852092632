/******************************************************************************\
 * File: AppStoreBadges.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/common/AppStoreBadges.scss';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------
import isMobile from '@/helpers/is-mobile';
import { ANDROID_APP_URL, IOS_APP_URL } from '@/helpers/constants';
//------------------------------------------------------------------------------
// React Functional Component --------------------------------------------------
const Badge = ({ img, href, title }) => (
    <a href={href} title={title} target="_blank" className={style.Badge}>
        <img src={img} width="200" />
    </a>
);

//------------------------------------------------------------------------------
// AppStoreBadges Functional Component -----------------------------------------
const AppStoreBadges = (props) => {
    // device detection
    let isAndroid = isMobile.Android();
    let isIOS = isMobile.iOS();
    // show both badges if we couldn't detect either
    if (!isAndroid && !isIOS) isAndroid = isIOS = true;

    return (
        <div {...props}>
            {isIOS && (
                <Badge img="/img/app-store-badge.png" href={IOS_APP_URL} />
            )}
            {isAndroid && (
                <Badge
                    img="/img/google-play-badge.png"
                    href={ANDROID_APP_URL}
                />
            )}
        </div>
    );
};

//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default AppStoreBadges;
