// extracted by mini-css-extract-plugin
var _1 = "Modal__arrow___QuqMp";
var _2 = "Modal__background___KDeSR";
var _3 = "Modal__bottom___VtbSF";
var _4 = "Modal__button___o8kWE";
var _5 = "Modal__close___L00bG";
var _6 = "Modal__content___YfLQ3";
var _7 = "Modal__header___QYqop";
var _8 = "Modal__inner___ngKMN";
var _9 = "Modal__large___Hzozq";
var _a = "Modal__negative___Lqlkx";
var _b = "Modal__overlay___CIZVp";
var _c = "Modal__positive___DPJex";
var _d = "Modal__right___Vil30";
var _e = "Modal__small___N_0RZ";
var _f = "Modal__subtitle___h4C1p";
var _10 = "Modal__text___t2baj";
var _11 = "Modal__textAction___Pmr10";
var _12 = "Modal__title____dvzy";
var _13 = "Modal__wiggle___hiu_B";
export { _1 as "arrow", _2 as "background", _3 as "bottom", _4 as "button", _5 as "close", _6 as "content", _7 as "header", _8 as "inner", _9 as "large", _a as "negative", _b as "overlay", _c as "positive", _d as "right", _e as "small", _f as "subtitle", _10 as "text", _11 as "textAction", _12 as "title", _13 as "wiggle" }
