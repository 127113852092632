/******************************************************************************\
 * File: MobileDownloadCTA.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/common/MobileDownloadCTA.scss';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------
import Modal from '@/components/common/Modal';
import Logo from '@/components/common/header/Logo';
import AppStoreBadges from '@/components/common/AppStoreBadges';
import { translate } from '@/helpers/i18n';
const t = translate('common.MobileDownloadCTA');
//------------------------------------------------------------------------------
// MobileDownloadCTA Function --------------------------------------------------
const MobileDownloadCTA = (props) => {
    return (
        <Modal
            className={style.MobileDownloadCTA}
            portalClassName="ReactModalPortal force-open" // Force to open on page load
            animated={false}
            large
            {...props}>
            <img className={style.bgImage} src="/img/harley-rider.jpg" />
            <div className={style.content}>
                <div className={style.center}>
                    <h4 className={style.caption}>
                        {t('Download the app to')}
                    </h4>
                    <h1 
                    // className={style.title}
                    >
                        {t('Get the Full Throttle')}
                    </h1>
                    <p 
                    // className={style.description}
                    >
                        {t(
                            'For the full experience, and some wind in your hair, download the app on your phone.'
                        )}
                    </p>
                    <Logo 
                    // className={style.logo} 
                    />
                </div>
                <div className={style.footer}>
                    <AppStoreBadges />
                </div>
            </div>
        </Modal>
    );
};
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default MobileDownloadCTA;
