/******************************************************************************\
* File: MiniArrowUp.jsx
*
* Author: Gigster
*
* Description: 
*
* Notes: 
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/common/icons/Icon.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class MiniArrowUp extends React.Component {
    render() {
        const { className, onClick } = this.props;

        const cn = classNames(style.Icon, {
            [style['clickable']]: onClick,
            [className]: !!className
        });

        return (
            <svg className={cn} viewBox="0 0 7 7" onClick={onClick}>
                <path
                    fill="#FFF"
                    fillRule="evenodd"
                    d="M1.907 0v1.132h3.161L0 6.2l.8.8 5.068-5.068v3.161H7V0z"
                />
            </svg>
        );
    }
}
export default MiniArrowUp;
