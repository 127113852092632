/******************************************************************************\
* File: Inline.jsx
*
* Author: Gigster
*
* Description: Displays children inline separated by a bullet
*
* Notes: 
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classnames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/mapListView/sidebar/Inline.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Inline extends React.Component {
    render() {
        const { className, children } = this.props;
        const cn = classnames(style.Inline, { [className]: !!className });
        return <div className={cn}>{children}</div>;
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Inline;
