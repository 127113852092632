/******************************************************************************\
 * File: RouteGasStationMarker.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/common/icons/Icon.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class RouteGasStationMarker extends React.Component {
    render() {
        const { className, onClick, highlight, isOnRoute } = this.props;
        const cn = classNames(style.Icon, {
            [style['clickable']]: onClick,
            [className]: className
        });

        return (
            <div
                style={{
                    ...style,
                    height: '40px',
                    width: '37px',
                    margin: '-40px 0 0 -19px'
                }}>
                <svg
                    style={{
                        height: '100%',
                        width: '100%'
                    }}
                    className={cn}
                    onClick={onClick}
                    viewBox={!isOnRoute ? '0 0 31 43' : '0 0 30 43'}>
                    <g fill="none" fillRule="evenodd">
                        {!isOnRoute && (
                            <circle
                                cx="16"
                                cy="16"
                                r="14"
                                fill="#000"
                                stroke="#C7C7C7"
                                strokeWidth="1"></circle>
                        )}
                        <path d="M0 0h30v30H0z" opacity=".6" />
                        {isOnRoute && (
                            <path
                                fill="#F60"
                                d="M25.612 4.41c-5.827-5.857-15.3-5.884-21.16-.058l-.06.059C-.852 9.676-1.502 19.575 2.999 25.576L15 43l12.002-17.4c4.501-6 3.851-15.924-1.395-21.19h.005z"
                            />
                        )}
                        <path
                            fill={!isOnRoute || highlight ? '#fff' : '#000'}
                            d="M20.912 15.937l-.704.29-1.103-3.28.183-.851-.555-1.494c-.099-.265-.109-.504-.022-.533l.655-.222c.086-.03.237.162.335.427l.414 1.113a.715.715 0 0 1 .019-.007l.291-.098c.278-.094.566-.002.644.207l1.29 3.474c.078.209-.085.454-.363.548l-.291.099-.041.017c.305 1.714.6 3.958-.055 4.589-.367.353-.699.453-.956.453a.973.973 0 0 1-.377-.075c-.615-.257-.948-1.123-.891-2.317.019-.396-.063-.665-.243-.798-.139-.103-.492-.157-.802-.178v3.174h.376c.493 0 .893.457.893 1.02v.835H9.375v-.835c0-.563.4-1.02.893-1.02h.353V10.368c0-.564.4-1.02.893-1.02h5.933c.493 0 .893.456.893 1.02v6.241c.426.015.933.089 1.244.316.382.28.56.745.53 1.383-.05 1.049.244 1.56.455 1.648.179.075.424-.136.522-.23.382-.368.106-2.068-.179-3.79zm-9.027-2.984h5.243V10.9h-5.243v2.052z"
                        />
                    </g>
                </svg>
            </div>
        );
    }
}
export default RouteGasStationMarker;
