/******************************************************************************\
 * File: NotFoundView.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import { connect } from 'react-redux';
import withRouter from '@/helpers/hooks';
import { Helmet } from 'react-helmet';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/errorView/NotFoundView.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import Hero from '@/components/homeView/Hero';
import CardGrid from '@/components/common/cards/CardGrid';
import Ride from '@/components/common/cards/Ride';
import Footer from '@/components/homeView/Footer';
import Section from '@/components/homeView/Section';
import SearchBar from '@/components/common/search/SearchBar';
import { Routes } from '@/helpers/routes';
import { auth } from '@/store/auth';
import { translate } from '@/helpers/i18n';
const t = translate('errorView.NotFoundView');
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class NotFoundView extends React.Component {
    render() {
        const { auth, home } = this.props;

        return (
            <div className={style.NotFoundView}>
                <Helmet>
                    <title>{t('Page Not Found')}</title>
                </Helmet>

                <Hero title={t('404')} subtitle={t('You Took a Wrong Turn')}>
                    {t(
                        'The page your were looking for was moved, removed or never existed.'
                    )}
                    <br />
                    <a
                        href="javascript:void(0)"
                        onClick={auth}
                        className={style.link}>
                        {t('Need to Sign in?')}
                    </a>
                    <hr className={style.hr} />
                </Hero>

                <div className={style.content}>
                    {((home.nearRides || {}).data || {}).length > 0 && (
                        <Section title={t('Rides For You')}>
                            <CardGrid
                                Item={Ride}
                                items={home.nearRides.data.slice(0, 9)}
                                columns={3}
                                heightFromRows
                                seeMoreLink={Routes.MAP_RIDES}
                            />
                        </Section>
                    )}
                </div>

                <Footer />
            </div>
        );
    }
}
//------------------------------------------------------------------------------
// Redux State -----------------------------------------------------------------
const mapStateToProps = (state, ownProps) => {
    return {
        home: state.home
    };
};
//------------------------------------------------------------------------------
// Redux Actions ---------------------------------------------------------------
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        auth: () => dispatch(auth())
    };
};
//------------------------------------------------------------------------------
// Redux Connect ---------------------------------------------------------------
const container = connect(mapStateToProps, mapDispatchToProps)(NotFoundView);
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withRouter(container);
