/******************************************************************************\
 * File: RideEditSidebar.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
//------------------------------------------------------------------------------
// Components ------------------------------------------------------------------
import Sidebar from '@/components/common/Sidebar';
import RideEdit from './RideEdit';
import ArrowRight from '@/components/common/icons/ArrowRight';
import * as style from '@/style/mapListView/sidebar/RideEdit.scss';
import { translate } from '@/helpers/i18n';
const t = translate('sidebar.RideEdit');

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class RideEditSidebar extends React.Component {
    backLink = (onClick, text) => (
        <a onClick={onClick} 
        // className={style.arrow}
        >
            <span>
                <ArrowRight 
                // className={style.leftArrow}
                 left={true} />
                <span 
                // className={style.arrowLink}
                >{text}</span>
            </span>
        </a>
    );
    render() {
        return (
            <Sidebar
                topOffset={true}
                paddingBottom={true}
                contentLabel={'name'}
                {...this.props}
            >
                <RideEdit smallTitle={true} {...this.props} />
            </Sidebar>
        );
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default RideEditSidebar;
