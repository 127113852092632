/******************************************************************************\
* File: Back.jsx
*
* Author: Gigster
*
* Description: SVG icon of Back
*
* Notes: 
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/common/icons/Icon.scss';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------
import ArrowRight from '@/components/common/icons/ArrowRight';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Back extends React.Component {
    render() {
        const { className, onClick } = this.props;

        const cn = classNames(style.Icon, {
            [style['clickable']]: onClick,
            [className]: className
        });

        return (
            <div style={{ transform: 'rotate(-180deg)' }}>
                <ArrowRight />
            </div>
        );
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Back;
