/******************************************************************************\
 * File: Text.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/common/form/Text.scss';
import Tooltip from '@/components/common/form/Tooltip';
import { ConnectedResultsList } from '@/components/common/search/Downshift';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Text extends React.Component {
    static defaultProps = {
        inputProps: {},
        selectOnFocus: false,
        isFocused: false
    };

    componentDidUpdate = (prevProps) => {
        const { tempValue } = this.state;
        if (tempValue !== undefined && prevProps.value !== this.props.value) {
            this.setState({ tempValue: undefined });
        }
    };

    state = {
        tempValue: undefined,
        heightExceeded: false
    };

    setRef = (el) => {
        const { initialFocus, setRef } = this.props;
        this.$input = el;
        setRef && setRef(el);
        initialFocus && el && el.focus();
    };

    onFocus = (e) => {
        const { inputProps, value, selectOnFocus } = this.props;
        const { tempValue } = this.state;
        inputProps.onFocus && inputProps.onFocus(e);

        if (selectOnFocus) this.$input.select();

        if (selectOnFocus && tempValue === undefined) {
            this.setState({ tempValue: value });
        }
        this.setState({
            isFocused: true,
            heightExceeded: window.innerHeight - e.target.offsetTop < 300
        });
    };

    onChange = (e) => {
        const { inputProps } = this.props;
        const value = e.target.value;
        inputProps.onChange && inputProps.onChange(e);
        this.setState({ tempValue: value });
    };

    onBlur = (e) => {
        const { value, inputProps, onSubmit } = this.props;
        const { tempValue } = this.state;
        inputProps.onBlur && inputProps.onBlur(e);
        setTimeout(
            () => this.setState({ isFocused: false, heightExceeded: false }),
            500
        );
        if (value !== tempValue) {
            onSubmit && onSubmit(tempValue);
            this.setState({ tempValue: undefined });
        }
    };

    render() {
        const {
            className,
            classNameWrapper,
            value,
            editable,
            blurOnEnter,
            inputProps,
            placeholder,
            onSubmit,
            initialFocus,
            selectOnFocus,
            dontShowResultsList,
            setRef,
            index,
            wide,
            sidebar,
            customOnBlur,
            error,
            toolTipText,
            errorText,
            icon,
            hasLocations,
            tooltipOffsetright,
            tooltipLeftAlign,
            inputType,
            isFocused: isFocusedProp,
            ...props
        } = this.props;

        const { tempValue, isFocused, heightExceeded } = this.state;

        const cn = classNames(style.Text, {
            [className]: !!className,
            [style.wide]: !!wide,
            [style.sidebar]: !!sidebar,
            [style.error]: !!error
        });

        return editable ? (
            <div className={classNameWrapper}>
                <div>
                    <input
                        className={cn}
                        type={!inputType ? 'text' : inputType}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && blurOnEnter)
                                e.target.blur();
                        }}
                        placeholder={placeholder}
                        {...inputProps}
                        onFocus={this.onFocus}
                        onChange={this.onChange}
                        onBlur={customOnBlur || this.onBlur}
                        ref={this.setRef}
                        {...props}
                        value={tempValue !== undefined ? tempValue : value}
                    />
                    {!!icon && !!toolTipText && (
                        <Tooltip
                            value={toolTipText}
                            placement="s"
                            multiline={true}
                            noDelay
                            offsetright={!!tooltipOffsetright ? true : false}
                            wide={true}
                            leftAlign={!!tooltipLeftAlign ? true : false}>
                            <div className={style.icon}>
                                <img src={icon} />
                            </div>
                        </Tooltip>
                    )}
                    {!dontShowResultsList && isFocused && (
                        <div 
                        // className={style.locationList}
                        >
                            <ConnectedResultsList
                                absolute={true}
                                heightExceeded={heightExceeded}
                            />
                        </div>
                    )}
                </div>
                {error && <div className={style.error}>{errorText}</div>}
            </div>
        ) : (
            <div
                className={cn}
                {...props}
                title={typeof value === 'string' ? value : undefined}>
                {placeholder && !(value || {}).length && (
                    <span className={style.placeholder}>{placeholder}</span>
                )}
                {value}
                {!dontShowResultsList && isFocused && (
                    <div 
                    // className={style.locationList}
                    >
                        <ConnectedResultsList
                            absolute={true}
                            heightExceeded={heightExceeded}
                        />
                    </div>
                )}
            </div>
        );
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Text;
