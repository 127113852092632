/******************************************************************************\
 * File: DownloadGPXButton.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/mapListView/DownloadGPXButton.scss';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import GPXModal from '@/components/mapListView/directions/GPXModal';
import DownloadIcon from '@/components/common/icons/Download';
import { gpxDownloadUrl } from '@/helpers/api';
import Tooltip from '@/components/common/form/Tooltip';
import { translate } from '@/helpers/i18n';
const t = translate('mapListView.DownloadGPXButton');
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class DownloadGPXButton extends React.Component {
    state = {
        showGPXConfirm: false,
        downloadRequested: false
    };

    setShowGPXConfirm = (showGPXConfirm) => this.setState({ showGPXConfirm });
    setDownloadRequested = (downloadRequested) =>
        this.setState({ downloadRequested });

    handleDownloadGPXClick = (e) => {
        if (!this.isRideSaved() || !this.props.ride.id) {
            this.setShowGPXConfirm(true);
            this.setDownloadRequested(true);
            e.preventDefault();
        }
    };
    isRideSaved = () => !(this.props.ride.meta || {}).dirty;

    hasTwoWaypoints = () =>
        ((this.props.ride || {}).waypoints || {}).length >= 2;

    render() {
        const { ride, position, id } = this.props;
        const { showGPXConfirm, downloadRequested } = this.state;

        const disabled = !ride.id || !this.isRideSaved();
        return (
            <div className={style.DownloadGPXButton}>
                <GPXModal
                    isOpen={showGPXConfirm}
                    ride={ride}
                    isRideSaved={this.isRideSaved()}
                    onRequestClose={() => {
                        if (downloadRequested) {
                            gpxDownloadUrl(ride.id);
                            this.setDownloadRequested(false);
                        }
                        this.setShowGPXConfirm(false);
                    }}
                />

                <Tooltip
                    value={
                        disabled
                            ? this.hasTwoWaypoints()
                                ? t('Save your ride to download the GPX file.')
                                : t(
                                      'Please add at least two (2) locations to create a GPX file.'
                                  )
                            : t('Download GPX File')
                    }
                    topPadding={true}
                    placement={position || 'w'}
                    noDelay>
                    <a
                        className={classNames(style.link, {
                            [style.disabled]: disabled
                        })}
                        title={t('Download GPX File')}
                        href={
                            disabled || (!ride && !ride.id)
                                ? undefined
                                : gpxDownloadUrl(ride.id)
                        }
                        style={{
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden'
                        }}
                        onClick={
                            disabled ? () => {} : this.handleDownloadGPXClick
                        }
                        id={id ? id : undefined}
                        data-testid={id ? id : undefined}>
                        <DownloadIcon />
                        {t('Export GPX')}
                    </a>
                </Tooltip>
            </div>
        );
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default DownloadGPXButton;
