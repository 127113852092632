/******************************************************************************\
* File: AddMarker.jsx
*
* Author: Gigster
*
* Description: 
*
* Notes: 
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/common/icons/Icon.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class AddMarker extends React.Component {
    render() {
        const { className, onClick } = this.props;

        const cn = classNames(style.Icon, {
            [style['clickable']]: onClick,
            [className]: className
        });

        return (
            <svg className={cn} viewBox="0 0 16 16" onClick={onClick}>
                <title>Add Marker</title>
                <desc>Created with Sketch.</desc>
                <defs />
                <g
                    id="Page-1"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                >
                    <g id="Add-Marker" fill="#FF6600">
                        <path
                            d="M13.4666667,2.54545455 L15.2761905,2.54545455 C15.6759394,2.54545455 16,2.87106564 16,3.27272727 C16,3.67438891 15.6759394,4 15.2761905,4 L13.4666667,4 L13.4666667,5.81818182 C13.4666667,6.21984345 13.1426061,6.54545455 12.7428571,6.54545455 C12.3431082,6.54545455 12.0190476,6.21984345 12.0190476,5.81818182 L12.0190476,4 L10.2095238,4 C9.80977485,4 9.48571429,3.67438891 9.48571429,3.27272727 C9.48571429,2.87106564 9.80977485,2.54545455 10.2095238,2.54545455 L12.0190476,2.54545455 L12.0190476,0.727272727 C12.0190476,0.325611091 12.3431082,0 12.7428571,0 C13.1426061,0 13.4666667,0.325611091 13.4666667,0.727272727 L13.4666667,2.54545455 Z M5.06666667,16 C1.68888889,12.3284085 0,9.59323661 0,7.7944844 C0,5.09635608 2.26842393,2.90909091 5.06666667,2.90909091 C7.8649094,2.90909091 10.1333333,5.09635608 10.1333333,7.7944844 C10.1333333,9.59323661 8.44444444,12.3284085 5.06666667,16 Z M5.06666667,9.7486418 C6.18596376,9.7486418 7.09333333,8.87373573 7.09333333,7.7944844 C7.09333333,6.71523307 6.18596376,5.840327 5.06666667,5.840327 C3.94736957,5.840327 3.04,6.71523307 3.04,7.7944844 C3.04,8.87373573 3.94736957,9.7486418 5.06666667,9.7486418 Z"
                            id="Combined-Shape"
                        />
                    </g>
                </g>
            </svg>
        );
    }
}
export default AddMarker;
