/******************************************************************************\
* File: SimpleHtmlRenderer.jsx
*
* Author: Gigster
*
* Description: 
*
* Notes: 
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import sanitizeHtml from 'sanitize-html';
import * as style from '@/style/common/SimpleHtmlRenderer.scss';
import { toArray } from '@/helpers/functions';
import Linkify from 'react-linkify';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class SimpleHtmlRenderer extends React.Component {
    renderHtml(html) {
        const clean = sanitizeHtml(html, {
            allowedTags: ['b', 'i', 'em', 'strong', 'a', 'br', 'hr']
        });
        const cleanTargetBlank = (clean || '').replace(
            /<a href/g,
            '<a target="_blank" href'
        );
        return (
            <div
                key={clean}
                dangerouslySetInnerHTML={{ __html: cleanTargetBlank }}
            />
        );
    }

    render() {
        const { html, children, className } = this.props;

        const cn = classNames(style.SimpleHtmlRenderer, {
            [className]: !!className
        });

        return (
            <Linkify
                className={cn}
                properties={{
                    target: '_blank',
                    style: { textDecoration: 'underline' }
                }}>
                {toArray(html || children).map(this.renderHtml)}
            </Linkify>
        );
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default SimpleHtmlRenderer;
