/******************************************************************************\
* File: Toggle.jsx
*
* Author: Gigster
*
* Description: SVG icon of Toggle
*
* Notes: 
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/common/icons/Icon.scss';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Toggle extends React.Component {
    render() {
        const { className, onClick } = this.props;

        const cn = classNames(style.Icon, {
            [style['clickable']]: onClick,
            [className]: className
        });

        return (
            <svg className={cn} viewBox="0 0 40 21" onClick={onClick}>
                <g stroke="none" fillRule="evenodd">
                    <path
                        d="M27.8400261,30.4998308 C27.1257174,30.5082527 26.4371925,30.201908 25.9301097,29.6471187 L10.2783451,12.550767 C9.24985517,11.431714 9.23935063,9.60522565 10.2544712,8.47143435 C10.2621108,8.4630125 10.2707055,8.45353792 10.2783451,8.44511607 L25.9587585,-8.65123562 C27.0025278,-9.78292146 28.6775244,-9.78292146 29.7212937,-8.65123562 C30.7497837,-7.53218255 30.7612432,-5.70569424 29.7451677,-4.57190293 C29.737528,-4.56348109 29.7298884,-4.55400651 29.7212937,-4.54558466 L15.922148,10.4979415 L29.7212937,25.5414678 C30.7497837,26.6615736 30.7612432,28.4870091 29.7451677,29.6208004 L29.7212937,29.6471187 C29.2199407,30.1934861 28.5438303,30.4998308 27.8400261,30.4998308"
                        id="Fill-1-Copy-2"
                        transform="translate(20.000000, 10.500000) scale(-1, -1) rotate(90.000000) translate(-20.000000, -10.500000) "
                    />
                </g>
            </svg>
        );
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Toggle;
