/******************************************************************************\
 * File: RouteDealerMarker.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/common/icons/Icon.scss';
import Bubble from '@/components/common/map/icons/Bubble';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class RouteDealerMarker extends React.Component {
    render() {
        const {
            className,
            onClick,
            highlight,
            isOnRoute,
            useFillColor,
            fillColor,
            isEVDealer
        } = this.props;

        const cn = classNames(style.Icon, 'dealer-marker', {
            [style['clickable']]: onClick,
            [className]: className
        });
        return (
            <div
                style={{
                    height: '40px',
                    width: '37px',
                    margin: isOnRoute ? '-40px 0 0 -19px' : '-16px 0 0 -19px'
                }}>
                <svg
                    style={{ height: '100%', width: '100%' }}
                    className={cn}
                    viewBox={!isOnRoute ? '0 0 31 43' : '0 0 30 43'}>
                    <g fill="none" fillRule="evenodd">
                        {isOnRoute || useFillColor ? (
                            <path
                                fill={useFillColor ? fillColor : '#F60'}
                                d="M25.612 4.41c-5.827-5.857-15.3-5.884-21.16-.058l-.06.059C-.852 9.676-1.502 19.575 2.999 25.576L15 43l12.002-17.4c4.501-6 3.851-15.924-1.395-21.19h.005z"
                            />
                        ) : (
                            <circle
                                cx="15"
                                cy="15"
                                r="14"
                                fill={isEVDealer ? '#056aaf' : '#000'}
                                stroke="#C7C7C7"
                                strokeWidth="1"></circle>
                        )}
                        <path
                            fill={!isOnRoute || highlight ? '#fff' : '#000'}
                            d="M7.841 16.248h14.313v-2.301H7.841v2.3zm11.428 2.057s-1.847 1.722-4.271 2.343c-2.425-.621-4.271-2.343-4.271-2.343.55-.337.735-.771.798-1.04h6.945c.063.269.248.703.799 1.04zm-8.558-6.873s2.081.111 4.287-1.563c2.205 1.674 4.286 1.563 4.286 1.563-.675.7-.787 1.257-.8 1.498H11.51c-.013-.24-.125-.799-.8-1.498zm12.452 1.498h-3.588c.021-.582.758-.86.758-.86v-1.5c-4.006-.223-5.335-2.28-5.335-2.28s-1.33 2.057-5.336 2.28v1.5s.737.278.758.86H6.832v4.334h3.576c-.204.748-1.223.937-1.223.937 2.919 3.086 5.813 3.58 5.813 3.58s2.893-.494 5.812-3.58c0 0-1.019-.19-1.223-.937h3.576V12.93z"
                        />
                    </g>
                </svg>
            </div>
        );
    }
}
export default RouteDealerMarker;
