/******************************************************************************\
* File: ActionBar.jsx
*
* Author: Gigster
*
* Description: 
*
* Notes: 
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import Link from '@/components/common/header/Link';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/mapListView/sidebar/ActionBar.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
export const Action = ({
    className,
    icon: IconComponent,
    text,
    active,
    color,
    ...props
}) => {
    return (
        <Link
            className={classNames(style.Action, {
                [className]: !!className,
                [style['active']]: !!active
            })}
            {...props}>
            {!!IconComponent && (
                <IconComponent
                    active={active}
                    color={color ? color : undefined}
                />
            )}
            <div>{text}</div>
        </Link>
    );
};

export const ActionBar = ({ className, star, children }) => (
    <div
        className={classNames(style.ActionBar, {
            [className]: !!className,
            [star]: !!star
        })}>
        {children}
    </div>
);
