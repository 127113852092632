/******************************************************************************\
* File: ReturnTrip.jsx
*
* Author: Gigster
*
* Description: 
*
* Notes: 
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/common/icons/Icon.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class ReturnTrip extends React.Component {
    render() {
        const { className, onClick } = this.props;

        const cn = classNames(style.Icon, {
            [style['clickable']]: onClick,
            [className]: !!className
        });

        return (
            <svg className={cn} viewBox="0 0 17 15" onClick={onClick}>
                <g fill="#FFF" fillRule="nonzero">
                    <path d="M3.293 10H.5l4.001-4L8.5 10H5.421a5.001 5.001 0 1 0 8.298-5.355 4.992 4.992 0 0 0 .22-2.445A7 7 0 1 1 3.293 10z" />
                    <path d="M9 4.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zM9 6a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                </g>
            </svg>
        );
    }
}
export default ReturnTrip;
