/******************************************************************************\
 * File: ChargingStationDetails.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/mapListView/sidebar/DealerSidebar.scss';
//------------------------------------------------------------------------------
// Components ------------------------------------------------------------------
import ArrowRight from '@/components/common/icons/ArrowRight';
import Section from '@/components/mapListView/sidebar/Section';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import {
    prettyPhone,
    __,
    responsiveToggle,
    uniqueObjArray
} from '@/helpers/functions';
import {
    isTwentyFourSeven,
    transformRanges,
    acAmpsVoltsToWatts,
    dcAmpsVoltsToWatts,
    daysHours,
    getEvseLevelCounts
} from '@/helpers/evCharging';
import { CONNECTOR_STANDARDS } from '@/shared/constants';

//App context
import AppContext from '@/contexts/AppContext';
//------------------------------------------------------------------------------
// Store -----------------------------------------------------------------------
import { translate } from '@/helpers/i18n';
const t = translate('sidebar.ChargingStationDetails');

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class ChargingStationDetails extends React.Component {
    static defaultProps = {
        isExpanded: true
    };

    state = {
        initialLoad: true
    };

    componentDidMount = () => {
        const { initialLoad } = this.state;
        const { chargingStation } = this.props;
        if (initialLoad) {
            this.setState({
                chargingStationId: chargingStation.id,
                chargingStation: { ...chargingStation },
                initialLoad: false
            });
        }
    };

    componentDidUpdate = (prevProps, prevState) => {
        const { chargingStation } = this.props;
        const { chargingStationId } = prevState;
        const newChargingStation =
            !!chargingStation.id && chargingStation.id !== chargingStationId
                ? chargingStation
                : undefined;

        if (newChargingStation) {
            this.setState({
                chargingStationId: newChargingStation.id,
                chargingStation: { ...newChargingStation }
            });
        }
    };

    toggle = (prop) => this.setState({ [prop]: !this.state[prop] });

    getNumColumns = (mobile, tablet, desktop, fallback = 1) => {
        const { screenSize } = this.context;
        return responsiveToggle(screenSize, fallback, mobile, tablet, desktop);
    };

    displayNotes = (notes) => (
        <Section
            subtitle={t('Additional Information')}
            expanded={true}
            className="notesSection">
            <div>
                <div className={style.shortNotes}>{notes}</div>
            </div>
        </Section>
    );

    nameAddressPhone = (name, address, cityState, phone) => {
        return (
            <Section title={name} expanded={true} className="noBorder">
                {address && <div>{address}</div>}
                {cityState && <div>{cityState}</div>}
                {phone && <div>{prettyPhone(phone)}</div>}
            </Section>
        );
    };

    displayLevels = (level, connectors) => {
        return (
            connectors.length > 0 && (
                <div
                    key={`displayLevel-${level}`}
                    className={style.displayLevels}>
                    <div
                        className={style.level}
                        style={{ marginBottom: '8px' }}>
                        {t('Level')} {level}
                    </div>
                    <div className={style.level}>{t('Connector Type')}</div>
                    <div>
                        {uniqueObjArray(connectors).map((k, i) => (
                            <div
                                className={style.Row}
                                style={{ marginBottom: '16px' }}
                                key={`connector-${level}-${i}`}>
                                <div
                                    className={style.connector}
                                    style={{ marginBottom: '20px' }}>
                                    <div>
                                        <img
                                            src={`/img/connectors/${k.image}.svg`}
                                        />
                                    </div>
                                    <div>
                                        <div>{k.type}</div>
                                        <div
                                            style={{
                                                textTransform: 'initial'
                                            }}>
                                            {t('Max Charging Rate')}: {k.kwHour}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )
        );
    };

    displayConnectors = (evses, supplierName, operator) => {
        const reduceConnectors = (evses) =>
            evses.reduce((acc, e) => {
                acc = [...acc, ...e.connectors];
                return acc;
            }, []);
        const connectorTest = evses[0].connectors;
        const connectorsRaw =
            (connectorTest || []).length > 0 ? reduceConnectors(evses) : evses;
        const connectors = connectorsRaw.map((c) => {
            const { max_amperage, max_voltage } = c;
            c.kwHour = c.power_type.includes('DC')
                ? dcAmpsVoltsToWatts(max_amperage, max_voltage)
                : acAmpsVoltsToWatts(max_amperage, max_voltage);
            return c;
        });

        const levelObj = getEvseLevelCounts(connectors);
        const supplier = ((operator || {}).name || supplierName).replace(
            '_',
            ' '
        );
        const connectorImagesObj = CONNECTOR_STANDARDS.reduce((acc, cs) => {
            acc[cs.standard] = { image: cs.image, type: cs.type };
            return acc;
        }, {});
        const evseStandardsByLevel = connectors.reduce((acc, e) => {
            const { standard, kwHour, level } = e;
            const { image, type } = connectorImagesObj[standard];
            if (!acc[level]) acc[level] = [];
            acc[level].push({ standard, kwHour, image, type });
            return acc;
        }, {});

        const level3 = evseStandardsByLevel['3'] || [];
        const level2 = evseStandardsByLevel['2'] || [];
        const level1 = evseStandardsByLevel['1'] || [];
        return (
            <div style={{ padding: '12px 0' }}>
                <div
                    className={style.border}
                    style={{ marginBottom: '8px', paddingBottom: '24px' }}>
                    <div className={style.tableTitle}>{t('EV CHARGING')}</div>
                    <div style={{ marginBottom: '24px' }}>{supplier}</div>
                    <div className={classNames(style.Table, style.Hours)}>
                        <div key={`level`} className={style.Row}>
                            <div className={style.level}>{t('Level')}</div>
                            <div className={style.ports}>{t('Ports')}</div>
                        </div>
                        {Object.keys(levelObj)
                            .reverse()
                            .map((k, i) => (
                                <div key={`count-${i}`} className={style.Row}>
                                    <div className={style.levelNum}>
                                        {k || t('Unknown')}
                                    </div>
                                    <div className={style.levelCount}>
                                        {levelObj[k].count}
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
                <div key="level-display" className={style.Row}>
                    {this.displayLevels(3, level3)}
                    {this.displayLevels(2, level2)}
                    {this.displayLevels(1, level1)}
                </div>
            </div>
        );
    };

    displayHours = ({ twentyfourseven, daysHours }) => {
        const is247 =
            twentyfourseven || (!!daysHours && isTwentyFourSeven(daysHours));
        const dayRanges = transformRanges(twentyfourseven, daysHours);
        const hoursUnknown = !dayRanges && !is247;
        return (
            <Section
                title={t('Hours of Operation').toUpperCase()}
                expanded={true}
                className="shortPadding">
                {is247 && t('Open 24 hours')}
                {hoursUnknown && t('Hours unknown')}
                {!is247 &&
                    !!dayRanges &&
                    Object.keys(dayRanges).map((dh) =>
                        this.displayDaysHours(dayRanges[dh])
                    )}
            </Section>
        );
    };

    displayDaysHours = (dayHours) => {
        const dateTimeRange = daysHours(dayHours);
        return (
            dayHours && (
                <div key={`d-${dayHours.weekday}`} className={style.Row}>
                    <div className={style.day}>{dateTimeRange}</div>
                </div>
            )
        );
    };

    backLink = (onClick, text) => (
        <a onClick={onClick} className={style.arrow}>
            <span>
                <ArrowRight className={style.leftArrow} />
                <span className={style.arrowLink}>{text}</span>
            </span>
        </a>
    );

    sectionTitleCount = (
        title,
        countTitle,
        items,
        onClick = null,
        expanded = null
    ) => (
        <div className={style.sectionTitleCount}>
            <span>{title}</span>
            <span
                className={
                    style.titleRight
                }>{`${items.length} ${countTitle}`}</span>
            {onClick && (
                <span
                    // className={style.caret}
                    data-expanded={expanded}
                    onClick={onClick}
                />
            )}
        </div>
    );

    render() {
        const { chargingStation } = this.state;
        const {
            id,
            name,
            address,
            cityState,
            phone,
            notes,
            entrancePosition,
            opening_times,
            evses,
            operator,
            supplierName
        } = chargingStation;
        const hasHours = (opening_times) => {
            const { twentyfourseven } = opening_times;
            return twentyfourseven || (daysHours && daysHours.regular_hours);
        };

        return (
            id && (
                <div key={`cs-${id}`}>
                    <div>
                        {address &&
                            this.nameAddressPhone(
                                name,
                                address,
                                cityState,
                                phone
                            )}
                        <br />
                        {hasHours && this.displayHours(opening_times)}
                        {evses &&
                            evses.length &&
                            this.displayConnectors(
                                evses,
                                supplierName,
                                operator
                            )}
                        {notes && this.displayNotes(notes, entrancePosition)}
                    </div>
                </div>
            )
        );
    }
}

ChargingStationDetails.contextType = AppContext;
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default ChargingStationDetails;
