/******************************************************************************\
 * File: UploadGPXButton.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import withRouter from '@/helpers/hooks';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/mapListView/DownloadGPXButton.scss';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import UploadIcon from '@/components/common/icons/Upload';
import Tooltip from '@/components/common/form/Tooltip';
import { translate } from '@/helpers/i18n';
import { uploadRideGPX } from '@/helpers/api';
import { createRide } from '@/store/edit_ride/ride';
import { unsafeCalculateRide } from '@/helpers/here';
import {
    setHDAnalyticsUploadSuccess,
    setHDAnalyticsUploadFailed
} from '@/helpers/analytics';

const t = translate('mapListView.DownloadGPXButton');
const tExtended = translate('modal.uploadModal');

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class UploadGPXButton extends React.Component {
    constructor(props) {
        super(props);
        this.fileInputRef = React.createRef();
    }

    isRideSaved = () => !(this.props.meta || {}).dirty;

    hasWaypoints = () => ((this.props.ride || {}).waypoints || {}).length >= 1;

    hasUploaded = false;
    uploadStep = 0;
    openFileDialog = () => {
        this.hasUploaded = false;
        this.uploadStep = 0;

        const { disabled } = this.props;
        if (disabled) return;
        this.fileInputRef.current.click();
    };

    onFilesAdded = (evt) => {
        const { updateUploadModal, closeUploadModal } = this.props;
        const successfullyUploaded = false;
        const uploading = true;
        const uploadMsg = 'Processing GPX file…';
        const file = evt.target.files[0];
        if (file && !this.hasUploaded) {
            const { disabled } = this.props;
            if (disabled) return;
            this.uploadFile(file);
            this.hasUploaded = true;
        }
        setTimeout(() => {
            if (this.fileInputRef.current && this.fileInputRef)
                this.fileInputRef.current.value = '';
            this.hasUploaded = false;
        }, 3000);
    };

    isRecordedRide = ({ session }) => !!session && !!session.createdTime;

    uploadFile = (file) => {
        this.setState({ uploading: true });
        const {
            updateUploadModal,
            closeUploadModal,
            previewGPXRide,
            editRide,
            createRide
        } = this.props;
        if (this.props.ride.waypoints.length > 0) {
            return;
        }
        updateUploadModal(file, 'Processing GPX file…', false, true);
        this.sendRequest(file)
            .then((result) => {
                const {
                    data: { ride }
                } = result;
                const isRecorded = this.isRecordedRide(ride);
                const { isOffRoad } = ride;

                setHDAnalyticsUploadSuccess(isRecorded);
                if (!this.uploadStep) {
                    const successfullyUploaded = false;
                    const uploading = false;
                    updateUploadModal(
                        file,
                        null,
                        successfullyUploaded,
                        uploading,
                        isRecorded
                    );
                } else {
                    const uploadMsg = isRecorded
                        ? 'Your GPX file has been successfully imported as a Recorded Ride. Recorded Rides can’t be edited but can be copied to a Planned Ride.'
                        : isOffRoad
                        ? 'Your GPX file has been successfully imported as a Planned Ride. Planned Rides are rides that you can edit the locations and add waypoints.<br /><br />The route may vary from the original route.'
                        : 'Your GPX file has been successfully imported as a Planned Ride. Planned Rides are rides that you can edit the locations and add waypoints.<br /><br />To support on-road routing, the route has been optimized to less than 100 waypoints. The route may vary from the original route.';
                    const successfullyUploaded = true;
                    const uploading = false;
                    updateUploadModal(
                        file,
                        uploadMsg,
                        successfullyUploaded,
                        uploading,
                        isRecorded
                    );
                    // isRecorded ? previewGPXRide(ride) : editRide(ride);
                }
                this.uploadStep++;
            })
            .catch((e) => {
                const uploadMsg =
                    'There was an issue with your file. Try importing the file again or select a new file.';
                const successfullyUploaded = false;
                const uploading = false;
                this.hasUploaded = false;
                setHDAnalyticsUploadFailed();
                updateUploadModal(
                    file,
                    uploadMsg,
                    successfullyUploaded,
                    uploading
                );
                this.uploadStep = 0;
            });
        this.hasUploaded = false;
    };

    sendRequest = (file) => uploadRideGPX(file, {}).then((result) => result);

    render() {
        const { ride, hasSessionToken, text } = this.props;

        const disabled = !ride.id || !this.isRideSaved();

        return (
            <div className={style.DownloadGPXButton}>
                <Tooltip
                    value={
                        disabled
                            ? this.hasWaypoints()
                                ? tExtended(
                                      'Import GPX is only available to start creating a new ride'
                                  )
                                : null
                            : null
                    }
                    placement="w"
                    noDelay>
                    <a
                        className={classNames(style.link, {
                            [style.disabled]: this.hasWaypoints()
                        })}
                        title={
                            !!text ? tExtended(text) : tExtended('Import GPX')
                        }
                        onClick={this.openFileDialog}>
                        <UploadIcon />
                        {!!text ? tExtended(text) : tExtended('Import GPX')}
                        <input
                            ref={this.fileInputRef}
                            type="file"
                            accept="application/gpx+xml, .gpx"
                            style={{ display: 'none' }}
                            onChange={this.onFilesAdded}
                        />
                    </a>
                </Tooltip>
            </div>
        );
    }
}

//------------------------------------------------------------------------------
// Redux State -----------------------------------------------------------------
const mapStateToProps = (state, ownProps) => {
    return {};
};
//------------------------------------------------------------------------------
// Redux Actions ---------------------------------------------------------------
const mapDispatchToProps = (dispatch, ownProps) => ({
    createRide: (ride) => {
        dispatch(createRide(ride));
    },
    calculateRide: (ride) => {
        dispatch(unsafeCalculateRide(ride));
    }
});
//------------------------------------------------------------------------------
// Redux Connect ---------------------------------------------------------------
const container = connect(mapStateToProps, mapDispatchToProps)(UploadGPXButton);
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withRouter(container);
