import history from '@/helpers/history';
import { showLoginScreen } from '@/helpers/gigya';
import * as api from '@/helpers/api';
import { setAccountData, setProfile } from '@/store/user';
import { clear, persist, set as setLocalStore } from '@/helpers/persistor';
import { fetchBookmarks } from '@/store/bookmarks';
import { fetchEventBookmarks } from '@/store/eventBookmarks';
import { makeEmailPrivate } from '@/helpers/functions';
import { Routes, match as matchRoute } from '@/helpers/routes';
import { canSaveRide, setConfirm } from '@/store/edit_ride';
import { analyticsEvent } from '@/helpers/analytics';

/* */
// Types
export const SET_SESSION = 'auth/SET_SESSION';
export const INVALIDATE = 'auth/INVALIDATE';

/* */
// Helpers
function parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
}

/* */
// Selectors
export const hasSessionToken = (state) =>
    !!((state.auth.session || {}).jwt || {}).length;

export const getGigyaToken = () => {
    const getToken = (((window.gigya || {}).auth || {}).loginToken || {}).get;
    return getToken && getToken();
};

export const isAuthenticated = (state) => {
    return (
        hasSessionToken(state) 
    );
};

export const checkSessionTimeout = () => (dispatch, getState) => {
    if (hasSessionToken(getState()) && !getGigyaToken()) {
        dispatch(invalidateAuth());
        return true;
    }

    return false;
};

export const currentUserId = (state) => state.auth.session.userId;

export const userCanEditRide = (userId, ride) => {
    return userId ? userId === ride.userId : false;
};

export const currentUserCanEditRide = (state, ride) =>
    userCanEditRide(currentUserId(state), ride);

/* */
// Action Creators

const handleGigyaResponse = (dispatch, getState, cb) => (response) => {
    const {
        userId,
        UIDSignature,
        signatureTimestamp,
        data,
        id_token,
        profile
    } = response;

    dispatch(setProfile(profile));
    dispatch(setAccountData(data));

    return fetchSession(
        userId,
        UIDSignature,
        signatureTimestamp,
        id_token
    ).then((session) => {
        dispatch(setSession(session));
        if (cb) cb(dispatch, getState);
    });
};

const handleGigyaError = () => (e) => e.toString();

/**
 * Resolves with current session or rejects on login error.
 * Shows gigya login screen.
 */
export const auth =
    (cb = false) =>
    (dispatch, getState) => {
        const state = getState();

        // check if we're already logged in
        if (isAuthenticated(state)) {
            if (cb) return Promise.resolve(cb(dispatch, getState));
        }

        // session timeout
        checkSessionTimeout()(dispatch, getState);

        // otherwise show the gigya login screen
        return showLoginScreen(
            handleGigyaError(dispatch),
            handleGigyaResponse(dispatch, getState, cb)
        );
    };

export const fetchSession = (
    userId,
    UIDSignature,
    signatureTimestamp,
    id_token
) =>
    userId && ((UIDSignature && signatureTimestamp) || id_token)
        ? api.getSession(
              userId,
              UIDSignature,
              signatureTimestamp,
              window.gigya.dataCenter,
              id_token
          )
        : Promise.reject(
              'Missing one of the required fields: [userId, UIDSignature, signatureTimestamp, id_token]'
          );

export const setSession =
    (session, remember = true) =>
    (dispatch, getState) => {
        const { jwt } = session;

        if (!jwt) throw new Error('Session must have a valid jwt');

        api.setAPIToken(jwt);

        const { sub: userId, iat } = parseJwt(jwt);
        const sessionWithUser = { ...session, userId, iat };

        if (remember) dispatch(persist('auth.session', sessionWithUser));

        if (session && !!Object.keys(session).length) {
            // perform async to allow this action to complete
            setTimeout(() => dispatch(fetchBookmarks()));
            setTimeout(() => dispatch(fetchEventBookmarks()));
        }

        return dispatch({
            type: SET_SESSION,
            data: { session: sessionWithUser }
        });
    };

export const invalidateAuth = () => (dispatch, getState) => {
    const currRoute = (history.location || {}).pathname;
    const state = getState();
    analyticsEvent('logout success');
    if (
        matchRoute(currRoute, { exact: true }, [
            Routes.RIDE_EDIT,
            Routes.RIDE_CREATE_PREVIEW
        ])
    ) {
        if (
            state.edit_ride.present.ride.privacy === 'PRIVATE' &&
            state.edit_ride.present.ride.id !== undefined &&
            canSaveRide(state)
        ) {
            dispatch(setConfirm('logout')).then(
                (reason) =>
                    reason !== 'cancel' && _invalidateAuth()(dispatch, getState)
            );
        } else {
            _invalidateAuth()(dispatch, getState);
        }
    } else {
        _invalidateAuth()(dispatch, getState);
    }
};

const _invalidateAuth = () => (dispatch, getState) => {
    const state = getState();

    if (hasSessionToken(state)) {
        api.removeAPIToken();
        dispatch(clear());
        if (window.gigya) window.gigya.accounts.logout();

        const userLocalStorage = ['auth.session', 'userPrefs', 'user.data'];
        userLocalStorage.map((k) => setLocalStore(k, {}));
        /*
         * Redirect based on app state
         * Defaults to staying on current page
         */
        const currRoute = (history.location || {}).pathname;

        // Navigate back to homepage
        if (
            matchRoute(currRoute, { exact: true }, [
                Routes.PROFILE,
                Routes.SAVED
            ])
        ) {
            history.push(Routes.HOME);
        } else if (
            matchRoute(currRoute, { exact: true }, [
                Routes.RIDE_CREATE_PREVIEW,
                Routes.RIDE_PREVIEW
            ])
        ) {
            // Preview will navigate back to homepage if the ride is private
            if (
                (state.rides.selected || {}).id !== undefined &&
                state.rides.selected.privacy === 'PRIVATE'
            ) {
                history.push(Routes.HOME);
            }
        }

        dispatch({ type: INVALIDATE });
    }
};

/* */
// Reducer
const initialState = {
    session: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_SESSION: {
            const { session } = action.data;
            return { ...state, session };
        }

        case INVALIDATE: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};
