/******************************************************************************\
 * File: Textarea.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/common/form/Textarea.scss';
//------------------------------------------------------------------------------
// React Function --------------------------------------------------------------
const Textarea = ({
    className,
    value,
    count = true,
    maxLength = false,
    ...rest
}) => {
    const cn = classNames(style.Textarea, {
        [className]: !!className,
        [style['show-count']]: !!count
    });

    return (
        <div className={cn}>
            <textarea
                {...rest}
                maxLength={maxLength ? maxLength : false}
                value={value}
            />
            {!!maxLength && count && (
                <span className={style.count}>
                    {(value || '').length} / {maxLength}
                </span>
            )}
        </div>
    );
};
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Textarea;
