/******************************************************************************\
 * File: ImagesSidebar.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Components ------------------------------------------------------------------
import Sidebar from '@/components/common/Sidebar';
import ArrowLink from '@/components/common/ArrowLink';
import ImageCarousel from '@/components/mapListView/directions/ImageCarousel';

import * as style from '@/style/mapListView/sidebar/ImagesSidebar.scss';
import { translate } from '@/helpers/i18n';
import { Column } from 'react-virtualized';
const t = translate('sidebar.ImageDetails');

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class ImagesSidebar extends React.Component {
    backLink = (onClick, text) => (
        <ArrowLink
            className={style.back}
            onClick={onClick}
            left={true}
            text={text}
        />
    );

    render() {
        const {
            name,
            onRequestClose,
            isMobile,
            images,
            imageIndex,
            setImageIndex,
            isOpen,
            setImages
        } = this.props;
        return (
            <Sidebar
                contentLabel={name}
                {...this.props}
                underNavigation={true}
                highZ={true}
                className={style.Sidebar}
                portalClassName={
                    !isMobile
                        ? 'ReactModalPortal force-open'
                        : 'ReactModalPortal'
                }
                isOpen={isOpen}>
                <div className={style.backContainer}>
                    {this.backLink(onRequestClose, t('Ride Photos'))}
                </div>
                <div className={style.imagesSidebar}>
                    {isMobile && (
                        <ImageCarousel
                            images={images}
                            setImages={setImages}
                            setImageIndex={setImageIndex}
                            imageIndex={imageIndex}
                            slidesPerView={1}
                            isMobile={true}
                        />
                    )}
                    {!isMobile &&
                        images.map((image, index) => (
                            <div
                                key={index}
                                className={style.imageContainer}
                                style={{
                                    border:
                                        imageIndex === index
                                            ? '2px solid rgb(250, 102, 0)'
                                            : ''
                                }}>
                                <img
                                    src={image}
                                    onClick={() => setImageIndex(index)}
                                />
                            </div>
                        ))}
                </div>
            </Sidebar>
        );
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default ImagesSidebar;
